import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Form,
    FormGroup,
    InputGroup,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { invoiceServices } from "../../APIServices/invoiceServices";
import { addressServices } from "../../APIServices/addressServices";

const UpdateInvoiceData = ({ Show, Hide, Size, updateInvoiceDetails, getInvoiceBySponsor, setActiveSponcerId, updateInvoiceId, activeSpouncer, setActiveSpouncer }) => {
    const [currency, setCurrency] = useState("");
    const [amount, setAmount] = useState("");
    const [rows, setRows] = useState([]);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [taxInvoice, setTaxInvoice] = useState("");
    const [dueAmount, setDueAmount] = useState("");
    const [placeofSupply, setPlaceofSupply] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [loader, setLoader] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [cityLists, setCityLists] = useState([]);
    const [city_id, setCityID] = useState("");
    const [stateLists, setStateLists] = useState([]);
    const [state_id, setStateID] = useState("");
    const [countryLists, setCountryLists] = useState([]);
    const [country_id, setCountryID] = useState("");

    const handleSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        let userData = {};
        userData.invoiceNo = invoiceNumber;
        userData.taxInvoice = taxInvoice;
        userData.amountDue = dueAmount;
        userData.currency = currency;
        userData.dueDate = dueDate;
        userData.City = city_id;
        userData.State = state_id;
        userData.Country = country_id;
        userData.service = rows;
        let data = await invoiceServices.updateInvoice(updateInvoiceId, userData);
        setStatusMessage(data);
        if (data?.status_code === 200) {
            clearFormData();
            getInvoiceBySponsor();
            setLoader(false);
        } else {
            setLoader(false);
        }
    };

    const getCountries = async (e) => {
        let data = await addressServices.getCountries(e);
        setCountryLists(data);
    };

    const getStates = async (e) => {
        let data = await addressServices.getStates(e);
        setStateLists(data);
    };

    const getCities = async (e) => {
        let data = await addressServices.getCities(e);
        setCityLists(data);
    };

    console.log("updateInvoiceId", updateInvoiceId);


    const clearFormData = () => {
        Hide();
        setInvoiceNumber("");
        setTaxInvoice("");
        setDueAmount("");
        setPlaceofSupply("");
        setDueDate("");
        setAmount("");
    };

    const addRow = () => {
        const newRow = {
            id: uuidv4(),
            serviceName: "",
            hsnSac: "",
            unit: "",
            price: "",
        };
        setRows([...rows, newRow]);
    };

    const deleteRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
    };

    useEffect(() => {
        getCountries();
        setInvoiceNumber(updateInvoiceDetails?.invoiceNo);
        setActiveSponcerId(updateInvoiceDetails?.sponsorID);
        setTaxInvoice(updateInvoiceDetails?.taxInvoice);
        setDueAmount(updateInvoiceDetails?.amountDue);
        setCurrency(updateInvoiceDetails?.currency);
        setAmount(updateInvoiceDetails?.amount);
        setDueDate(updateInvoiceDetails?.dueDate);
        setCountryID(updateInvoiceDetails?.country_id)
        setStateID(updateInvoiceDetails?.state_id)
        setCityID(updateInvoiceDetails?.city_id)
        setRows(updateInvoiceDetails?.service);
    }, [updateInvoiceDetails]);

    console.log("updateInvoiceDetails", updateInvoiceDetails);

    console.log("currency", currency);




    return (
        <div>
            <Modal
                show={Show}
                onHide={Hide}
                size={Size}
                backdrop="static"
                keyboard="false"
            >
                <Modal.Header className="modal_closeButton">
                    <Modal.Title>Update Invoice Data</Modal.Title>
                    <div
                        onClick={() => clearFormData()}
                        className="close_modal"
                        title="Close"
                    >
                        X
                    </div>
                </Modal.Header>
                <Modal.Body className="modal_bodyText">
                    <Row>
                        <FormGroup as={Col} md={4} className="mb-2">
                            <Form.Label className="mb-1 fw-bold">
                                <b>Sponser</b>
                                <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                                onChange={(e) => setActiveSponcerId(e.target.value)}
                                value={updateInvoiceDetails?.sponsorID || ""}
                            >
                                <option>select</option>
                                {activeSpouncer?.map((item, index) => (
                                    <option key={index} value={item?._id?.$oid}>
                                        {item?.companyName}
                                    </option>
                                ))}
                            </Form.Select>
                        </FormGroup>
                        <FormGroup as={Col} md={4} className="mb-2">
                            <Form.Label className="mb-1 fw-bold">
                                <b>Invoice Number</b>
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="rounded-1"
                                placeholder="Enter Your Invoice Number"
                                onChange={(e) => setInvoiceNumber(e.target.value)}
                                value={invoiceNumber}
                            />
                        </FormGroup>
                        <FormGroup
                            as={Col}
                            md={4}
                            className="mb-2"
                            placeholder="Enter Your Last Name"
                        >
                            <Form.Label className="mb-1 fw-bold">
                                <b>Tax Invoice</b>
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="rounded-1"
                                placeholder="Enter Your Tax Invoice"
                                onChange={(e) => setTaxInvoice(e.target.value)}
                                value={taxInvoice}
                            />
                        </FormGroup>
                        <Form.Group as={Col} md={6} className="mb-2">
                            <Form.Label className="mb-1 fw-bold">
                                Due Date <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                                required
                                className="rounded-1"
                                type="date"
                                placeholder="Enter Due Date"
                                onChange={(e) => setDueDate(e.target.value)}
                                value={dueDate}

                            />
                        </Form.Group>
                        <FormGroup as={Col} md={6} className="mb-2">
                            <Form.Label className="mb-1 fw-bold">
                                <b>Due Amount</b>
                                <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                className="rounded-1"
                                placeholder="Enter your Due Amount"
                                onChange={(e) => setDueAmount(e.target.value)}
                                value={dueAmount}
                            />
                        </FormGroup>

                        <Form.Group as={Col} md={currency ? 6 : 12} className="mb-2">
                            <Form.Label className="mb-1 fw-bold">
                                <b>Currency</b>
                                <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                                className="rounded-1"
                                aria-label="Default select example"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                            >
                                <option value="">Select Currency</option>
                                <option selected={currency === "dollar"} value="dollar">Dollar ($)</option>
                                <option selected={currency === "inr"} value="inr">INR (₹)</option>
                            </Form.Select>
                        </Form.Group>
                        {currency && (
                            <Form.Group as={Col} md="6" className="mb-2">
                                <Form.Label className="mb-1 fw-bold">
                                    Amount <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        required
                                        className="checkbox-control"
                                        type="number"
                                        placeholder="Enter Your Amount"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                    <Button
                                        variant="light"
                                        className="px-2"
                                        style={{
                                            fontSize: "13px",
                                            border: "1px solid #dee2e6",
                                        }}
                                    >
                                        {currency === "Dollar" ? "$" : "₹"}
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        )}

                        <Form.Group as={Col} md={12} className="mb-2">
                            <Form.Label className="mb-1 fw-bold">
                                Country <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                                className="rounded-1"
                                onChange={(e) => {
                                    setCountryID(e.target.value);
                                    getStates(e.target.value);
                                }}
                                value={country_id}
                            >
                                <option>-Select-</option>
                                {countryLists?.length > 0 &&
                                    countryLists.map((item, key) => (
                                        <option value={item.id} key={key} title={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-2">
                            <Form.Label className="mb-1 fw-bold">
                                State <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                                className="rounded-1"
                                onChange={(e) => {
                                    setStateID(e.target.value);
                                    getCities(e.target.value);
                                }}
                                value={state_id}
                            >
                                <option>-Select-</option>
                                {stateLists.length > 0 &&
                                    stateLists.map((item, key) => (
                                        <option value={item.id} key={key} title={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-2">
                            <Form.Label className="mb-1 fw-bold">
                                City <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                                className="rounded-1"
                                onChange={(e) => setCityID(e.target.value)}
                                value={city_id}
                            >
                                <option>-Select-</option>
                                {cityLists.length > 0 &&
                                    cityLists.map((item, key) => (
                                        <option value={item.id} key={key}>
                                            {item.name}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Form.Group>

                        <div className="d-flex mt-2">
                            <Form.Label className="m-0">
                                <b>Services:</b>
                            </Form.Label>
                            <Form.Label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-add">Add Services</Tooltip>}
                                >
                                    <i
                                        className="fa-solid fa-circle-plus ms-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={addRow}
                                    ></i>
                                </OverlayTrigger>
                            </Form.Label>
                        </div>
                        <div class="tablesss-container">
                            <table class="customModal-table">
                                <thead>
                                    <tr>
                                        <th>Service Name</th>
                                        <th>HSN/SAC</th>
                                        <th>Unit</th>
                                        <th>Price</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows?.map((row) => (
                                        <tr key={row.id}>
                                            <td className="p-0">
                                                <input
                                                    type="text"
                                                    value={row.serviceName}
                                                    onChange={(e) =>
                                                        setRows(
                                                            rows.map((r) =>
                                                                r.id === row.id ? { ...r, serviceName: e.target.value } : r
                                                            )
                                                        )
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        border: "1px solid lightgray",
                                                        outline: "none",
                                                        padding: "5px",
                                                    }}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    type="text"
                                                    value={row.hsnSac}
                                                    onChange={(e) =>
                                                        setRows(
                                                            rows.map((r) =>
                                                                r.id === row.id ? { ...r, hsnSac: e.target.value } : r
                                                            )
                                                        )
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        border: "1px solid lightgray",
                                                        outline: "none",
                                                        padding: "5px",
                                                    }}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    type="number"
                                                    value={row.unit}
                                                    onChange={(e) =>
                                                        setRows(
                                                            rows.map((r) =>
                                                                r.id === row.id ? { ...r, unit: e.target.value } : r
                                                            )
                                                        )
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        border: "1px solid lightgray",
                                                        outline: "none",
                                                        padding: "5px",
                                                    }}
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    type="number"
                                                    value={row.price}
                                                    onChange={(e) =>
                                                        setRows(
                                                            rows.map((r) =>
                                                                r.id === row.id ? { ...r, price: e.target.value } : r
                                                            )
                                                        )
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        border: "1px solid lightgray",
                                                        outline: "none",
                                                        padding: "5px",
                                                    }}
                                                />
                                            </td>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-add">Delete Row</Tooltip>}
                                            >
                                                <td className="p-0 text-center border">
                                                    <i
                                                        className="fa-solid fa-trash"
                                                        onClick={() => deleteRow(row.id)}
                                                        style={{ color: "red", cursor: "pointer" }}
                                                    ></i>
                                                </td>
                                            </OverlayTrigger>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-end">
                        <Form.Group as={Col} md="12">
                            <div className="button_type">
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form.Group>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateInvoiceData;
