import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { clientServices } from "../../APIServices/clientServices";
import Swal from "sweetalert2";
import { workflowServices } from "../../APIServices/workflowServices";
import Moment from "react-moment";
import Paginations from "../../Hooks/Paginations";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../Hooks/NoDataFoundTable";
import SplitPane from "react-split-pane";
import Multiselect from "multiselect-react-dropdown";
import { quotationServices } from "../../APIServices/quotationServices";
import { NavLink, useParams } from "react-router-dom";

const StudyList = ({ projectId, studyId, setStudyId }) => {
  const [phaseID, setPhaseID] = useState("");
  const [studyName, setStudyName] = useState("");
  const [studyData, SetStudyData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsTwo, setSelectedOptionsTwo] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsActive, setActiveOptions] = useState([]);
  const [activePhaseData, setActivePhaseData] = useState([]);
  const [updateStudyId, setUpdateStudyId] = useState("");
  const [loading, setLoading] = useState(true);
  const [statusCode, setStatusCode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [showPhase, setShowPhase] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedActiveIds, setSelectedActiveIds] = useState([]);
  const [qutations, setQutations] = useState("");

  const handleSelect = (selectedList) => {
    setSelectedOptions(selectedList);
    updateSelectedIds(selectedList);
  };

  const handleRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptions(NewObjectData);
    updateSelectedIds(NewObjectData);
  };

  const updateSelectedIds = (selectedList) => {
    const ids = selectedList.map((item) => item.value);
    setSelectedIds(ids);
  };

  const handleActiveSelect = (selectedList) => {
    setSelectedOptionsTwo(selectedList);
    const ids = selectedList.map((item) => item?.value);
    setSelectedActiveIds(ids);
  };

  const handleActiveRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptionsTwo(NewObjectData);
    updateSelectedActiveIds(NewObjectData);
  };

  const updateSelectedActiveIds = (selectedList) => {
    const ids = selectedList.map((item) => item?.value);
    setSelectedActiveIds(ids);
  };

  const getActiveWorkflow = async () => {
    let data = await workflowServices.getActiveWorkflow();
    if (data?.status_code === 200) {
      const formattedOptions = data?.data?.map((workflow) => ({
        label: workflow?.name,
        value: workflow?._id?.$oid,
      }));
      setActiveOptions(formattedOptions);
    }
  };

  const addStudyWithQutations = async () => {
    let userData = {};
    setLoader(true);
    userData.studyName = studyName;
    userData.workflowID = selectedIds;
    userData.phaseID = phaseID;
    userData.quatation = qutations;
    let data = await clientServices.addStudy(userData, projectId);
    if (data?.status_code === 200) {
      clearFormData();
      getAllStudy();
    }
  };

  const addStudyWithoutQutations = async () => {
    let userData = {};
    setLoader(true);
    userData.studyName = studyName;
    userData.workflowID = selectedActiveIds;
    userData.quatation = qutations;
    let data = await clientServices.addStudy(userData, projectId);
    if (data?.status_code === 200) {
      clearFormData();
      getAllStudy();
    }
  };

  const getAllStudy = async () => {
    let data = await clientServices.getAllStudy(projectId);
    SetStudyData(data?.data);
    setLoading(false);
  };

  const clearFormData = () => {
    setStudyName("");
    setPhaseID("");
    setLoader("");
  };

  const updateStudyStatus = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Study Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await clientServices.updateStudy(_id, data);
        if (results?.status_code === 200) {
          getAllStudy();
        }
      }
    });
  };

  const updateStudy = async () => {
    let _id = studyId;
    let userData = {};
    userData.studyName = studyName;
    userData.phaseID = phaseID;
    userData.options = options;
    let data = await clientServices.updateStudy(_id, userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      getAllStudy();
      setStatusCode(true);
      clearFormData();
    }
  };

  const handleUpdateClick = (study) => {
    setUpdateStudyId(study?.studyID);
    setStudyName(study.studyName);
    setPhaseID(study.phaseID);
    setQutations(study?.quatation);
    const formattedSelectedWorkflows = study.workflow.map((wf) => ({
      label: wf.lable,
      value: wf.value,
    }));
    const falseSelectedWorkflows = study.workflow.map((wf) => ({
      label: wf.lable,
      value: wf.value,
    }));

    setSelectedOptions(formattedSelectedWorkflows);
    setActiveOptions(falseSelectedWorkflows);
  };

  const deleteStudy = async () => {
    let data = await clientServices.deleteStudy(studyId);
    if (data?.status_code === 200) {
      getAllStudy();
    }
  };

  const getWorkflowByPhase = async () => {
    let data = await workflowServices.getWorkflowByPhase(phaseID);
    if (data?.status_code === 200) {
      const formattedOptions = data?.data?.map((workflow) => ({
        label: workflow.workflowName,
        value: workflow.workflowID,
      }));
      setOptions(formattedOptions);
    }
  };

  const handleRowClick = (id) => {
    setStudyId(id);
  };
  const getActivePhase = async () => {
    let data = await quotationServices.getActivePhase();
    setActivePhaseData(data?.data);
  };

  useEffect(() => {
    getActiveWorkflow();
  }, []);

  useEffect(() => {
    getWorkflowByPhase();
  }, [phaseID]);

  useEffect(() => {
    getAllStudy();
    getActivePhase();
  }, [projectId]);

  const handleLoginClick = (studyID) => {
    setLoader(true);
    localStorage.setItem("path", "clientstudy");
    window.location.href = `/clientstudy/${studyID}`;
    setLoader(false);
  };

  const isDisabled = !studyName || !qutations || !phaseID;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please fill & select all mandatory fields
    </Tooltip>
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyData?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize={"25%"}
        style={{ height: "calc(100vh - 320px)" }}
      >
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 320px)" }}
        >
          <div className="p-2">
            <FormGroup className="mb-2">
              <Form.Label>
                <b>Study Name :</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                className="rounded-1"
                onChange={(e) => {
                  setStudyName(e.target.value);
                }}
                value={studyName}
                placeholder="Enter Your Study Name"
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Form.Label>
                <b>Quotations:</b>
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                className="rounded-1"
                onChange={(e) => setQutations(e.target.value)}
              >
                <option>Select</option>
                <option selected={qutations === "True"} value={"True"}>
                  Yes
                </option>
                <option selected={qutations === "False"} value={"False"}>
                  No
                </option>
              </Form.Select>
            </FormGroup>
            {qutations === "True" && (
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>
                    Phase :<span style={{ color: "red" }}>*</span>
                  </b>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setPhaseID(e.target.value)}
                  value={phaseID}
                  className="rounded-1"
                  aria-label="Default select example"
                >
                  <option value="">Select</option>
                  {activePhaseData?.map((item, index) => (
                    <option key={index} value={item._id?.$oid}>
                      {item.phaseName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            {qutations === "True" && (
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Workflow :</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Multiselect
                  displayValue="label"
                  onKeyPressFn={() => {}}
                  onSelect={handleSelect}
                  onRemove={handleRemove}
                  className="rounded-1"
                  options={options}
                  selectedValues={selectedOptions}
                />
              </Form.Group>
            )}
            {qutations === "False" && (
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Workflow:</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>

                <Multiselect
                  displayValue="label"
                  onKeyPressFn={() => {}}
                  onSelect={handleActiveSelect}
                  onRemove={handleActiveRemove}
                  className="rounded-1"
                  options={optionsActive}
                  selectedValues={selectedOptionsTwo}
                />
              </Form.Group>
            )}

            <div className="text-end">
              <Form.Group as={Col} md="12">
                <div className="button_type">
                  <OverlayTrigger
                    placement="top"
                    overlay={isDisabled ? renderTooltip : <span></span>}
                  >
                    <button
                      onClick={
                        !updateStudyId
                          ? qutations === "True"
                            ? addStudyWithQutations
                            : addStudyWithoutQutations
                          : updateStudy
                      }
                      disabled={!studyName}
                    >
                      {loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 15, height: 15 }}
                          className="me-2"
                        />
                      ) : null}
                      {!updateStudyId ? "Add" : "Update"} Study
                    </button>
                  </OverlayTrigger>
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
        {loading ? (
          <SpinerDataLoader />
        ) : result?.length > 0 ? (
          <div className="table-container overflow-auto m-1">
            <Table
              id="resizable-table"
              className="custom-table"
              striped
              border
              hover
            >
              <thead>
                <tr style={{ cursor: "pointer" }}>
                  <th style={{ width: 68 }} className="text-center">
                    Sr.
                  </th>
                  <th style={{ width: 173 }}>Study Name</th>
                  <th style={{ width: 173 }}>Phase Name</th>
                  <th style={{ width: 173 }}>Workflow Name</th>
                  <th style={{ width: 173 }}>Status</th>
                  <th style={{ width: 150 }}>Create Date</th>
                  <th style={{ width: 173 }}>Update Date</th>
                  <th style={{ width: 55, textAlign: "center" }}>Update</th>
                  <th style={{ width: 55, textAlign: "center" }}>Login</th>
                  <th style={{ width: 55, textAlign: "center" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={item.id}
                    onClick={() => handleRowClick(item.studyID)}
                    className={studyId === item.studyID ? "selected" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="text-center">{startIndex + index + 1}</td>
                    <td>{item.studyName}</td>
                    <td>{item.phaseName}</td>
                    {item.workflow.map((wf, index) => (
                      <td key={index}>{wf.lable}</td>
                    ))}
                    <td>
                      <select
                        className={`status-select ${item.status.toLowerCase()}`}
                        value={item.status}
                        onChange={(e) =>
                          updateStudyStatus(item?.studyID, e.target.value)
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "0",
                          margin: "0",
                          border: "none",
                          boxSizing: "border-box",
                        }}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.created_at}
                      </Moment>
                    </td>

                    <td>
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.updated_at}
                      </Moment>
                    </td>
                    <td className="text-center">
                      <span onClick={() => handleUpdateClick(item)}>
                        <i className="fa-solid fa-pen-to-square"></i>
                      </span>
                    </td>
                    <td className="text-center">
                      <NavLink
                        to={`clientstudy/${item?.studyID}`}
                        onClick={() => handleLoginClick(item.studyID)}
                      >
                        <span>
                          <i className="fa-solid fa-right-to-bracket"></i>
                        </span>
                      </NavLink>
                    </td>
                    <td className="text-center">
                      <span className="text-danger">
                        <i
                          class="fa-solid fa-trash"
                          onClick={() => deleteStudy(item?._id?.$oid)}
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <NoDataFoundTable MSG={"Study Not Found"} />
        )}
        <Paginations
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={studyData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </SplitPane>
    </>
  );
};

export default StudyList;
