import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row, Spinner } from "react-bootstrap";
import ProfilePic from "../../../Images/manImage.jpg";
import Footer from "../../../Component/Footer";
import { addressServices } from "../../../APIServices/addressServices";
import { profileServices } from "../../../APIServices/profileServices";

const Profile = ({ userCurrrentData, currentUserData, roleType }) => {
  const [image, setImage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");
  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");
  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [statusCode, setStatusCode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [officephone, setOfficephone] = useState("");
  const [website, setWebsite] = useState("");
  const [companyaddress, setCompanyaddress] = useState("");
  const [companycityLists, setcompanycityLists] = useState([]);
  const [companycity_id, setCompanycityID] = useState("");
  const [companystateLists, setCompanystateLists] = useState([]);
  const [companystate_id, setCompanystateID] = useState("");
  const [companycountryLists, setCompanycountryLists] = useState([]);
  const [companycountry_id, setCompanycountryID] = useState("");
  const [companypostalCode, setCompanypostalCode] = useState("");

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountryLists(data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setStateLists(data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCityLists(data);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.address = address;
    userData.gender = gender;
    userData.city_id = city_id;
    userData.stateId = state_id;
    userData.countryId = country_id;
    userData.zipCode = postalCode;
    userData.roleType = "pmuser";
    let data = await profileServices.updateProfile(userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      currentUserData();
      setStatusCode(true);
    } else {
      setLoader(false);
    }
  };

  const handleImageChange = async (e) => {
    let fileData = e.target.files[0];
    if (fileData) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(fileData);

      const formData = new FormData();
      formData.append("file", fileData);

      let result = await profileServices.updateProfile(
        formData,
        userCurrrentData?._id
      );
      if (result?.statusCode === 200) {
        currentUserData();
      }
    }
  };

  useEffect(() => {
    getCountries();
    setImage(userCurrrentData?.profileImage);
    setFirstName(userCurrrentData?.firstName);
    setMiddleName(userCurrrentData?.middleName);
    setLastName(userCurrrentData?.lastName);
    setEmail(userCurrrentData?.email);
    setPhone(userCurrrentData?.phone);
    setGender(userCurrrentData?.gender);
    setAddress(userCurrrentData?.Address);
    setCompanyName(userCurrrentData?.companyName);
    setCompanyEmail(userCurrrentData?.email);
    setOfficephone(userCurrrentData?.officePhoneNumber);
    setWebsite(userCurrrentData?.website);
    setCompanyaddress(userCurrrentData?.Address);
    setCompanycountryID(userCurrrentData?.Country);
    setCompanystateID(userCurrrentData?.State);
    setCompanycityID(userCurrrentData?.City);
    setCompanypostalCode(userCurrrentData?.zipCode);
  }, [userCurrrentData]);

  return (
    <>
      <div className="admin-main-content-area">
        <Row>
          <Col md={4}>
            <div className="Profile_img ">
              <Form.Label>Profile Image</Form.Label>
            </div>
            <div
              className="bg-white shadow-sm border-radius-3"
              style={{ padding: "21px 10px" }}
            >
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                  />
                  <label
                    htmlFor="imageUpload"
                    title="Upload Profile Pic"
                  ></label>
                </div>
                <div className="avatar-preview">
                  {image ? (
                    <img src={image} alt="Preview" />
                  ) : (
                    <img src={ProfilePic} alt="Default Preview" />
                  )}
                </div>
              </div>
            </div>
          </Col>

          {roleType === "user" && (
            <>
              <Col md={8}>
                <div className="modal_bodyText">
                  <Row>
                    <FormGroup as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>First Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                    </FormGroup>
                    <FormGroup as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>Middle Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Middle Name"
                        onChange={(e) => setMiddleName(e.target.value)}
                        value={middleName}
                      />
                    </FormGroup>
                    <FormGroup
                      as={Col}
                      md={6}
                      className="mb-3"
                      placeholder="Enter Your Last Name"
                    >
                      <Form.Label>
                        <b>Last Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                    </FormGroup>
                    <FormGroup as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>Email</b>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        disabled
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </FormGroup>
                    <Form.Group as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>Phone</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Phone Number"
                        disabled
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        value={phone}
                      />
                    </Form.Group>
                    <FormGroup as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>Gender</b>
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => setGender(e.target.value)}
                        aria-label="Default select example"
                      >
                        <option>Select Gender</option>
                        <option selected={gender === "male"} value="male">
                          Male
                        </option>
                        <option selected={gender === "female"} value="female">
                          Female
                        </option>
                        <option selected={gender === "other"} value="other">
                          Other
                        </option>
                      </Form.Select>
                    </FormGroup>
                  </Row>
                </div>
              </Col>
              <FormGroup as={Col} md={12} className="my-3">
                <Form.Label>
                  <b>Address</b>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter your address"
                  style={{ height: "29px" }}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </FormGroup>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  onChange={(e) => setCountryID(e.target.value)}
                  value={country_id}
                  onClick={(e) =>
                    getStates(e.target[e.target.selectedIndex].title)
                  }
                >
                  <option>-Select-</option>
                  {countryLists?.length > 0
                    ? countryLists.map((item, key) => (
                        <option value={item.id} key={key} title={item.id}>
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Select
                  onChange={(e) => setStateID(e.target.value)}
                  value={state_id}
                  onClick={(e) =>
                    getCities(e.target[e.target.selectedIndex].title)
                  }
                >
                  <option>-select-</option>
                  {stateLists.length > 0
                    ? stateLists.map((item, key) => (
                        <option value={item._id} key={key} title={item.id}>
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Select
                  onChange={(e) => setCityID(e.target.value)}
                  value={city_id}
                >
                  <option>-select-</option>
                  {cityLists.length > 0
                    ? cityLists.map((item, key) => (
                        <option value={item._id} key={key}>
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Postal Code"
                  onChange={(e) => setPostalCode(e.target.value)}
                  value={postalCode}
                />
              </Form.Group>
            </>
          )}

          {roleType === "client" && (
            <>
              <Col md={8}>
                <div className="modal_bodyText">
                  <Row>
                    <FormGroup as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>Company Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Company Name"
                        className="text-capitalize"
                        onChange={(e) => setCompanyName(e.target.value)}
                        value={companyName}
                      />
                    </FormGroup>
                    <FormGroup as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>Company Email</b>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        disabled
                        onChange={(e) => {
                          setCompanyEmail(e.target.value);
                        }}
                        value={companyEmail}
                      />
                    </FormGroup>
                    <Form.Group as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>Office Phone</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Office Phone Number"
                        disabled
                        onChange={(e) => {
                          setOfficephone(e.target.value);
                        }}
                        value={officephone}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6} className="mb-3">
                      <Form.Label>
                        <b>website</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Website"
                        disabled
                        onChange={(e) => {
                          setWebsite(e.target.value);
                        }}
                        value={website}
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <FormGroup as={Col} md={12} className="my-3">
                <Form.Label>
                  <b>Address</b>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter your address"
                  style={{ height: "29px" }}
                  onChange={(e) => setCompanyaddress(e.target.value)}
                  value={companyaddress}
                />
              </FormGroup>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  onChange={(e) => setCompanycountryID(e.target.value)}
                  value={companycountry_id}
                  onClick={(e) =>
                    getStates(e.target[e.target.selectedIndex].title)
                  }
                >
                  <option>-Select-</option>
                  {countryLists?.length > 0
                    ? countryLists.map((item, key) => (
                        <option value={item.id} key={key} title={item.id}>
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Select
                  onChange={(e) => setCompanystateID(e.target.value)}
                  value={companystate_id}
                  onClick={(e) =>
                    getCities(e.target[e.target.selectedIndex].title)
                  }
                >
                  <option>-select-</option>
                  {stateLists.length > 0
                    ? stateLists.map((item, key) => (
                        <option value={item._id} key={key} title={item.id}>
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Select
                  onChange={(e) => setCompanycityID(e.target.value)}
                  value={companycity_id}
                >
                  <option>-select-</option>
                  {cityLists.length > 0
                    ? cityLists.map((item, key) => (
                        <option value={item._id} key={key}>
                          {item.name}
                        </option>
                      ))
                    : ""}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Postal Code"
                  onChange={(e) => setCompanypostalCode(e.target.value)}
                  value={companypostalCode}
                />
              </Form.Group>
            </>
          )}
        </Row>
        <div className="text-end">
          <Form.Group as={Col} md="12">
            <div className="button_type">
              <button type="submit" onClick={handleSubmit}>
                {loader ? (
                  <Spinner
                    animation="border"
                    style={{ width: 13, height: 13 }}
                    className="me-2"
                  />
                ) : null}
                Submit
              </button>
            </div>
          </Form.Group>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
