import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Collapse, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import SignaturePad from "signature_pad";

const General = ({
  label,
  setLabel,
  color,
  setColor,
  isBold,
  setIsBold,
  isItalic,
  setIsItalic,
  required,
  setRequired,
  customize,
  setCustomize,
  orderNo,
  setOrderNo,
  help,
  setHelp,
  sections,
  section,
  setFormData,
  setSignature,
  signature,
}) => {
  const [open, setOpen] = useState(false);
  const canvasRef = useRef(null);
  const [signaturePad, setSignaturePad] = useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const signaturePad = new SignaturePad(canvas, {
      backgroundColor: "rgba(255, 255, 255, 0)",
      penColor: "black",
      velocityFilterWeight: 0.7,
      minWidth: 0.5,
      maxWidth: 2.5,
      throttle: 16,
      minPointDistance: 3,
      onEnd: () => { },
      onBegin: () => { },
    });

    setSignaturePad(signaturePad);

    return () => {
      signaturePad.off();
    };
  }, []);


  const handleClear = () => {
    signaturePad.clear();
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSection = sections.find((item) => item.id === section.id);
      if (updatedSection) {
        setFormData(updatedSection);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [section.sections]);

  const setCustomization = (e) => {
    setOpen(!open);
    setCustomize(!customize);
  };
  return (
    <>
      <div className="p-2 pb-0">
        <Form.Group>
          <div className="hstack justify-content-between">
            <Form.Label>
              <b>Label</b>
            </Form.Label>
            {label && (
              <Form.Check
                type="switch"
                label="Customize Label"
                onChange={setCustomization}
                checked={customize}
              />
            )}
          </div>
          <Form.Control
            type="text"
            className="p-2"
            placeholder="Enter Signature label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Form.Group>
      </div>
      <Collapse in={customize}>
        <div className="p-2">
          <Form.Label className="mb-1">
            <b>Customization</b>
          </Form.Label>
          <Card className="toogle-card shadow-sm border">
            <div className="hstack border-bottom">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-color">Change Color</Tooltip>}
              >
                <div className="date-icon">
                  <Form.Control
                    type="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-bold"> Text Bold</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsBold(!isBold)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-bold ${isBold ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-italic">Text Italic</Tooltip>}
              >
                <div
                  className="date-icon"
                  onClick={() => setIsItalic(!isItalic)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa-solid fa-italic ${isItalic ? "bg-body-secondary rounded-1" : ""
                      }`}
                  ></i>
                </div>
              </OverlayTrigger>
              <div className="vr"></div>
            </div>
            <div className="p-2">
              <Form.Group className="mb-2">
                <Form.Label className="mb-1">
                  <b>Output</b>
                </Form.Label>
                <Card className="border h-1">
                  <Card.Body>
                    <Card.Text
                      className="text-center"
                      style={{
                        color,
                        fontWeight: isBold ? "bold" : "normal",
                        fontStyle: isItalic ? "italic" : "normal",
                      }}
                    >
                      {label || "Output Result"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>
              <Form.Check
                className="requird_form_check mb-2"
                type="switch"
                aria-label="option 1"
                checked={required}
                onClick={(e) => setRequired(e.target.value)}
                label="Required"
              />
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Order no</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={orderNo}
                  onChange={(e) => setOrderNo(e.target.value)}
                  placeholder="Enter Your Details"
                />
              </Form.Group>

                <Form.Label className="mb-2">Signature Box</Form.Label>
                <canvas ref={canvasRef} className="signature-box" style={{ border: '1px solid black', width: '100%', height: '150px' }} onChange={(e) => setSignature(e.target.value)} value={signature}></canvas>
              <div className="text-end button_type mt-2">
                <button  onClick={handleClear}>
                  Clear
                </button>
              </div>
              
               

              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Help </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="help Description"
                  value={help}
                  onChange={(e) => setHelp(e.target.value)}
                />
              </Form.Group>
            </div>
          </Card>
        </div>
      </Collapse>
    </>
  );
};

export default General;


