import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  headersWithAuthWithoutBody,
  headersWithoutAuthWithoutBody,
} from "../APIHelpers/Responses";

export const addressServices = {
  getCountries,
  getCountriesById,
  getStates,
  getStateById,
  getCities,
  getCityById,
};

// Get All Country
async function getCountries() {
  const response = await fetch(
    `${config.API_URL}getCountries`,
    headersWithoutAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Find Country
async function getCountriesById(id) {
  const response = await fetch(
    `${config.API_URL}getCountriesById/${id}`,
    headersWithoutAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get States Based on Country
async function getStates(countryId) {
  const response = await fetch(
    `${config.API_URL}getStates/${countryId}`,
    headersWithoutAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Find State
async function getStateById(id) {
  const response = await fetch(
    `${config.API_URL}getStateById/${id}`,
    headersWithoutAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get City Based on State
async function getCities(stateId) {
  const response = await fetch(
    `${config.API_URL}getCities/${stateId}`,
    headersWithoutAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Find City
async function getCityById(id) {
  const response = await fetch(
    `${config.API_URL}getCityById/${id}`,
    headersWithoutAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
