import config from "../config/config.json";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
} from "../APIHelpers/Responses";

export const profileServices = {
  uploadImage,
  updateProfile,
};

// uploadImage
async function uploadImage(userData) {
  const response = await fetch(
    `${config.API_URL}uploadImage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//updateProfile
async function updateProfile(userData) {
  const response = await fetch(
    `${config.API_URL}updateProfile`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
