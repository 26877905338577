import React from "react";
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import Moment from "react-moment";

const TempelatesPropertiesModal = ({ Show, Hide, Size, templateData }) => {
  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title>Properties</Modal.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Modal.Header>
          <Modal.Body className="modal_bodyText">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="fw-bold">Attributes</th>
                  <th className="fw-bold">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Created Date</b>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      <span className="p-1">{templateData?.created_at}</span>
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b> Name :</b>
                  </td>
                  <td>
                    <span className="p-1">{templateData?.name}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b> Status :</b>
                  </td>
                  <td>
                    <span className="p-1">{templateData?.status}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>updated_at :</b>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      <span className="p-1">{templateData?.updated_at}</span>
                    </Moment>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default TempelatesPropertiesModal;
