import React, { useEffect, useState } from "react";
import { Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import AccessTab from "./AccessTab";
import GeneralTab from "./GeneralTab";
import AdvanceTab from "./AdvanceTab";

const FormCheckboxModal = ({
  show,
  onHide,
  size,
  Title,
  id,
  type,
  sections,
  addUpdateFormById,
}) => {
  const [selectedTab, setSelectedTab] = useState("General");
  const [label, setLabel] = useState("");
  const [color, setColor] = useState("#000000");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [required, setRequired] = useState(false);
  const [customize, setCustomize] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [help, setHelp] = useState("");
  const [checkbox, setCheckbox] = useState([{ id: uuidv4(), value: "" }]);

  const handleSaveData = () => {
    let headingObj = {};
    headingObj.id = id;
    headingObj.type = type;
    headingObj.customize = customize;
    headingObj.required = required;
    if (label) {
      headingObj.label = label;
    }
    if (required) {
      headingObj.required = required;
    }
    if (orderNo) {
      headingObj.orderNo = orderNo;
    }
    if (defaultValue) {
      headingObj.defaultValue = defaultValue;
    }
    if (help) {
      headingObj.help = help;
    }
    headingObj.options = checkbox || [];
    headingObj.labelStyle = {
      color: color,
      bold: isBold,
      italic: isItalic,
    };
    const existingIndex = sections?.findIndex((item) => item.id === id);
    if (existingIndex !== -1) {
      sections[existingIndex] = headingObj;
    } else {
      sections.push(headingObj);
    }
    window.dispatchEvent(new Event("storage"));
    onHide();
    addUpdateFormById();
  };

  useEffect(() => {
    let findField = sections?.find((item) => item.id === id);
    setLabel(findField?.label);
    setColor(findField?.labelStyle?.color);
    setIsBold(findField?.labelStyle?.bold);
    setIsItalic(findField?.labelStyle?.italic);
    setRequired(findField?.required);
    setCustomize(findField?.customize);
    setOrderNo(findField?.orderNo);
    setDefaultValue(findField?.defaultValue);
    setHelp(findField?.help);
    setCheckbox(findField?.checkbox);
  }, [id, sections]);

  return (
    <>
      <Offcanvas show={show} size={size} onHide={onHide} placement="end">
        <Offcanvas.Header>
          <div className="hstack justify-content-between w-100 gap-3">
            <Offcanvas.Title>
              <b>{Title}</b>
            </Offcanvas.Title>
            <div
              onClick={() => onHide()}
              className="offcanvas_Close"
              title="Close"
            >
              X
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="fixed-buttongroup border-bottom h-stack">
            <div className="date-buttonchange d-flex border-top">
              <div
                className={`custom-button ${
                  selectedTab === "General" ? "selected" : ""
                }`}
                onClick={() => setSelectedTab("General")}
              >
                General
              </div>
              <div
                className={`custom-button border-start border-end ${
                  selectedTab === "Advance" ? "selected" : ""
                }`}
                onClick={() => setSelectedTab("Advance")}
              >
                Advance
              </div>
              <div
                className={`custom-button ${
                  selectedTab === "Access" ? "selected" : ""
                }`}
                onClick={() => setSelectedTab("Access")}
              >
                Access
              </div>
            </div>
          </div>

          {selectedTab === "General" && (
            <GeneralTab
              label={label}
              setLabel={setLabel}
              color={color}
              setColor={setColor}
              isBold={isBold}
              setIsBold={setIsBold}
              isItalic={isItalic}
              setIsItalic={setIsItalic}
              required={required}
              setRequired={setRequired}
              customize={customize}
              setCustomize={setCustomize}
              orderNo={orderNo}
              setOrderNo={setOrderNo}
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
              help={help}
              setHelp={setHelp}
              checkbox={checkbox || []}
              setCheckbox={setCheckbox}
            />
          )}
          {selectedTab === "Advance" && <AdvanceTab id={id} type={type} />}
          {selectedTab === "Access" && <AccessTab id={id} type={type} />}

          <div className="button_type text-end p-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-save">Apply Changes</Tooltip>}
            >
              <button
                variant="outline-dark"
                className="apply-button-templates p-1 px-3"
                onClick={() => handleSaveData()}
              >
                Apply
              </button>
            </OverlayTrigger>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FormCheckboxModal;
