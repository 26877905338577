import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import { Table } from "react-bootstrap";
import { NoDataFoundTable, SpinerDataLoader } from "../../../Hooks/NoDataFoundTable";
import { elearningServices } from "../../../APIServices/elearningServices";
import Moment from "react-moment";
import Paginations from "../../../Hooks/Paginations";

const EActivity = () => {
  const [loader, setLoadeer] = useState("");
  const [allElearning, setAllElearning] = useState([]);
  const [loading, setLoading] = useState(true);


  const getAllElearningLog = async () => {
    let data = await elearningServices.getAllElearningLog();
    setAllElearning(data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getAllElearningLog();
  }, []);

  console.log("allElearning", allElearning);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = allElearning?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(allElearning?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "91%" }}
      >
        {loading ? (
          <SpinerDataLoader />
        ) : result?.length > 0 ? (
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              hover
              className="m-0 custom-table"
            >
              <thead className="thead-sticky top-0">
                <tr>
                  <th style={{ width: 45 }}>Sr.</th>
                  <th>Training Name</th>
                  <th>Activity By</th>
                  <th>Activities</th>
                  <th>Type</th>
                  <th>Activity Date | Time</th>
                </tr>
              </thead>
              <tbody>
                {allElearning.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.trainingName}</td>
                    <td>{item.activityBy}</td>
                    <td>{item.activity}</td>
                    <td>{item.type}</td>

                    <td><Moment format="DD MM YY || HH:MM">{item.createdAt}</Moment></td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={elearningServices?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        ) : (
          <NoDataFoundTable MSG={"E learning Activity Is Not Found"} />
        )}
      </SplitPane>
    </>
  );
};

export default EActivity;













