import React, { useEffect, useRef, useState } from 'react'
import SplitPane from 'react-split-pane'
import UserClientList from './UserClientList';
import UserProjectList from './UserProjectList';
import UserStudyList from './UserStudyList';
import { Button } from 'react-bootstrap';
import { clientServices } from '../APIServices/clientServices';

const UserSpouncer = ({ tableRef, onMouseDown }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const yResizerRef = useRef(null);
    const [activeTabs, setActiveTabs] = useState("projects");
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [studyId, setStudyId] = useState(null);
    const [assignedSponsorData, setAssignedSponsorData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getAllAssignedSponsor = async () => {
        let data = await clientServices.getAllAssignedSponsor();
        setAssignedSponsorData(data?.data);
        setLoading(false);
    };

    console.log("assignedSponsorData", assignedSponsorData);


    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
        if (!isFullScreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        getAllAssignedSponsor();
    }, []);

    return (
        <>
            <SplitPane
                split="horizontal"
                className="position-relative"
                defaultSize={selectedRow ? "40%" : "100%"}
            >
                <SplitPane split="vertical" minSize={20} defaultSize={"100%"} >
                    <UserClientList
                        assignedSponsorData={assignedSponsorData}
                        setSelectedRow={setSelectedRow}
                        loading={loading}
                        selectedRow={selectedRow}
                        onMouseDown={onMouseDown}
                        tableRef={tableRef}
                    />
                </SplitPane>
                {selectedRow && (
                    <div className={isFullScreen ? "full-screen-component" : ""}>
                        <div className="hstack paginate fixed_tab_button justify-content-between border-bottom">
                            <div className="hstack ms-2 gap-1">
                                {["projects", "studies", "qutations"].map((tab) =>
                                    (!projectId && tab === "studies") ||
                                        (!studyId && tab === "qutations") ? (
                                        ""
                                    ) : (
                                        <Button
                                            key={tab}
                                            className={` w-100 border-top rounded-top border-dark border-start border-end border-0 rounded-0 clientButton ${activeTabs === tab ? "active" : ""
                                                }`}
                                            onClick={() => setActiveTabs(tab)}
                                            title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                                        >
                                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                        </Button>
                                    )
                                )}
                            </div>
                            <div
                                className={`app-container ${isFullScreen ? "fullscreen" : ""}`}
                            >
                                <div className="toolbar-full-screen">
                                    <i
                                        className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                                            }`}
                                        title={!isFullScreen ? "Full Mode" : "Original Mode"}
                                        onClick={toggleFullScreen}
                                    ></i>
                                </div>
                            </div>
                        </div>

                        {activeTabs === "projects" ? (
                            <UserProjectList
                                projectId={projectId}
                                selectedRow={selectedRow}
                                setProjectId={setProjectId}
                            />
                        ) : activeTabs === "studies" ? (
                            <UserStudyList
                                projectId={projectId}
                                selectedRow={selectedRow}
                            />

                        ) : (
                            ""
                        )}
                    </div>
                )}
            </SplitPane>
        </>
    )
}

export default UserSpouncer