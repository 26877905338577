import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import TableWorkflowOffcanvas from "./TableWorkflowOffcanvas";
import UseToggle from "../../Hooks/UseToggle";
import { workflowServices } from "../../APIServices/workflowServices";
import Paginations from "../../Hooks/Paginations";
import { NoDataFoundTable } from "../../Hooks/NoDataFoundTable";
import Moment from "react-moment";

const WorkflowList = ({ workFlowId }) => {
  const { toggle, setToggle } = UseToggle();
  const [workflowTableData, setWorkflowTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(true);

  const getAllTaskByWorkflow = async () => {
    let data = await workflowServices.getAllTaskByWorkflow(workFlowId);
    setWorkflowTableData(data?.data);
    setLoader(false);
  };

  useEffect(() => {
    getAllTaskByWorkflow();
  }, [workFlowId]);

  console.log("workflowTableData", workflowTableData);

  console.log("workFlowId", workFlowId);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = workflowTableData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(workflowTableData?.length / itemsPerPage);

  return (
    <div>
      <>
        <div
          className="overflow-auto p-1"
          style={{ height: "calc(100vh - 130px", paddingTop: 5 }}
        >
          {loader ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "65vh" }}
            >
              <Spinner animation="border" />
            </div>
          ) : result?.length > 0 ? (
            <>
              <div className="overflow-auto m-1" style={{ height: "calc(100vh -130px" }}>
                <Table
                  id="resizable-table"
                  bordered
                  className="custom-table"
                  striped
                  border
                  hover
                >
                  <thead className="position-sticky" style={{ top: -1 }}>
                    <tr style={{ cursor: "pointer" }}>
                      <th style={{ border: "1px solid #896e6e5e", width: 66 }}>Sr.</th>
                      <th style={{ border: "1px solid #896e6e5e" }}>WorkFlow</th>
                      <th style={{ border: "1px solid #896e6e5e" }}>Created By</th>
                      <th style={{ border: "1px solid #896e6e5e" }}>Update By</th>
                      <th style={{ border: "1px solid #896e6e5e" }}>Task Name</th>
                      <th style={{ border: "1px solid #896e6e5e" }}>Task Status</th>
                      <th style={{ border: "1px solid #896e6e5e" }}>Created Date & Time</th>
                    </tr>
                  </thead>
                  {result?.map((item, index) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={item.id}
                      className={selectedRow === item?._id?.$oid ? "selected" : ""}
                      onClick={() => setSelectedRow(item?._id?.$oid)}
                    >
                      <td>{startIndex + index + 1}</td>
                      <td>{item.workflowName}</td>
                      <td>{item.createdBy}</td>
                      <td>{item.updatedBy}</td>
                      <td>{item.taskName}</td>
                      <td style={{ color: item.status === "active" ? "green" : item.status === "pending" ? "red" : "black", }}>
                        {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY || HH:MM">{item.created_at}</Moment>
                      </td>
                    </tr>
                  ))}
                </Table>
                <div className="position-sticky bottom-0 bg-white">
                  <Paginations
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={workflowTableData?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "800vh",
                justifyContent: "center",
              }}
            >
              <NoDataFoundTable MSG={"Workflow Data Not Found"} />
            </div>
          )}
        </div>
        <TableWorkflowOffcanvas Show={!toggle} Hide={setToggle} />
      </>
    </div>
  );
};

export default WorkflowList;
