import React, { useState } from "react";
import "../OrgChart.css";
import { FaEnvelope, FaPhone } from "react-icons/fa6";
import { orgServices } from "../../../APIServices/orgServices";
import Swal from "sweetalert2";

const CustomNodeContent = (props) => {
  const [childId, setChildId] = useState("");

  const handleDelete = (id) => {
    setChildId(id);
    deleteChild();
  };

  const deleteChild = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete This User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await orgServices.deleteOrg(_id);
        if (results?.status_code === 200) {
        }
      }
    });
  };

  return (
    <div
      className="bg-light border rounded-1 hstack justify-content-center text-muted"
      style={{ minHeight: 85 }}
    >
      <div className="w-100 px-2">
        <div className="gap-2">
          <div className="hstack gap-2 flex-column">
            <div className="node-info d-flex flex-column">
              <h6 className="mb-1 fw-bold mainColor fs-13">
                {props.data.child_name}
              </h6>
              <span className="fs-11 mb-1">{props.data.positionName}</span>
              <span className="fs-11">{props.data.name}</span>
            </div>
          </div>
          <div className="hstack justify-content-center gap-2 mt-2">
            <a href={`mailto:${props.data.email}`}>
              <FaEnvelope className="mainColor fs-9" title={props.data.email} style={{ marginRight: 22 }} />
            </a>
            <a href={`tel:+${props.data.phone}`}>
              <FaPhone className="mainColor fs-9" title={props.data.phone} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomNodeContent;
