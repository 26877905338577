import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import AddUpdateLearning from "./AddUpdateLearning";
import ELearningDataTable from "./ELearningDataTable";
import ELearningProperties from "./ELearningProperties";
import { SpinerDataLoader } from "../../Hooks/NoDataFoundTable";
import { elearningServices } from "../../APIServices/elearningServices";
import { useParams } from "react-router-dom";

const ELearningManagement = ({ questions, setQuestions, multiselectAnswer, setMultiselectAnswer }) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateType, setUpdateType] = useState("");
  const [trainingName, setTrainingName] = useState("");
  const [description, setDescription] = useState("");
  const [completionDays, setCompletionDays] = useState("");
  const [updateDataId, setUpdateDataId] = useState(null);
  const [elearningData, setElearningData] = useState([]);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [elearningID, setElearningID] = useState("");
  const [trandingId, setTrandingId] = useState("");
  const [companyID, setCompanyID] = useState("");

  const handleAddNewLearning = () => {
    setUpdateType("add");
  };

  const { studyId } = useParams();

  const getAllElearningMeta = async () => {
    setLoading(true);
    let data = await elearningServices.getAllElearningMeta(studyId ? studyId : "undefined");
    setElearningData(data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getAllElearningMeta();
  }, []);

  return (
    <>
      <div className="position-relative">
        <div className="p-1 hstack justify-content-between border-bottom">
          {!trandingId ? (
            <div className="hstack gap-2 toolbar" style={{ marginBottom: 5 }}>
              {updateType ? (
                <i
                  onClick={() => setUpdateType("")}
                  title="Table Full Mode"
                  className="fa-solid fa-table-list"
                ></i>
              ) : (
                <div className="hstack p-2 inputWorkFlow gap-2 px-1 border-bottom">
                  <button
                    onClick={handleAddNewLearning}
                    title="Add new E-Learning Data"
                    className="teams_button border rounded-1 fs-12"
                  >
                    <i className="fa-solid fa-square-plus CP"></i>
                  </button>
                </div>
              )}
            </div>
          ) : (

            <div className="hstack gap-2 px-2 py-1 toolbar" style={{ marginBottom: 5, cursor: "pointer" }}>
              <i
                onClick={() => setTrandingId("")}
                title="Back to Training List"
                className="fa-solid fa-arrow-left"
              ></i>
            </div>
          )
          }
        </div>
        <SplitPane
          split="horizontal"
          style={{
            height: "calc(100vh - 152px)",
          }}
          className="position-relative"
          defaultSize={isSectionVisible ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={updateType ? "20%" : "100%"}
          >
            {loader ? (
              <SpinerDataLoader />
            ) : (
              updateType && (
                <AddUpdateLearning
                  setTrainingName={setTrainingName}
                  trainingName={trainingName}
                  description={description}
                  setDescription={setDescription}
                  completionDays={completionDays}
                  setCompletionDays={setCompletionDays}
                  handleAddNewLearning={handleAddNewLearning}
                  getAllElearningMeta={getAllElearningMeta}
                  updateDataId={updateDataId}
                  setUpdateDataId={setUpdateDataId}
                  setElearningID={setElearningID}
                  elearningID={elearningID}
                  questions={questions}
                  setQuestions={setQuestions}
                  multiselectAnswer={multiselectAnswer}
                  setMultiselectAnswer={setMultiselectAnswer}

                />
              )
            )}
            {loader ? (
              <SpinerDataLoader />
            ) : (
              <ELearningDataTable
                description={description}
                elearningID={elearningID}
                updateDataId={updateDataId}
                trainingName={trainingName}
                elearningData={elearningData}
                setDescription={setDescription}
                completionDays={completionDays}
                setElearningID={setElearningID}
                setUpdateDataId={setUpdateDataId}
                setTrainingName={setTrainingName}
                isSectionVisible={isSectionVisible}
                setCompletionDays={setCompletionDays}
                setIsSectionVisible={setIsSectionVisible}
                getAllElearningMeta={getAllElearningMeta}
                handleAddNewLearning={handleAddNewLearning}
                setCompanyID={setCompanyID}
                companyID={companyID}
                setTrandingId={setTrandingId}
                trandingId={trandingId}
                setUpdateType={setUpdateType}
                loading={loading}
                questions={questions}
                setQuestions={setQuestions}
                multiselectAnswer={multiselectAnswer}
                setMultiselectAnswer={setMultiselectAnswer}
              />
            )}
          </SplitPane>
          {isSectionVisible && (
            <ELearningProperties setIsSectionVisible={setIsSectionVisible} companyID={companyID} elearningID={elearningID} />
          )}
        </SplitPane>
      </div>
    </>
  );
};

export default ELearningManagement;
