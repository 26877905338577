import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import AddDesignationOffcanvas from "./AddDesignationOffcanvas";
import { ContextMenuTrigger } from "react-contextmenu";
import Moment from "react-moment";
import Swal from "sweetalert2";
import UseToggle from "../../../Hooks/UseToggle";
import { orgServices } from "../../../APIServices/orgServices";
import Paginations from "../../../Hooks/Paginations";
import { NoDataFoundTable } from "../../../Hooks/NoDataFoundTable";
import RightClick from "../../../Component/RightClick/RightClick";

const DeginationTable = () => {
  const { toggle1, setToggle1 } = UseToggle();
  const [designationData, setDesignationData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [designationId, setDesignationId] = useState("");
  const [desinationName, setDesinationName] = useState("");

  const getDesignation = async () => {
    let data = await orgServices.getAllDesignation();
    setDesignationData(data?.data);
    setLoading(false);
  }

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "inherit";
    }
  };

  console.log("desinationName", desinationName);


  const deleteDesignation = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Designation!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await orgServices.deleteDesignation(_id);
        if (results?.status_code === 200) {
          getDesignation();
        }
      }
    });
  };

  const handleRowClick = (_id, status) => {
    updateDesignation(_id, status);
  };

  const handleUpdateClick = (item) => {
    setToggle1();
    setDesignationId(item?._id?.$oid)
    setDesinationName(item?.designationName);


  }

  const updateDesignation = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Designation Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await orgServices.updateDesignation(_id, data);
        if (results?.status_code === 200) {
          getDesignation();
        }
      }
    });
  };

  useEffect(() => {
    getDesignation();
  }, []);

  console.log("designationId", designationId);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = designationData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(designationData?.length / itemsPerPage);



  return (
    <div>
      <div className="hstack p-2 inputWorkFlow gap-2 px-1 border-bottom">
        <button
          onClick={setToggle1}
          title="Add Quotations"
          className="teams_button border rounded-1 fs-12"
        >
          <i className="fa-solid fa-plus text-muted mt-1"></i>
        </button>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "65vh" }}
        >
          <Spinner animation="border" />
        </div>
      ) : result?.length > 0 ? (
        <>
          <div
            className="overflow-auto m-1"
            style={{ height: "calc(100vh - 130px" }}
          >
            <Table
              id="resizable-table"
              bordered
              className="custom-table"
              striped
              border
              hover
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr style={{ cursor: "pointer" }}>
                  <th style={{ border: "1px solid #896e6e5e", width: 66 }}>
                    Sr.
                  </th>
                  <th style={{ border: "1px solid #896e6e5e" }}>
                    Designation Name
                  </th>
                  <th style={{ border: "1px solid #896e6e5e" }}>Status</th>
                  <th style={{ border: "1px solid #896e6e5e" }}>
                    Create Date | Time
                  </th>
                  <th style={{ border: "1px solid #896e6e5e" }}>
                    Update Date | Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={item.id}
                    className={
                      selectedRow === item._id.$oid ? "selected" : ""
                    }
                    onClick={() => setSelectedRow(item._id.$oid)}
                  >
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {startIndex + index + 1}
                      </ContextMenuTrigger>
                      <RightClick
                        ContextId={`clientData${index}`}
                        Menu1
                        MenuName1={
                          item.status === "active" ? "Inctive" : "Active"
                        }
                        icons1={
                          <i
                            class={`fa-solid fa-circle ${item.status === "active"
                              ? "text-danger"
                              : "text-success"
                              }`}
                          ></i>
                        }
                        className1={"text-white"}
                        handleClick1={() =>
                          handleRowClick(
                            item._id.$oid,
                            item.status === "active" ? "inactive" : "active"
                          )
                        }
                        Menu2
                        MenuName2={"Update"}
                        icons2={
                          <i class="fa-solid fa-pen-to-square text-white"></i>
                        }
                        className2={"text-white "}
                        handleClick2={() => handleUpdateClick(item)}
                        Menu3
                        MenuName3={"Delete"}
                        icons3={
                          <i class="fa-solid fa-trash-can text-danger"></i>
                        }
                        className3={"text-white "}
                        handleClick3={() => deleteDesignation(item?._id?.$oid)}
                      />
                    </td>
                    <td>

                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.designationName}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <div style={{ color: getStatusColor(item.status) }}>
                          {item.status}
                        </div>
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <Moment format="DD MMM YYYY | HH:MM">
                          {item.created_at}
                        </Moment>
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      {" "}
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <Moment format="DD MMM YYYY | HH:MM">
                          {item.updated_at}
                        </Moment>
                      </ContextMenuTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={designationData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "80vh",
            justifyContent: "center",
          }}
        >
          <NoDataFoundTable MSG={"Designation  Table is Not Found"} />
        </div>
      )}

      <AddDesignationOffcanvas
        show={!toggle1}
        onHide={setToggle1}
        getDesignation={getDesignation}
        designationData={designationData}
        designationId={designationId}
        setDesinationName={setDesinationName}
        desinationName={desinationName} />
    </div>
  );
};

export default DeginationTable;
