import React from "react";
import { Modal, Table } from "react-bootstrap";
import Moment from "react-moment";

const AddPropertiesModal = ({ Show, Hide, Size, workflowData }) => {
  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title>Properties</Modal.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Modal.Header>
          <Modal.Body className="modal_bodyText">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="fw-bold">Attributes</th>
                  <th className="fw-bold">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Company ID</b>
                  </td>
                  <td>
                    <span className="p-1">{workflowData?.companyID}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Created Date | Time</b>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      <span className="p-1">{workflowData?.created_at}</span>
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Name :</b>
                  </td>
                  <td>
                    <span className="p-1">{workflowData?.name}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Status :</b>
                  </td>
                  <td>
                    <span className="p-1">{workflowData?.status}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Templates :</b>
                  </td>
                  <td>
                    <span className="p-1">
                      {Array.isArray(workflowData?.templates)
                        ? workflowData.templates.join(", ")
                        : workflowData?.templates}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Updated At :</b>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      <span className="p-1">{workflowData?.updated_at}</span>
                    </Moment>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddPropertiesModal;
