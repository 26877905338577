import React from 'react'
import { Button, Card, Col, Container, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'
import profileImage from "../../Images/FriendForecer.jpg";
import unique from "../../Images/weAreUnique.png";
import fun from "../../Images/just Fun.jpg";
import game from "../../Images/game boye.jpg";
import tripti from "../../Images/tripti.jpg";
import sakira from "../../Images/sakira.jpg";
import astha from "../../Images/astha.jpg";
import perry from "../../Images/perray.jpg";
import { LiaCheckDoubleSolid, } from "react-icons/lia";
import { FaPaperclip } from "react-icons/fa6";
import { FaMicrophone } from "react-icons/fa";

const Chat = () => {
    return (
        <>
            <div>
                <Container fluid>
                    <Row>
                        <Col md={4}>
                            <div className="p-2">
                                <div className="p-2 border shadow-sm rounded-3 mb-1">
                                    <div className="hstack">
                                        <i class="fa-solid fa-magnifying-glass mx-2"></i>
                                        <Form.Control
                                            type="text"
                                            placeholder="Type your search here..."
                                            className="message-input-field border-0"
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="p-2">
                                <div className="p-3 border shadow-sm rounded-3 mb-2">
                                    <div className="hstack mb-2">
                                        <b>Group</b>
                                    </div>
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <img src={profileImage} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                            <div className="message-content ms-2">
                                                <h6 className="mb-0">Friendship Forever</h6>
                                                <p class="text-muted mb-0" style={{ fontSize: 12 }}>Life is about everything.</p>
                                            </div>
                                        </div>
                                        <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                    </div>
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <img src={fun} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                            <div className="message-content ms-2">
                                                <h6 className="mb-0">Just Fun</h6>
                                                <p class="text-muted mb-0" style={{ fontSize: 12 }}>Life is about everything.</p>
                                            </div>
                                        </div>
                                        <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                    </div>
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <img src={unique} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                            <div className="message-content ms-2">
                                                <h6 className="mb-0">We Are Unique</h6>
                                                <p class="text-muted mb-0" style={{ fontSize: 12 }}>Life is about everything.</p>
                                            </div>
                                        </div>
                                        <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                    </div>
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <img src={game} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                            <div className="message-content ms-2">
                                                <h6 className="mb-0">Game Boy</h6>
                                                <p class="text-muted mb-0" style={{ fontSize: 12 }}>Life is about everything.</p>
                                            </div>
                                        </div>
                                        <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                    </div>

                                </div>
                            </div>
                            <div className="p-2">
                                <div className="p-3 border shadow-sm rounded-3 mb-2">
                                    <div className="hstack mb-2">
                                        <b>Friends</b>
                                    </div>
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <div className="avatar-container">
                                                <img src={tripti} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                                <span className="status-indicator"></span>
                                            </div>

                                            <div className="message-content ms-2">
                                                <h6 className="mb-0">Mrs. Tripti</h6>
                                                <p class="text-muted mb-0" style={{ fontSize: 12 }}>I'm Busy Now.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                            <div className='text-end'>
                                                <LiaCheckDoubleSolid style={{ color: "blue" }} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <div className="avatar-container">
                                                <img src={sakira} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                                <span className="status-indicator"></span>
                                            </div>

                                            <div className="message-content ms-2">
                                                <h6 className="mb-0">Mrs. Sakira</h6>
                                                <p class="text-muted mb-0" style={{ fontSize: 12 }}>To Late</p>
                                            </div>
                                        </div>
                                        <div>
                                            <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                            <div className='text-end'>
                                                <LiaCheckDoubleSolid style={{ color: "blue" }} />
                                            </div>
                                        </div>



                                    </div>
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <div className="avatar-container">
                                                <img src={astha} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                                <span className="status-indicatorred"></span>
                                            </div>
                                            <div className="message-content ms-2">
                                                <h6 className="mb-0">Astha</h6>
                                                <p class="text-muted mb-0" style={{ fontSize: 12 }}>OnBord.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                            <div className='text-end'>
                                                <LiaCheckDoubleSolid style={{ color: "grey" }} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <div className="avatar-container">
                                                <img src={perry} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                                <span className="status-indicatorred"></span>
                                            </div>
                                            <div className="message-content ms-2">
                                                <h6 className="mb-0">Alex Perry</h6>
                                                <p class="text-muted mb-0" style={{ fontSize: 12 }}>Heyyy.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <small className="text-muted" style={{ fontSize: 12 }}>Yesterday, 11:52Pm</small>
                                            <div className='text-end'>
                                                <LiaCheckDoubleSolid style={{ color: "grey" }} />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="p-2" style={{ height: "calc(100vh - 150px)" }}>
                                <div className="p-2 border shadow-sm rounded-3 mb-1">
                                    <div className="message-container hstack justify-content-between p-2" style={{ cursor: "pointer" }}>
                                        <div className="hstack mb-2">
                                            <img src={profileImage} alt="Profile" title="User Profile" className="rounded-circle avatar-img" />
                                            <div className="message-contentes ms-2">
                                                <h6 className="mb-0">Friendship Forever</h6>
                                                <p class="last-seen mb-0">Last Seen : 12:00 Pm</p>
                                            </div>
                                        </div>
                                        <small><i class="fa-solid fa-ellipsis-vertical"></i></small>
                                    </div>
                                    <div className="messageHello receiver mt-2">
                                        <p>Hey Bruh!</p>
                                        <span className="time">Today, 9:00pm</span>
                                    </div>
                                    <div className="messageHello2 receiver">
                                        <p>How are you doing?</p>
                                        <span className="time">Today, 9:01pm</span>
                                    </div>
                                    <div className="messageHello sender">
                                        <p>Hello</p>
                                        <span className="time">Today, 9:02pm</span>
                                    </div>
                                    <div className="messageHello2 sender">
                                        <p>I am fine, thank you.</p>
                                        <span className="time text-end w-100 px-3">Today, 9:02pm</span>

                                    </div>
                                    <div className="message sender">
                                        <p>Been a while, trust you are doing good yourself?</p>
                                        <span className="time text-end w-100 px-3">Today, 9:03pm</span>
                                    </div>
                                    <div className="messageHello2 receiver">
                                        <p>Yea! I am fine.</p>
                                        <span className="time">Today, 9:03pm</span>
                                    </div>
                                    <div className="messageHello2 receiver">
                                        <p>Can we meet tomorrow?</p>
                                        <span className="time">Today, 9:04pm</span>
                                    </div>

                                    <div className="message-input-container">

                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type your message here..."
                                                className="message-input-field"
                                            />
                                            <Button
                                                variant="light"
                                                className="px-2"
                                                style={{
                                                    fontSize: "13px",
                                                    border: "1px solid #dee2e6",
                                                }}
                                            >
                                                <FaPaperclip className="message-icon" />
                                            </Button>
                                        </InputGroup>


                                        <div className="message-icons">

                                        </div>
                                        <Button className="microphone-button">
                                            <FaMicrophone />
                                        </Button>
                                    </div>

                                </div>
                            </div>

                        </Col>



                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Chat