import React from "react";
import { Modal, Table } from "react-bootstrap";
import Moment from "react-moment";

const MilestonePropertiesModal = ({
  Show,
  Hide,
  Size,
  milestoneProperties,
}) => {
  return (
    <>
      <div>
        <Modal
          show={Show}
          onHide={Hide}
          size={Size}
          backdrop="static"
          keyboard="false"
        >
          <Modal.Header className="modal_closeButton">
            <Modal.Title>Properties</Modal.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Modal.Header>
          <Modal.Body className="modal_bodyText">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="fw-bold">Attributes</th>
                  <th className="fw-bold">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Milestone Name</b>
                  </td>
                  <td>
                    <span className="p-1">
                      {milestoneProperties?.milestoneName}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Created Date | Time</b>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      <span className="p-1">
                        {milestoneProperties?.created_at}
                      </span>
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Expected Start Date :</b>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      <span className="p-1">
                        {milestoneProperties?.expectedStartDate}
                      </span>
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Expected End Date :</b>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      <span className="p-1">
                        {milestoneProperties?.expectedEndDate}
                      </span>
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Templates :</b>
                  </td>
                  <td>
                    <span className="p-1">
                      {Array.isArray(milestoneProperties?.templates)
                        ? milestoneProperties.templates.join(", ")
                        : milestoneProperties?.templates}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Updated At :</b>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY">
                      <span className="p-1">
                        {milestoneProperties?.updated_at}
                      </span>
                    </Moment>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default MilestonePropertiesModal;
