import React, { useEffect, useState } from "react";
import Pagination from "../../../Hooks/Pagination";
import DownloadFaildDataModal from "./DownloadFaildDataModal";
import FaildTableData from "../../Settings/Faild Data/FaildTableData";
import { loginActivitiesServices } from "../../../APIServices/loginActivitiesServices";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import UseToggle from "../../../Hooks/UseToggle";

const FaildData = ({ tableRef, onMouseDown }) => {
  const { toggle7, setToggle7 } = UseToggle();
  const [failedLoginData, setFailedLoginData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  const getFailLogin = async () => {
    let data = await loginActivitiesServices.getFailLogin();
    setFailedLoginData(data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getFailLogin();
  }, []);

  return (
    <>
      <div className="hstack inputWorkFlow gap-2 py-2 px-1 border-bottom">
        <div className="filter_section hstack p-0">
          {filter ? (
            <>
              <div className="h-stack justify-content-between">
                <InputGroup>
                  <InputGroup.Text
                    onClick={() => {
                      setFilter(false);
                    }}
                    title="Cancel"
                    className="search_button fs-12 px-2"
                    style={{ padding: 2.5 }}
                  >
                    <i className="fa-solid fa-xmark text-muted"></i>
                  </InputGroup.Text>
                  <Form.Control type="search" className="search-bar" />
                  <InputGroup.Text
                    title="Search"
                    className="teams_button fs-12 px-2"
                    style={{ padding: 2.5 }}
                  >
                    <i className="fa-solid fa-magnifying-glass text-muted"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </>
          ) : (
            <button
              onClick={() => setFilter(true)}
              title="Filter"
              className="teams_button border rounded-1 fs-12"
            >
              <i className="fa-solid fa-filter text-muted"></i>
            </button>
          )}
        </div>
        <button
          title="Download Log Data"
          className="teams_button border rounded-1 fs-12"
          onClick={setToggle7}
        >
          <i className="fa-solid fa-download text-muted mt-1"></i>
        </button>
      </div>
      <FaildTableData
        failedLoginData={failedLoginData}
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        loading={loading}
      />
      <DownloadFaildDataModal
        Show={!toggle7}
        Hide={setToggle7}
        Size={"md"}
        Title="Faild login Data"
        Submit={"Faild Login data"}
      />
    </>
  );
};

export default FaildData;
