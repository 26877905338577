import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const workflowServices = {
  addWorkflow,
  getWorkflows,
  updateWorkflow,
  deleteWorkflow,
  getAllWorkflow,
  addMilestone,
  getMilestones,
  searchWorkflows,
  updateMilestone,
  deleteMilestone,
  deleteAllMilestone,
  getActiveTemplate,
  getActiveWorkflow,
  updateMilestone,
  addTask,
  updateTask,
  addTaskComment,
  updateTaskComment,
  getAllTaskComment,
  getWorkflowByPhase,
  getWorkflowByStudy,
  getAllQuatation,
  getQuatationByStudy,
  deleteTask,
  addAssignedStudyAndTask,
  getAllTaskByWorkflowId,
  getAllTaskByWorkflowForClient,
  getTemplateForMilestone,
  getTemplateForTask,
  getAllTaskByWorkflow,
  getAllTaskByUser
};

//  add workflow
async function addWorkflow(userData) {
  const response = await fetch(
    `${config.API_URL}addWorkflow`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//delete workflow
async function deleteWorkflow(_id) {
  const response = await fetch(
    `${config.API_URL}deleteWorkflow/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//deleteTask
async function deleteTask(_id) {
  const response = await fetch(
    `${config.API_URL}deleteTask/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// getWorkflow
async function getWorkflows() {
  const response = await fetch(
    `${config.API_URL}getWorkflows`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//update workflow\
async function updateWorkflow(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateWorkflow/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//add milestone
async function addMilestone(userData, _id) {
  const response = await fetch(
    `${config.API_URL}addMilestone/${_id}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//addTaskComment
async function addTaskComment(userData, _id) {
  const response = await fetch(
    `${config.API_URL}addTaskComment/${_id}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getMilestones
async function getMilestones(id) {
  const response = await fetch(
    `${config.API_URL}getMilestones/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//get_All_task_by_workflow
async function getAllTaskByWorkflow(workFlowId) {
  const response = await fetch(
    `${config.API_URL}getAllTaskByWorkflow/${workFlowId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}


//workflow Calendar
async function getAllTaskByUser(userId) {
  const response = await fetch(
    `${config.API_URL}getAllTaskByUser/${userId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getAllTaskByWorkflowId
async function getAllTaskByWorkflowId(id) {
  const response = await fetch(
    `${config.API_URL}getAllTaskByWorkflowId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getAllTaskByWorkflowForClient
async function getAllTaskByWorkflowForClient(userworkflowid, studyId) {
  const response = await fetch(
    `${config.API_URL}getAllTaskByWorkflowForClient/${userworkflowid}?studyID=${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//updatemilestone
async function updateMilestone(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateMilestone/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//delete milestone
async function deleteMilestone(_id) {
  const response = await fetch(
    `${config.API_URL}deleteMilestone/${_id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

//delete  alll milestone
async function deleteAllMilestone(_id) {
  const response = await fetch(
    `${config.API_URL}deleteAllMilestone/${_id}`,
    headersWithAuth("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// getAllWorkflow
async function getAllWorkflow() {
  const response = await fetch(
    `${config.API_URL}getAllWorkflow`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//   searchWorkflows
async function searchWorkflows(name) {
  const response = await fetch(
    `${config.API_URL}searchWorkflows?name=${name}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getActiveTemplate
async function getActiveTemplate() {
  const response = await fetch(
    `${config.API_URL}getActiveTemplate`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//getActiveWorkflow
async function getActiveWorkflow() {
  const response = await fetch(
    `${config.API_URL}getActiveWorkflow`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//add Task
async function addTask(userData, _id) {
  const response = await fetch(
    `${config.API_URL}addTask/${_id}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Task
async function updateTask(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateTask/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//updateTaskComment
async function updateTaskComment(_id, userData) {
  const response = await fetch(
    `${config.API_URL}updateTaskComment/${_id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getAllTaskComment
async function getAllTaskComment(_id) {
  const response = await fetch(
    `${config.API_URL}getAllTaskComment/${_id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getWorkflowByPhase
async function getWorkflowByPhase(id) {
  const response = await fetch(
    `${config.API_URL}getWorkflowByPhase/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getWorkflowByStudy
async function getWorkflowByStudy(id) {
  const response = await fetch(
    `${config.API_URL}getWorkflowByStudy/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getQuatationByStudy
async function getQuatationByStudy(id) {
  const response = await fetch(
    `${config.API_URL}getQuatationByStudy/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// getAllQuatation
async function getAllQuatation() {
  const response = await fetch(
    `${config.API_URL}getAllQuatation`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
//addAssignedStudyAndTask
async function addAssignedStudyAndTask(userData) {
  const response = await fetch(
    `${config.API_URL}addAssignedStudyAndTask`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getTemplateForMilestone
async function getTemplateForMilestone(_id) {
  const response = await fetch(
    `${config.API_URL}getTemplateForMilestone/${_id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getTemplateForTask
async function getTemplateForTask(_id) {
  const response = await fetch(
    `${config.API_URL}getTemplateForTask/${_id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//  Upload Document
async function replaceDocumentFile(formData) {
  const response = await axios.post(
    `${config.API_URL_USER_ONBOARDING}replaceDocumentFile`,
    formData
  );
  const data = response?.data;
  handleResponse(data);
  return data;
}
