import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Collapse, OverlayTrigger, Popover, Fade } from "react-bootstrap";

function Sidebar({
  roleType,
  projectId,
  isCollapsed,
  setProjectId,
  openlearning,
  qutationData,
  setOpenlearning,
  toggleCollpased,
  userworkflowList,
  projectStudyData,
  isWorkflowCollapsed,
  isCollapseQuotations,
  isCollapseTempelates,
  getAllWorkflowForUser,
  toggleWorkFlowCollpased,
  toggleQuotationsCollpased,
  toggleTempelatesCollpased,
  userSideTab,
  setUserSideTab,
  setWorkFlowId,
  workFlowId,
}) {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [activePopover, setActivePopover] = useState(null);
  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const currentPath = splitUrl[3];

  const handlePopoverClick = (placement) => {
    setActivePopover(activePopover === placement ? null : placement);
  };

  const { studyId } = useParams();
  const loginType = localStorage.getItem("loginType");
  const path = localStorage.getItem("path", "clientstudy");

  useEffect(() => {
    getAllWorkflowForUser();
  }, [studyId]);
  return (
    <>
      <div
        className="sidebar_section overflow-auto border-end position-relative"
        style={{ height: "calc(100vh - 88px)" }}
      >
        {!isCollapsed ? (
          <div className="sidebar_element">
            {currentPath !== "clientstudy" && loginType !== "study" && (
              <div
                className={`${currentPath === "analytics" ? "sidebarMenuActive" : ""
                  } border-bottom`}
              >
                <NavLink to={"/analytics"}>
                  <div className="sidebarmenu">
                    <div className="sidebar_element">
                      <i className="fa-solid fa-chart-line me-2"></i>Analytics
                    </div>
                  </div>
                </NavLink>
              </div>
            )}
            {(currentPath === "clientstudy" || currentPath === "study") && (
              <div
                className={`${openlearning === "openanalyticsClient" && "sidebarMenuActive"
                  } border-bottom`}
              >
                <div
                  onClick={() => {
                    setOpenlearning("openanalyticsClient");
                    setUserSideTab("")
                    setWorkFlowId("");
                  }}
                >
                  <div className="sidebarmenu">
                    <div className="sidebar_element">
                      <i className="fa-solid fa-chart-line me-2"></i>Analytics
                    </div>
                  </div>
                </div>
              </div>
            )}
            {path === "clientstudy"
              ? ""
              : roleType === "client" && (
                <div
                  className={` ${currentPath === "team" ? "sidebarMenuActive" : ""
                    } border-bottom`}
                >
                  <NavLink to={"/team"}>
                    <div className="sidebarmenu">
                      <div className="sidebar_element">
                        <i className="fa-solid fa-users me-2"></i>Teams
                      </div>
                    </div>
                  </NavLink>
                </div>
              )}
            {path === "clientstudy"
              ? ""
              : roleType === "client" && (
                <div
                  className={` ${currentPath === "Sponsor" ? "sidebarMenuActive" : ""
                    } border-bottom`}
                >
                  <NavLink to={"/Sponsor"}>
                    <div className="sidebarmenu">
                      <div className="sidebar_element">
                        <i class="fa-solid fa-people-group me-2"></i>Sponsor
                      </div>
                    </div>
                  </NavLink>
                </div>
              )}
            {(path !== "clientstudy" && loginType !== "study") && roleType === "user" && (
              <div
                className={` ${currentPath === "userSpounser" ? "sidebarMenuActive" : ""
                  } border-bottom`}
              >
                <NavLink to={"/userSpounser"}>
                  <div className="sidebarmenu" onClick={() => {
                    setUserSideTab("");
                    setWorkFlowId("");
                    setOpenlearning("");
                  }}>
                    <div className="sidebar_element">
                      <i className="fa-solid fa-people-group me-2"></i> User Sponsor
                    </div>
                  </div>
                </NavLink>
              </div>
            )}
            {path === "clientstudy"
              ? ""
              : roleType === "client" && (
                <div
                  className={` ${currentPath === "workflow" ? "sidebarMenuActive" : ""
                    } border-bottom`}
                >
                  <NavLink to={"/workflow"}>
                    <div
                      className="hstack justify-content-between sidebarmenu"
                      onClick={toggleWorkFlowCollpased}
                    >
                      <div className="sidebar_element">
                        <i className="fa-solid fa-chart-gantt me-2"></i>
                        Workflow
                      </div>
                      <i
                        className={`fa-solid ${isWorkflowCollapsed
                          ? "fa-angles-right"
                          : "fa-angles-left"
                          }`}
                      ></i>
                    </div>
                  </NavLink>
                </div>
              )}

            {path === "clientstudy" || loginType === "study" ? "" :
              (roleType === "client" || roleType !== "user") && (
                <div className="">
                  <div
                    className={`hstack justify-content-between sidebarmenu ${open2 ? "sidebarMenuActive" : ""
                      } border-bottom`}
                    onClick={() => setOpen2(!open2)}
                  >
                    <div aria-expanded={open2} className="sidebar_element">
                      <i class="fa-solid fa-code-fork me-2"></i>
                      Organization Chart
                    </div>
                    <div className="symbol_i">
                      <i
                        className={`fa-solid ${!open2 ? "fa-chevron-down" : "fa-chevron-up"
                          }`}
                      ></i>
                    </div>
                  </div>
                  <Collapse in={open2}>
                    <div id="example-collapse-text">
                      <ul className="mb-0">
                        <NavLink to={"/designation"}>
                          <li
                            className={` ${currentPath === "designation"
                              ? "sidebarMenuActive"
                              : ""
                              } border-bottom`}
                          >
                            <i className="fa-regular fa-chart-bar me-2"></i>
                            Designation
                          </li>
                        </NavLink>
                        <NavLink to={"/organizationChart"}>
                          <li
                            className={`${currentPath === "organizationChart"
                              ? "sidebarMenuActive"
                              : ""
                              } border-bottom`}
                          >
                            <i class="fa-solid fa-code-fork me-2"></i>
                            Organization Chart
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </Collapse>
                </div>
              )
            }
            {path === "clientstudy" || loginType === "study" ? "" :
              (roleType === "client" || roleType !== "user") && (
                <div
                  className={` ${currentPath === "invoiceData" ? "sidebarMenuActive" : ""
                    } border-bottom`}
                >
                  <NavLink to={"/invoiceData"}>
                    <div className="sidebarmenu">
                      <div className="sidebar_element">
                        <i class="fa-solid fa-file-invoice-dollar me-2"></i>
                        Invoice
                      </div>
                    </div>
                  </NavLink>
                </div>

              )
            }
            {path === "clientstudy" || loginType === "study" ? "" :
              (roleType === "client" || roleType !== "user") && (
                <div
                  className={` ${currentPath === "chatbot" ? "sidebarMenuActive" : ""
                    } border-bottom`}
                >
                  <NavLink to={"/chatbot"}>
                    <div className="sidebarmenu">
                      <div className="sidebar_element">
                        <i class="fa-solid fa-headset me-2"></i>
                        Chatbot
                      </div>
                    </div>
                  </NavLink>
                </div>

              )
            }
            {path === "clientstudy"
              ? ""
              : roleType === "client" && (
                <div
                  className={`${currentPath === "template" ? "sidebarMenuActive" : ""
                    } border-bottom`}
                >
                  <NavLink to={"/template"}>
                    <div
                      className="hstack justify-content-between sidebarmenu"
                      onClick={toggleTempelatesCollpased}
                    >
                      <div className="sidebar_element">
                        <i class="fa-solid fa-table-list me-2"></i> Templates
                      </div>
                      <i
                        className={`fa-solid ${isCollapseTempelates
                          ? "fa-angles-right"
                          : "fa-angles-left"
                          }`}
                      ></i>
                    </div>
                  </NavLink>
                </div>
              )}
            {loginType !== "study" &&
              projectStudyData?.map(
                (
                  item,
                  index // Project data sidebar  listing in User Pannel
                ) => (
                  <div
                    key={index}
                    className={`py-2 px-3 workflowMenuList overflow-hidden border-bottom ${item?._id?.$oid === projectId ? "sidebarMenuActive" : ""
                      }`}
                    onClick={() => setProjectId(item?.projectID)}
                  >
                    <div
                      className={`${item?.status === "inactive" ? "text-muted" : ""
                        }`}
                    >
                      <NavLink to={`/studylist/${item?.projectID}`}>
                        <div>
                          <i class="fa-solid fa-file-invoice me-2"></i>
                          <span style={{ fontSize: "13px" }}>
                            {item?.projectName}
                          </span>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                )
              )}
            {loginType === "study" &&
              userworkflowList?.map((item, index) => (
                <div
                  key={index}
                  className={`py-2 px-3 workflowMenuList overflow-hidden border-bottom ${item?._id?.$oid === workFlowId
                    ? "sidebarMenuActive"
                    : ""
                    }`}
                  onClick={() => { setWorkFlowId(item?._id?.$oid); setUserSideTab(""); setOpenlearning("") }}
                >
                  <div
                    className={`${item?.status === "inactive" ? "text-muted" : ""
                      }`}
                  >
                    <div>
                      <i class="fa-brands fa-wordpress me-2"></i>
                      <span style={{ fontSize: "13px" }}>{item?.name}</span>
                    </div>
                  </div>
                </div>
              ))}
            {path === "clientstudy" && (
              <>
                {qutationData?.map((item, index) => (
                  <div
                    key={index}
                    className={`py-2 px-3 workflowMenuList overflow-hidden border-bottom ${item?._id?.$oid === workFlowId
                      ? "sidebarMenuActive"
                      : ""
                      }`}
                    onClick={() => {
                      setWorkFlowId(item?._id);
                      setOpenlearning("");
                    }}
                  >
                    <div
                      className={`${item?.status === "inactive" ? "text-muted" : ""
                        }`}
                    >
                      {/* <NavLink to={`/study/${item?.studyId}`}> */}
                      <div style={{ cursor: "pointer" }}>
                        <i class="fa-brands fa-wordpress me-2"></i>
                        <span style={{ fontSize: "13px" }}>{item?.name}</span>
                      </div>
                      {/* </NavLink> */}
                    </div>
                  </div>
                ))}
              </>
            )}
            {roleType === "client" && path !== "clientstudy" && (
              <div
                className={` ${currentPath === "e-learning-management"
                  ? "sidebarMenuActive"
                  : ""
                  } border-bottom`}
              >
                <NavLink to={"/e-learning-management"}>
                  <div className="sidebarmenu">
                    <div className="sidebar_element">
                      <i className="fa-solid fa-list-check me-2"></i>
                      E-Learning Management
                    </div>
                  </div>
                </NavLink>
              </div>
            )}
            {path === "clientstudy" && (
              <div
                className={` ${openlearning === "eLearningMgmt" && "sidebarMenuActive"
                  } border-bottom`}
              >
                <div
                  onClick={() => {
                    setOpenlearning("eLearningMgmt");
                    setWorkFlowId("");
                  }}
                >
                  <div className="sidebarmenu">
                    <div className="sidebar_element">
                      <i className="fa-solid fa-list-check me-2"></i>
                      E-Learning Management
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loginType === "study" && (
              <div
                onClick={() => { setUserSideTab("elearning"); setWorkFlowId(""); setOpenlearning("") }}
                className={` ${userSideTab === "elearning" ? "sidebarMenuActive" : ""
                  } border-bottom`}
              >
                <div className="sidebarmenu">
                  <div className="sidebar_element">
                    <i class="fa-solid fa-chalkboard-user me-2"></i>
                    E-Learning
                  </div>
                </div>

              </div>
            )}

            {roleType === "user" && path !== "clientstudy" && loginType !== "study" && (
              <div
                onClick={() => {
                  setUserSideTab("elearning");
                  setWorkFlowId("");
                  setOpenlearning("");
                }}
                className={` ${userSideTab === "elearning" ? "sidebarMenuActive" : ""} border-bottom`}
              >
                <NavLink to={"/e-learning"}>
                  <div className="sidebarmenu">
                    <div className="sidebar_element">
                      <i className="fa-solid fa-chalkboard-user me-2"></i>
                      E-Learning Global
                    </div>
                  </div>
                </NavLink>
              </div>
            )}
            {path === "clientstudy"
              ? ""
              : roleType === "client" && (
                <div className="">
                  <div
                    className={`hstack justify-content-between sidebarmenu ${open1 ? "sidebarMenuActive" : ""
                      } border-bottom`}
                    onClick={() => setOpen1(!open1)}
                  >
                    <div aria-expanded={open1} className="sidebar_element">
                      <i class="fa-solid fa-file-waveform me-2"></i>Quotation
                    </div>
                    <div className="symbol_i">
                      <i
                        className={`fa-solid ${!open1 ? "fa-chevron-down" : "fa-chevron-up"
                          }`}
                      ></i>
                    </div>
                  </div>
                  <Collapse in={open1}>
                    <div id="example-collapse-text">
                      <ul className="m-0">
                        <NavLink to={"/phasemetadata"}>
                          <li
                            className={` ${currentPath === "phasemetadata"
                              ? "sidebarMenuActive"
                              : ""
                              } border-bottom`}
                          >
                            <i class="fa-solid fa-wrench me-2"></i>
                            Phase Estimate
                          </li>
                        </NavLink>

                        <NavLink to={"/phase"}>
                          <li
                            className={`hstack justify-content-between ${currentPath === "phase"
                              ? "sidebarMenuActive"
                              : ""
                              } border-bottom h-stack`}
                            onClick={toggleQuotationsCollpased}
                          >
                            <span>
                              <i class="fa-solid fa-hourglass-half me-2"></i>
                              Phase
                            </span>
                            <i
                              className={`fa-solid ${isCollapseQuotations
                                ? "fa-angles-right"
                                : "fa-angles-left"
                                }`}
                            ></i>


                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </Collapse>
                </div>
              )}
            {path === "clientstudy"
              ? ""
              : roleType === "client" && (
                <div className="">
                  <div
                    className={`hstack justify-content-between sidebarmenu ${open ? "sidebarMenuActive" : ""
                      } border-bottom`}
                    onClick={() => setOpen(!open)}
                  >
                    <div aria-expanded={open} className="sidebar_element">
                      <i className="fa-solid fa-gears me-2"></i>Settings
                    </div>
                    <div className="symbol_i">
                      <i
                        className={`fa-solid ${!open ? "fa-chevron-down" : "fa-chevron-up"
                          }`}
                      ></i>
                    </div>
                  </div>
                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <ul>
                        <NavLink to={"/profile"}>
                          <li
                            className={` ${currentPath === "profile"
                              ? "sidebarMenuActive"
                              : ""
                              } border-bottom`}
                          >
                            <i className="fa-solid fa-address-card me-2"></i>
                            Profile
                          </li>
                        </NavLink>
                        <NavLink to={"/apperance"}>
                          <li
                            className={` ${currentPath === "apperance"
                              ? "sidebarMenuActive"
                              : ""
                              } border-bottom`}
                          >
                            <i className="fa-solid fa-palette me-2"></i>
                            Appearance
                          </li>
                        </NavLink>
                        <NavLink to={"/logdata"}>
                          <li
                            className={` ${currentPath === "logdata"
                              ? "sidebarMenuActive"
                              : ""
                              } border-bottom`}
                          >
                            <i className="fa-solid fa-clock-rotate-left me-2"></i>
                            Log Data
                          </li>
                        </NavLink>
                        <NavLink to={"/failddata"}>
                          <li
                            className={` ${currentPath === "failddata"
                              ? "sidebarMenuActive"
                              : ""
                              } border-bottom`}
                          >
                            <i className="fa-solid fa-ban me-2"></i>
                            Failed Data
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </Collapse>
                </div>
              )}

            {/* {path === "clientstudy" && (
              <div
                className={` ${
                  currentPath === "e-learning-management"
                    ? "sidebarMenuActive"
                    : ""
                } border-bottom`}
              >
                <NavLink to={`/clientstudy/${studyId}/e-learning-management`}>
                  <div className="sidebarmenu">
                    <div className="sidebar_element">
                      <i class="fa-solid fa-list-check me-2"></i>E-Learning
                      Management
                    </div>
                  </div>
                </NavLink>
              </div>
            )} */}

            <div className="sidebarmenu border-top position-absolute bottom-0 bg-white">
              <div className="sidebar_element" onClick={toggleCollpased}>
                <i
                  className={`fa-solid ${isCollapsed ? "fa-angles-right me-2" : "fa-angles-left me-2"
                    }`}
                ></i>
                Collapse Menu
              </div>
            </div>
          </div>
        ) : (
          <div className="sid_li">
            <ul className="p-0">
              {(path !== "clientstudy" && loginType !== "study") && (

                <li
                  title="Analytics"
                  className={`text-center border-bottom ${currentPath === "analytics" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5, cursor: "pointer" }}
                  onClick={() => {
                    setUserSideTab("")
                  }}
                >
                  <NavLink to={"/analytics"}>
                    <i className="fa-solid fa-chart-line"></i>
                  </NavLink>
                </li>
              )}
              {(path === "clientstudy" || loginType === "study") && (
                <li
                  title=" ClientStudy Analytics"
                  className={`text-center border-bottom ${openlearning === "openanalyticsClient" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5, cursor: "pointer" }}
                >
                  <div
                    onClick={() => {
                      setOpenlearning("openanalyticsClient");
                      setUserSideTab("")
                      setWorkFlowId("");
                    }}
                  >
                    <i className="fa-solid fa-chart-line"></i>
                  </div>
                </li>
              )}
              {path === "clientstudy" && (
                <li
                  title="ClientStudy E learning Management"
                  className={`text-center border-bottom ${openlearning === "eLearningMgmt" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5, cursor: "pointer" }}
                >
                  <div
                    onClick={() => {
                      setOpenlearning("eLearningMgmt");
                      setUserSideTab("")
                      setWorkFlowId("");
                    }}
                  >
                    <i className="fa-solid fa-list-check"></i>
                  </div>
                </li>
              )}

              {(path !== "clientstudy" && roleType !== "user") && (
                <li
                  title="Teams"
                  className={`text-center border-bottom ${currentPath === "team" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5 }}
                >
                  <NavLink to={"/team"}>
                    <i className="fa-solid fa-users"></i>
                  </NavLink>
                </li>
              )}
              {currentPath !== "clientstudy" && roleType === "client" && (
                <li
                  title="Sponsor"
                  className={`text-center border-bottom ${currentPath === "Sponsor" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5 }}
                >
                  <NavLink to={"/Sponsor"}>
                    <i className="fa-solid fa-people-group"></i>
                  </NavLink>
                </li>
              )}
              {currentPath !== "clientstudy" && roleType === "client" && (
                <li
                  title="Workflow"
                  className={`text-center border-bottom ${currentPath === "workflow" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5 }}
                >
                  <NavLink to={"/workflow"}>
                    <i className="fa-solid fa-chart-gantt"></i>
                  </NavLink>
                </li>
              )}
              {currentPath !== "clientstudy" && roleType === "client" && (
                <li
                  title="Organization Chart"
                  className={`text-center border-bottom ${currentPath === "organizationChart"
                    ? "sidebarMenuActive"
                    : ""
                    }`}
                  style={{ padding: 8.5, cursor: "pointer" }}
                >
                  <OverlayTrigger
                    trigger="click"
                    key="popover3"
                    placement="right"
                    overlay={
                      <Popover id="popover-right3">
                        <Popover.Body className="p-0">
                          <div id="example-collapse-textpop" className="d-grid">
                            <NavLink
                              to={"/designation"}
                              className={`${currentPath === "designation" ? "sidebarMenuActive" : ""} border-bottom`}
                            >
                              <div className="p-2 px-4">
                                <i class="fa-solid fa-user-tie me-2"></i>
                                Designation
                              </div>
                            </NavLink>
                            <NavLink
                              to={"/organizationChart"}
                              className={`${currentPath === "organizationChart" ? "sidebarMenuActive" : ""} border-bottom`}
                            >
                              <div className="p-2 px-4">
                                <i class="fa-solid fa-code-fork me-2"></i>
                                Org Chart
                              </div>
                            </NavLink>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                    show={activePopover === "popover3"}
                    onToggle={() => handlePopoverClick("popover3")}
                    rootClose={true}
                    transition={Fade}
                    delay={{ show: 250, hide: 400 }}
                  >
                    <i class="fa-solid fa-code-fork"></i>
                  </OverlayTrigger>
                </li>
              )}
              {currentPath !== "clientstudy" && roleType === "client" && (
                <li
                  title="Templates"
                  className={`text-center border-bottom ${currentPath === "template" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5 }}
                >
                  <NavLink to={"/template"}>
                    <i class="fa-solid fa-table-list"></i>
                  </NavLink>
                </li>
              )}
              {currentPath !== "clientstudy" && roleType === "client" && (
                <li
                  title="Invoice"
                  className={`text-center border-bottom ${currentPath === "invoices" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5 }}
                >
                  <NavLink to={"/invoices"}>
                    <i className="fa-solid fa-file-invoice-dollar"></i>
                  </NavLink>
                </li>
              )}

              {currentPath !== "clientstudy" && roleType === "client" && (
                <li
                  title="E-learning Management"
                  className={`text-center border-bottom ${currentPath === "e-learning-management"
                    ? "sidebarMenuActive"
                    : ""
                    }`}
                  style={{ padding: 8.5 }}
                >
                  <NavLink to={"/e-learning-management"}>
                    <i class="fa-solid fa-laptop-file"></i>
                  </NavLink>
                </li>
              )}
              {currentPath !== "clientstudy" && roleType === "client" && (
                <li
                  title="Quotation"
                  className={`text-center border-bottom ${currentPath === "phasemetadata" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5, cursor: "pointer" }}
                >
                  <OverlayTrigger
                    trigger="click"
                    key="popover2"
                    placement="right"
                    overlay={
                      <Popover id="popover-right2">
                        <Popover.Body className="p-0">
                          <div id="example-collapse-textpop" className="d-grid">
                            <NavLink
                              to={"/phasemetadata"}
                              className={`${currentPath === "phasemetadata" ? "sidebarMenuActive" : ""} border-bottom`}
                            >
                              <div className="p-2 px-4">
                                <i className="fa-solid fa-wrench me-2"></i>
                                Phase Estimate
                              </div>
                            </NavLink>
                            <NavLink
                              to={"/phase"}
                              className={`${currentPath === "phase" ? "sidebarMenuActive" : ""} border-bottom`}
                            >
                              <div className="p-2 px-4">
                                <i className="fa-solid fa-hourglass-half me-2"></i>
                                Phase
                              </div>
                            </NavLink>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                    show={activePopover === "popover2"}
                    onToggle={() => handlePopoverClick("popover2")}
                    rootClose={true}
                    transition={Fade}
                    delay={{ show: 250, hide: 400 }}
                  >
                    <i className="fa-solid fa-file-waveform"></i>
                  </OverlayTrigger>
                </li>
              )}

              {loginType !== "study" && roleType === "user" && (
                <li
                  title="User E learning "
                  className={`text-center border-bottom ${userSideTab === "elearning" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5, cursor: "pointer" }}
                >
                  <div
                    onClick={() => {
                      setUserSideTab("elearning");
                      setOpenlearning("");
                      setWorkFlowId("");
                      setUserSideTab("")
                    }}

                  >
                    <NavLink to={"/e-learning"}>
                      <i className="fa-solid fa-chalkboard-user"></i>
                    </NavLink>
                  </div>
                </li>
              )}
              {loginType === "study" && (
                <li
                  title="Study User E learning "
                  className={`text-center border-bottom ${userSideTab === "elearning" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5, cursor: "pointer" }}
                >
                  <div
                    onClick={() => {
                      setUserSideTab("elearning");
                      setOpenlearning("");
                      setWorkFlowId("");
                      setUserSideTab("")
                    }}

                  >
                    <NavLink to={"/e-learning"}>
                      <i className="fa-solid fa-chalkboard-user"></i>
                    </NavLink>
                  </div>
                </li>
              )}
              {(roleType === "client" && path !== "clientstudy") && (
                <li
                  title="Setting "
                  className={`text-center border-bottom ${currentPath === "profile" ? "sidebarMenuActive" : ""
                    }`}
                  style={{ padding: 8.5 }}
                >
                  <OverlayTrigger
                    trigger="click"
                    key="popover1"
                    placement="right"
                    overlay={
                      <Popover id="popover-right1">
                        <Popover.Body className="p-0">
                          <div id="example-collapse-textpop" className="d-grid">
                            <NavLink
                              to={"/profile"}
                              className={` ${currentPath === "profile"
                                ? "sidebarMenuActive"
                                : ""
                                } border-bottom`}
                            >
                              <div className="p-2 px-4">
                                <i className="fa-solid fa-address-card me-2"></i>
                                Profile
                              </div>
                            </NavLink>
                            <NavLink
                              to={"/apperance"}
                              className={` ${currentPath === "apperance"
                                ? "sidebarMenuActive"
                                : ""
                                } border-bottom`}
                            >
                              <div className="p-2 px-4">
                                <i className="fa-solid fa-palette me-2"></i>
                                Appearance
                              </div>
                            </NavLink>
                            <NavLink
                              to={"/logdata"}
                              className={` ${currentPath === "logdata"
                                ? "sidebarMenuActive"
                                : ""
                                } border-bottom`}
                            >
                              <div className="p-2 px-4">
                                <i className="fa-solid fa-clock-rotate-left me-2"></i>
                                Log Data
                              </div>
                            </NavLink>
                            <NavLink
                              to={"/failddata"}
                              className={` ${currentPath === "failddata"
                                ? "sidebarMenuActive"
                                : ""
                                }`}
                            >
                              <div className="p-2 px-4">
                                <i className="fa-solid fa-ban me-2"></i>Failed
                                Data
                              </div>
                            </NavLink>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                    show={activePopover === "popover1"}
                    onToggle={() => handlePopoverClick("popover1")}
                    rootClose={true}
                    transition={Fade}
                    delay={{ show: 250, hide: 400 }}
                  >
                    <i className="fa-solid fa-gears"></i>
                  </OverlayTrigger>
                </li>
              )}
              <li
                title="Collapes Sidebar"
                className="text-center border-top"
                onClick={toggleCollpased}
                style={{ paddingLeft: 14, cursor: "pointer" }}
              >
                <div className="position-absolute bottom-0">
                  <i
                    className={`fa-solid ${isCollapsed ? "fa-angles-right" : "fa-angles-left"
                      }`}
                  ></i>
                </div>
              </li>

            </ul>
          </div>
        )}

      </div>
    </>
  );
}

export default Sidebar;
