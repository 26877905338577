import React from "react";
import { Col, Form, ListGroup } from "react-bootstrap";

const TemplateOption = ({ addFormSection }) => {
  const formElements = [
    { type: "heading", label: "Heading", icon: "heading" },
    { type: "date", label: "Date", icon: "calendar-days" },
    // { type: "time", label: "Time", icon: "clock" },
    { type: "textinput", label: "Text-Input [short]", icon: "keyboard" },
    { type: "numberinput", label: "Number Input", icon: "list-ol" },
    { type: "textarea", label: "Text-area [long]", icon: "pen-to-square" },
    { type: "checkbox", label: "CheckBox", icon: "square-check" },
    { type: "radio", label: "Radio", icon: "list-check" },
    { type: "select", label: "Select", icon: "check" },
    { type: "uploadfile", label: "Upload file", icon: "upload" },
    { type: "formtable", label: "Table", icon: "table-list" },
    { type: "reating", label: "Rating", icon: "star" },
    { type: "scale", label: "Scale", icon: "expand" },
    { type: "termsConditions", label: "Condition", icon: "question" },
    { type: "separator", label: "Separator", icon: "text-width" },
    { type: "footernote", label: "Footer-Note", icon: "note-sticky" },
    { type: "signaturebox", label: "Signature", icon: "b" },
  ];

  return (
    <div className="form-option">
      <div
        className="p-2 px-2 text-center position-sticky top-0 bg-light"
        style={{ zIndex: 9 }}
      >
        <h4 className=" fw-bold">Tools</h4>
      </div>
      <ListGroup className="">
        {formElements.map((element, index) => (
          <ListGroup.Item
            action
            key={index}
            onClick={() => addFormSection(element.type)}
          >
            <i className={`fa-solid fa-${element.icon} me-3`} />
            {element.label}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default TemplateOption;
