import React, { useEffect, useState } from "react";
import UseToggle from "../../Hooks/UseToggle";
import { Spinner, Table } from "react-bootstrap";
import { quotationServices } from "../../APIServices/quotationServices";
import { NoDataFoundTable } from "../../Hooks/NoDataFoundTable";
import Paginations from "../../Hooks/Paginations";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../Component/RightClick/RightClick";
import Swal from "sweetalert2";
import Moment from "react-moment";
import PhaseEstimateOffcanvas from "./QuotatioOffcanvas/PhaseEstimateOffcanvas";

const PhaseEstimate = ({ phaseData, getAllPhase ,loading}) => {
  const { toggle1, setToggle1 } = UseToggle();
  const [selectedRow, setSelectedRow] = useState(null);
  const [servicesDetails, setServicesDetails] = useState("");

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "inherit";
    }
  };

  const deletePhase = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Services!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await quotationServices.deletePhase(_id);
        if (results?.status_code === 200) {
          getAllPhase();
        }
      }
    });
  };

  const handleRowClick = (_id, status) => {
    updatePhase(_id, status);
  };

  const updatePhase = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Services Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await quotationServices.updatePhase(_id, data);
        if (results?.status_code === 200) {
          getAllPhase();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = phaseData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(phaseData?.length / itemsPerPage);

  useEffect(() => {
    getAllPhase();
  }, []);

  return (
    <div>
      <>
        <div className="hstack p-2 inputWorkFlow gap-2 px-1 border-bottom">
          <button
            onClick={setToggle1}
            title="Add Quotations"
            className="teams_button border rounded-1 fs-12"
          >
            <i className="fa-solid fa-plus text-muted mt-1"></i>
          </button>
        </div>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "65vh" }}
          >
            <Spinner animation="border" />
          </div>
        ) : result?.length > 0 ? (
          <>
            <div
              className="overflow-auto m-1"
              style={{ height: "calc(100vh - 130px" }}
            >
              <Table
                id="resizable-table"
                bordered
                className="custom-table"
                striped
                border
                hover
              >
                <thead className="position-sticky" style={{ top: -1 }}>
                  <tr style={{ cursor: "pointer" }}>
                    <th style={{ border: "1px solid #896e6e5e", width: 66 }}>
                      Phase
                    </th>
                    <th style={{ border: "1px solid #896e6e5e" }}>
                      Service Name
                    </th>
                    <th style={{ border: "1px solid #896e6e5e" }}>Status</th>
                    <th style={{ border: "1px solid #896e6e5e" }}>
                      Create Date | Time
                    </th>
                    <th style={{ border: "1px solid #896e6e5e" }}>
                      Update Date | Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {result?.map((item, index) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={item.id}
                      className={
                        selectedRow === item?._id?.$oid ? "selected" : ""
                      }
                      onClick={() => setSelectedRow(item._id.$oid)}
                    >
                      <td>
                        <ContextMenuTrigger
                          id={`clientData${index}`}
                          collect={() => ({
                            id: `clientData${index}` + 1,
                          })}
                        >
                          {startIndex + index + 1}
                        </ContextMenuTrigger>
                        <RightClick
                          ContextId={`clientData${index}`}
                          Menu1
                          MenuName1={
                            item.status === "active" ? "Inctive" : "Active"
                          }
                          icons1={
                            <i
                              class={`fa-solid fa-circle ${
                                item.status === "active"
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            ></i>
                          }
                          className1={"text-white"}
                          handleClick1={() =>
                            handleRowClick(
                              item._id.$oid,
                              item.status === "active" ? "inactive" : "active"
                            )
                          }
                          Menu3
                          MenuName3={"Delete"}
                          icons3={
                            <i class="fa-solid fa-trash-can text-danger"></i>
                          }
                          className3={"text-white "}
                          handleClick3={() => deletePhase(item?._id?.$oid)}
                        />
                      </td>
                      <td>
                        {" "}
                        <ContextMenuTrigger
                          id={`clientData${index}`}
                          collect={() => ({
                            id: `clientData${index}` + 1,
                          })}
                        >
                          {item.phaseName}
                        </ContextMenuTrigger>
                      </td>
                      <td>
                        <ContextMenuTrigger
                          id={`clientData${index}`}
                          collect={() => ({
                            id: `clientData${index}` + 1,
                          })}
                        >
                          <div style={{ color: getStatusColor(item.status) }}>
                            {item.status}
                          </div>
                        </ContextMenuTrigger>
                      </td>
                      <td>
                        <ContextMenuTrigger
                          id={`clientData${index}`}
                          collect={() => ({
                            id: `clientData${index}` + 1,
                          })}
                        >
                          <Moment format="DD MMM YYYY | HH:MM">
                            {item.created_at}
                          </Moment>
                        </ContextMenuTrigger>
                      </td>
                      <td>
                        {" "}
                        <ContextMenuTrigger
                          id={`clientData${index}`}
                          collect={() => ({
                            id: `clientData${index}` + 1,
                          })}
                        >
                          <Moment format="DD MMM YYYY | HH:MM">
                            {item.updated_at}
                          </Moment>
                        </ContextMenuTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="position-sticky bottom-0 bg-white">
                <Paginations
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  totalItems={phaseData?.length}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "80vh",
              justifyContent: "center",
            }}
          >
            <NoDataFoundTable MSG={"Phase Table is Not Found"} />
          </div>
        )}

        <PhaseEstimateOffcanvas
          show={!toggle1}
          onHide={setToggle1}
          getAllPhase={getAllPhase}
          servicesDetails={servicesDetails}
        />
      </>
    </div>
  );
};

export default PhaseEstimate;
