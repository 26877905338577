import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import Paginations from "../../../Hooks/Paginations";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../Hooks/NoDataFoundTable";

const FaildTableData = ({
  failedLoginData,
  tableRef,
  onMouseDown,
  loading,
}) => {
  const getActivityColor = (activity) => {
    switch (activity.trim().toLowerCase()) {
      case "entered wrong password":
        return "red";
      default:
        return "inherit";
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = failedLoginData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(failedLoginData?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <SpinerDataLoader />
      ) : result?.length > 0 ? (
        <>
          <div
            className="table-container overflow-auto m-1"
            style={{ height: "calc(100vh-130px" }}
          >
            <Table
              id="resizable-table"
              className="custom-table"
              ref={tableRef}
              striped
              bordered
              hover
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th
                    style={{ width: 80 }}
                    className="text-center"
                    onMouseDown={(e) => onMouseDown(e, 0)}
                  >
                    sr.
                  </th>
                  <th
                    style={{ width: 315 }}
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    Email
                  </th>
                  <th
                    style={{ width: 290 }}
                    onMouseDown={(e) => onMouseDown(e, 2)}
                  >
                    Password
                  </th>
                  <th
                    style={{ width: 250 }}
                    onMouseDown={(e) => onMouseDown(e, 3)}
                  >
                    Message
                  </th>
                  <th
                    style={{ width: 295 }}
                    onMouseDown={(e) => onMouseDown(e, 4)}
                  >
                    Date | Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr key={index}>
                    <td>{startIndex + index + 1}</td>
                    <td>{item.email}</td>
                    <td>{item.password}</td>
                    <td style={{ color: getActivityColor(item.msg) }}>
                      {item.msg}
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.timestamp}
                      </Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 py-2 bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={failedLoginData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <NoDataFoundTable MSG={"Faild Data  Not Found"} />
      )}
    </>
  );
};

export default FaildTableData;
