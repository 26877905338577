import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import { Form, Spinner, Table } from "react-bootstrap";
import { NoDataFoundTable, SpinerDataLoader } from "../../../Hooks/NoDataFoundTable";
import { elearningServices } from "../../../APIServices/elearningServices";
import { teamServices } from "../../../APIServices/teamServices";
import Paginations from "../../../Hooks/Paginations";
import { useParams } from "react-router-dom";
const AssignUser = ({ elearningID }) => {
  const [loader, setLoader] = useState("");
  const [buttonLoader, setButtonLoader] = useState("");
  const [selectedIds, setSelectedIds] = useState([])
  const [activeUser, setActiveUser] = useState([]);
  const [getAllElearnigUser, setGetAllElearnigUser] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");


  const { studyId } = useParams();

  const handleCheckboxChange = (item, isChecked) => {
    const userId = item?._id?.$oid;
    if (isChecked) {
      setSelectedIds((prev) => [...new Set([...prev, userId])]);
    } else {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== userId));
    }
  };


  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = activeUser.map((user) => user?._id?.$oid);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };


  const getAllUsersByElearning = async () => {
    let data = await elearningServices.getAllUsersByElearning(elearningID);
    setGetAllElearnigUser(data?.data);
    setSelectedIds(data?._id?.$oid || []);
    console.log("data", data);
    setLoader(false);
  };
  useEffect(() => {
    getAllUsersByElearning();
  }, [elearningID]);


  const getActiveUsers = async () => {
    let data = await teamServices.getActiveUsers();
    setActiveUser(data?.data);
    setSelectedIds(data?._id?.$oid || []);
    setLoader(false);
  };
  useEffect(() => {
    getActiveUsers();
  }, []);

  console.log("activeUser", activeUser);
  console.log("getAllElearnigUser", getAllElearnigUser);




  const isAllSelected = activeUser?.length > 0 && selectedIds?.length === activeUser?.length;


  const assignTraining = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.userID = selectedIds;
    userData.studyID = studyId ? studyId : "undefined";
    let data = await elearningServices.addAssignedUserForTraining(elearningID, userData);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      getAllUsersByElearning();
      setButtonLoader(false);
    } else {
      setButtonLoader(false);
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = activeUser?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(activeUser?.length / itemsPerPage);
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "95%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : result?.length > 0 ? (
          <>
            <Table
              id="resizable-table"
              striped
              hover
              className="m-0 custom-table"
            >
              <thead className="thead-sticky top-0">
                <tr>
                  <th style={{ width: 80 }}>
                    <Form.Check
                      checked={isAllSelected}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      type="checkbox" />
                  </th>
                  <th>Admin ID</th>
                  <th>Full Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {result.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Check type="checkbox"
                        checked={getAllElearnigUser?.some(data => data._id === item?._id?.$oid) || selectedIds.includes(item?._id?.$oid)}
                        onChange={(e) => handleCheckboxChange(item, e.target.checked)} />
                    </td>
                    <td>{item.fullName}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={activeUser?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
            <div className="position-sticky bottom-0 w-100 p-2 text-end bg-white button_type">
              <button className="rounded-1 mb-3" onClick={assignTraining}>
                {buttonLoader ? (
                  <Spinner className="me-2" style={{ width: 15, height: 15 }} />
                ) : (
                  <i className="fa-solid fa-right-to-bracket me-2"></i>
                )}
                Assign
              </button>
            </div>
          </>
        ) : (
          <NoDataFoundTable MSG={"User Is Not Found"} />
        )}
      </SplitPane>
    </>
  );
};
export default AssignUser;


