import React, { useEffect, useState } from 'react'
import { Col, Container, Form, FormGroup, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { tempelatesServices } from '../../APIServices/tempelatesServices';

const RegularUserForm = () => {
    const [filledDesc, setFilledDesc] = useState("");
    const [sections, setSections] = useState([]);
    const [formData, setFormData] = useState([]);
    const [loader, setLoader] = useState("");
    const { _id } = useParams();

    const getDataEntryByTemplate = async () => {
        let data = await tempelatesServices.getDataEntryByTemplate(_id);
        let result = data?.dataEntry || [];
        setSections(result);
        const extractedFooternoteData = result
            .filter(item => item.type === "footernote")
            .map((item) => ({
                id: item.id,
                type: item.type,
                label: item.label,
                footerNote: item.footerNote,
            }));

        const headerSections = result || [];
        setSections(headerSections);
        const extractedHeaderData = result
            .filter(item => item.type === "heading")
            .map((item) => ({
                id: item.id,
                type: item.type,
                label: item.label,
            }));

        const combinedData = [...extractedFooternoteData, ...extractedHeaderData];
        setFormData(combinedData);
    };

    useEffect(() => {
        getDataEntryByTemplate();
    }, [_id]);

    const addDataEntry = async () => {
        setLoader(true);
        let userData = {};
        userData.templateID = _id;
        userData.dataEntry = formData;
        let data = await tempelatesServices.addDataEntry(userData);
        if (data?.status_code === 200) {
            getDataEntryByTemplate();
            setLoader(false);
        }
    }

    console.log("formData", formData);


    const handleDescClick = (sectionIndex) => {
        const newFilledDesc = [...filledDesc];
        newFilledDesc[sectionIndex] = !newFilledDesc[sectionIndex];
        setFilledDesc(newFilledDesc);

        const selectedSection = sections[sectionIndex];

        if (selectedSection) {
            const newEntry = {
                id: selectedSection.id,
                label: selectedSection.label,
                type: selectedSection.type,
                values: selectedSection.options.map(option => option.value),
            };
            const updatedFormData = [...formData];
            const existingIndex = updatedFormData.findIndex(item => item.id === newEntry.id);

            if (existingIndex !== -1) {
                updatedFormData[existingIndex] = newEntry;
            } else {
                updatedFormData.push(newEntry);
            }
            setFormData(updatedFormData);
        }
    };

    const renderButtons = (filled, sectionIndex, options) => {
        const totalOptions = options?.length || 1;

        return options?.map((option, optionIndex) => {
            const hue = (120 / (totalOptions - 1)) * optionIndex;
            const buttonColor = `hsl(${hue}, 100%, 50%)`;

            return (
                <button
                    key={option.id}
                    className={`btn btn-scale rounded-0 btn-scale-${optionIndex + 1} ${filled[sectionIndex] ? "filled" : ""}`}
                    onClick={() => handleDescClick(sectionIndex)}
                    style={{ backgroundColor: buttonColor, color: "#fff" }}
                >
                    {option.value}
                </button>
            );
        });
    };

    const handleInputChange = (e, item) => {
        const { value } = e.target;
        setFormData(prevState => {
            const updatedData = [...prevState];
            const index = updatedData.findIndex(data => data.id === item.id);
            if (index !== -1) {
                updatedData[index] = { ...updatedData[index], value };
            } else {
                updatedData.push({
                    id: item.id,
                    label: item.label,
                    type: item.type,
                    value: value
                });
            }
            return updatedData;
        });
    };

    const handleSelectChange = (e, item) => {
        const { value } = e.target;

        setFormData(prevState => {
            const updatedData = [...prevState];
            const index = updatedData.findIndex(data => data.id === item.id);

            if (index !== -1) {
                updatedData[index] = { ...updatedData[index], value };
            } else {
                updatedData.push({
                    id: item.id,
                    label: item.label,
                    type: item.type,
                    value: value
                });
            }
            return updatedData;
        });
    };

    const handleCheckboxChange = (e, item) => {
        const { checked, value } = e.target;

        setFormData(prevState => {
            let updatedData = [...prevState];
            const index = updatedData.findIndex(data => data.id === item.id);

            if (index === -1 && checked) {
                updatedData.push({ id: item.id, label: item.label, type: item.type, value: [value] });
            } else if (index !== -1) {
                const updatedValues = checked
                    ? [...new Set([...updatedData[index].value, value])]
                    : updatedData[index].value.filter(v => v !== value);

                updatedValues.length ? updatedData[index].value = updatedValues : updatedData.splice(index, 1);
            }

            return updatedData;
        });
    };

    const handleRadioChange = (e, item) => {
        const { value } = e.target;

        setFormData(prevState => {
            const updatedData = [...prevState];
            const index = updatedData.findIndex(data => data.id === item.id);

            if (index !== -1) {
                updatedData[index].value = value;
            } else {
                updatedData.push({
                    id: item.id,
                    label: item.label,
                    type: item.type,
                    value: value
                });
            }
            return updatedData;
        });
    };



    const handleFileChange = (e, item) => {
        const file = e.target.files[0];

        if (file) {
            setFormData(prevState => {
                const updatedData = [...prevState];
                const index = updatedData.findIndex(data => data.id === item.id);

                if (index !== -1) {
                    updatedData[index].value = file.name;
                } else {
                    updatedData.push({
                        id: item.id,
                        label: item.label,
                        type: item.type,
                        value: file.name
                    });
                }
                return updatedData;
            });
        }
    };

    const handleTableInputChange = (value, item, rowIndex, cellIndex) => {
        setFormData(prevState => {
            const updatedData = [...prevState];
            const index = updatedData.findIndex(data => data.id === item.id);

            if (index !== -1) {
                updatedData[index].rows[rowIndex][cellIndex] = value;
            } else {
                updatedData.push({
                    id: item.id,
                    label: item.label,
                    type: item.type,
                    rows: Array.from({ length: item.rows.length }, (_, rIndex) =>
                        Array.from({ length: item.columns.length }, (_, cIndex) =>
                            rIndex === rowIndex && cIndex === cellIndex ? value : item.rows[rIndex][cIndex]
                        )
                    )
                });
            }
            return updatedData;
        });
    };

    const handleCheckboxtwoChange = (item) => {
        const newEntry = {
            id: item.id,
            type: item.type,
            label: item.label,
            termConditions: item.termConditions
        };

        setFormData((prevData) => {
            const isAlreadyChecked = prevData.some(data => data.id === item.id);
            if (isAlreadyChecked) {
                return prevData.filter(data => data.id !== item.id);
            }
            return [...prevData, newEntry];
        });
    };

    console.log("sections", sections);


    return (
        <div className="overflow-auto mt-3 pt-2 w-50 m-auto shadow-sm" style={{ height: "calc(100vh - 50px" }}>
            <Container fluid>
                <Row>
                    {sections?.map((item, index) => (
                        <>
                            <Col md={12} key={index}>
                                {item?.type === "heading" && (
                                    <FormGroup className="d-flex justify-content-between align-items-center mb-2">
                                        <div>
                                            <Form.Label style={item?.labelStyle}>
                                                {item?.label} <span className="text-danger">*</span>
                                            </Form.Label>
                                        </div>
                                    </FormGroup>
                                )}
                            </Col>
                            <Col md={12} key={index}>
                                {item?.type === "textinput" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label> {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <Form.Control
                                            type="text"
                                            placeholder={item?.placeholder}
                                            value={formData.find(data => data.id === item.id)?.value || item?.value || ""}
                                            onChange={(e) => handleInputChange(e, item)}
                                            className="mb-2"
                                        />
                                    </>
                                )}
                            </Col>

                            <Col md={12}>
                                {item?.type === "numberinput" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label> {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>

                                        </FormGroup>
                                        <Form.Control
                                            type="number"
                                            placeholder={item?.placeholder}
                                            value={formData.find(data => data.id === item.id)?.value || item?.value || ""}
                                            onChange={(e) => handleInputChange(e, item)}
                                            className="mb-2"
                                        />
                                    </>
                                )}

                            </Col>
                            <Col md={12}>
                                {item?.type === "textarea" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <Form.Control as="textarea"
                                            placeholder={item?.placeholder}
                                            value={formData.find(data => data.id === item.id)?.value || item?.value || ""}
                                            onChange={(e) => handleInputChange(e, item)}
                                            style={{ height: '100px' }} className="mb-2" />
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "select" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <Form.Select className="mb-2"
                                            onChange={(e) => handleSelectChange(e, item)}
                                        >
                                            <option value="">Select</option>
                                            {item?.options?.map((option) => (
                                                <option selected={(option?.value === item?.value)} key={option.fid} value={option.value}>{option.value}</option>
                                            ))}
                                        </Form.Select>
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "checkbox" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>
                                                    {item?.label}
                                                </Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="tooltip-header">{item?.help}</Tooltip>}
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>

                                        <div className=" gap-2 mb-2">
                                            {item?.options?.map((option, index) => (
                                                <div key={option.id} className="d-flex align-items-center">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={option.id}
                                                        value={option.value}
                                                        checked={item?.value?.includes(option?.value)}
                                                        className="px-2"
                                                        onChange={(e) => handleCheckboxChange(e, item, option)}
                                                    />
                                                    <Form.Label htmlFor={option.id} className="m-0">
                                                        {option.value}
                                                    </Form.Label>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </Col>

                            <Col md={12}>
                                {item?.type === "radio" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        {item?.options?.map((option, index) => (
                                            <div key={option.id} className="d-flex align-items-center">
                                                <Form.Check
                                                    type="radio"
                                                    id={option.id}
                                                    value={option.value}
                                                    checked={item?.value === option?.value} //for checked data to when found Api Maine Edit kiya hai 
                                                    name={`radio-${item?.id}`}
                                                    className="px-2"
                                                    onChange={(e) => handleRadioChange(e, item)}
                                                />
                                                <Form.Label htmlFor={option.id} className="m-0">
                                                    {option.value}
                                                </Form.Label>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Col>

                            <Col md={12}>
                                {item?.type === "date" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === true && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <Form.Control type="date"
                                            className="mb-2"
                                            value={formData.find(data => data.id === item.id)?.value || item?.value || ""}
                                            onChange={(e) => handleInputChange(e, item)}
                                            placeholder={item?.formate} />
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "separator" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <Form.Range className="mb-2" value={item?.orderNo} />
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "uploadfile" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <Form.Control type="file" className="mb-2" onChange={(e) => handleFileChange(e, item)} />
                                        <div className=" mt-2">
                                            <p><b className='text-success'>Uploaded File: </b>{item?.value}</p>
                                        </div>
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "reating" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <div className="gap-2 d-flex mb-2">
                                            <i class="fa-regular fa-star"></i>
                                            <i class="fa-regular fa-star"></i>
                                            <i class="fa-regular fa-star"></i>
                                            <i class="fa-regular fa-star"></i>
                                        </div>
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "scale" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center mb-2">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <div className="form-long_text-input mt-1 mb-2">
                                            <div className="chart-scale">
                                                {renderButtons(filledDesc, index, item?.options)}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "footernote" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center mb-2">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <div className=" form-long_text-input mt-1 mb-2">
                                            <footer>
                                                <div className="footer-note">
                                                    <p>&copy; {item?.footerNote}</p>
                                                </div>
                                            </footer>
                                        </div>
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "termsConditions" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center mb-2">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-header">{item?.help}</Tooltip>
                                                    }
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <div className="h-stack">
                                            <Form.Label className="d-flex align-items-center">
                                                <Form.Check type="checkbox" onChange={() => handleCheckboxtwoChange(item)} checked={!!item?.termConditions} />
                                                <span className="ms-2">{item?.termConditions}</span>
                                            </Form.Label>
                                        </div>
                                    </>
                                )}
                            </Col>
                            <Col md={12}>
                                {item?.type === "formtable" && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between align-items-center mb-2">
                                            <div>
                                                <Form.Label style={item?.labelStyle}>{item?.label}</Form.Label>  {item?.required === "on" && <span className="text-danger">*</span>}
                                            </div>
                                            <span className="text-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="tooltip-header">{item?.help}</Tooltip>}
                                                >
                                                    <i className="fa-solid fa-circle-question text-dark"></i>
                                                </OverlayTrigger>
                                            </span>
                                        </FormGroup>
                                        <Table bordered className="mb-2">
                                            <thead>
                                                <tr>
                                                    {item?.columns?.map((column, index) => (
                                                        <th key={index}>{column}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item?.rows?.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {row?.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>
                                                                <Form.Control
                                                                    type="text"
                                                                    className="border-0"
                                                                    defaultValue={cell?.value} // Use cell.value to show the actual value in the table
                                                                    onChange={(e) =>
                                                                        handleTableInputChange(e.target.value, item, rowIndex, cellIndex)
                                                                    }
                                                                />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                )}
                            </Col>
                        </>
                    ))}

                    <div className="text-end">
                        <Form.Group as={Col} md="12">
                            <div className="button_type">

                                <button
                                    type="submit"

                                    onClick={addDataEntry}
                                >
                                    {loader ? (
                                        <Spinner
                                            animation="border"
                                            style={{ width: 15, height: 15 }}
                                            className="me-2"
                                        />
                                    ) : null}
                                    Submit
                                </button>
                            </div>
                        </Form.Group>
                    </div>

                </Row>

            </Container>
        </div>
    )
}

export default RegularUserForm
