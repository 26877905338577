import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import SponsorList from "./SponsorList";

import UseToggle from "../../Hooks/UseToggle";
import AddSponsorClientModal from "./AddSponsorClientModal";
import ProjectList from "./ProjectList";
import StudyList from "./StudyList";
import { clientServices } from "../../APIServices/clientServices";
import SplitPane from "react-split-pane";
import QutationsList from "./QutationsList";
import ExportSponcerData from "./ExportSponcerData";
import SponcerViewProperty from "./SponcerViewProperty";

const Sponsor = ({ tableRef, onMouseDown }) => {
  const yResizerRef = useRef(null);
  const [activeTabs, setActiveTabs] = useState("properties");
  const { toggle, setToggle, toggle3, setToggle3 } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [propertiesData, setPropertiesData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [projectId, setProjectId] = useState(null);
  const [studyId, setStudyId] = useState(null);

  const getAllSponsor = async () => {
    let data = await clientServices.getAllSponsor();
    setClientData(data?.data);
    setLoading(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    getAllSponsor();
  }, []);

  return (
    <>
      <div className="hstack inputWorkFlow gap-2 py-2 px-1  border-bottom">
        <div className="filter_section hstack p-0">
          {filter ? (
            <>
              <div className="h-stack justify-content-between">
                <InputGroup>
                  <InputGroup.Text
                    onClick={() => {
                      setFilter(false);
                    }}
                    title="Cancel"
                    className="search_button fs-12 px-2"
                    style={{ padding: 2.5 }}
                  >
                    <i className="fa-solid fa-xmark text-muted"></i>
                  </InputGroup.Text>
                  <Form.Control type="search" className="search-bar" />
                  <InputGroup.Text
                    title="Search"
                    className="search_button fs-12 px-2"
                    style={{ padding: 2.5 }}
                  >
                    <i className="fa-solid fa-magnifying-glass text-muted"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </>
          ) : (
            <button
              onClick={() => setFilter(true)}
              title="Filter"
              className="teams_button border rounded-1 fs-12"
            >
              <i className="fa-solid fa-filter text-muted mt-1"></i>
            </button>
          )}
        </div>
        <button
          variant="outline-dark"
          title="Add Sponsor"
          onClick={setToggle}
          className="teams_button border rounded-1 fs-12"
        >
          <i class="fa-solid fa-user-plus text-muted mt-1"></i>
        </button>
        <div className="ms-auto px-2">
          <button
            onClick={setToggle3}
            title="Export Team  User"
            className="teams_button border rounded-1 fs-12"
          >
            <i class="fa-solid fa-file-arrow-down text-muted mt-1"></i>
          </button>
        </div>
      </div>
      <SplitPane
        split="horizontal"
        className="position-relative"
        defaultSize={selectedRow ? "40%" : "100%"}
      >
        <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
          <SponsorList
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            selectedRow={selectedRow}
            clientData={clientData}
            loading={loading}
            setPropertiesData={setPropertiesData}
            getAllSponsor={getAllSponsor}
            setSelectedRow={setSelectedRow}
          />
        </SplitPane>

        {selectedRow && (
          <div className={isFullScreen ? "full-screen-component" : ""}>
            <div className="hstack paginate fixed_tab_button justify-content-between border-bottom">
              <div className="hstack ms-2 gap-1">
                {[" Properties", "projects", "studies", "qutations"].map((tab) =>
                  (!projectId && tab === "studies") ||
                    (!studyId && tab === "qutations") ? (
                    ""
                  ) : (
                    <Button
                      key={tab}
                      className={` w-100 border-top rounded-top border-dark border-start border-end border-0 rounded-0 clientButton ${activeTabs === tab ? "active" : ""
                        }`}
                      onClick={() => setActiveTabs(tab)}
                      title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </Button>
                  )
                )}
              </div>
              <div
                className={`app-container ${isFullScreen ? "fullscreen" : ""}`}
              >
                <div className="toolbar-full-screen">
                  <i
                    className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                      }`}
                    title={!isFullScreen ? "Full Mode" : "Original Mode"}
                    onClick={toggleFullScreen}
                  ></i>
                </div>
              </div>
            </div>

            {activeTabs === "projects" ? (
              <ProjectList
                projectId={projectId}
                selectedRow={selectedRow}
                setProjectId={setProjectId}
              />
            ) : activeTabs === "studies" ? (
              <StudyList
                projectId={projectId}
                setProjectId={setProjectId}
                studyId={studyId}
                setStudyId={setStudyId}
              />
            ) : activeTabs === "qutations" ? (
              <QutationsList
                studyId={studyId}
                setStudyId={setStudyId}
                setSelectedRow={setSelectedRow}
                selectedRow={selectedRow}
              />
            ) : activeTabs === "properties" ? (
              <SponcerViewProperty
                propertiesData={propertiesData}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </SplitPane>
      <AddSponsorClientModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        clientData={clientData}
        getAllSponsor={getAllSponsor}
        Submit={"Add Client"}
      />
      <ExportSponcerData Show={!toggle3} Hide={setToggle3} Size={"md"} />
    </>
  );
};
export default Sponsor;
