import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";
import "react-quill/dist/quill.snow.css";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Container,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Offcanvas,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { workflowServices } from "../../APIServices/workflowServices";
import Swal from "sweetalert2";
import Multiselect from "multiselect-react-dropdown";
import { NavLink, useParams } from "react-router-dom";

const TaskDetailModal = ({
  Show,
  Hide,
  taskData,
  getMilestones,
  roleType,
  teamsData,
  sections,
  workFlowId,
  milestoneId,
  taskID,
  commentTaskId,
  selectedOptiones,
  setSelectedOptiones,
}) => {
  const [activeTab, setActiveTab] = useState("comments");
  const [headerTitle, setHeaderTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [options, setOptions] = useState([]);
  const [optiones, setOptiones] = useState([]);
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoadering] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [allTaskComment, setAllTaskComment] = useState([]);
  const [commentStatusMessage, setCommentStatusMessage] = useState("");
  const [dropdowns, setDropdowns] = useState([]);
  const [status, setStatus] = useState("");
  const [astatus, setAstatus] = useState("Pending");
  const [statusCode, setStatusCode] = useState("");
  const [selecteduserIds, setSelectedUserIds] = useState([]);
  const [openTextFormate, setOpenTextFormate] = useState(false);
  const [fileType, setFileType] = useState("");

  // >>>>>>>>>>>>Show Comment
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setComment((prevComment) => `${prevComment}\n[File: ${file.name}]`);
    }
    setFileType(e.target.value);
  };

  // >>>>>>>>>>>>

  const { studyId } = useParams();

  // >>>>>>>>>>Assign too Multiselect

  const handleSelect = (selectedList) => {
    setSelectedOptiones(selectedList);
    updateSelectedIds(selectedList);
  };

  const handleRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptiones(NewObjectData);
    updateSelectedIds(NewObjectData);
  };

  const updateSelectedIds = (selectedList) => {
    const ids = selectedList.map((item) => item.value);
    setSelectedUserIds(ids);
  };

  const fetchOptiones = async () => {
    const formattedOptiones = teamsData?.map((item) => ({
      label: item?.fullName,
      value: item?._id?.$oid, // Use the _id.$oid as the value
    }));

    setOptiones(formattedOptiones);
  };
  // >>>>>>>>>>>>>

  // ....................Tempelates Multiselect
  const fetchOptions = async () => {
    let data = await workflowServices.getTemplateForTask(taskID);
    if (data?.status_code === 200) {
      const formattedOptions = data?.data?.map((template) => ({
        label: template.name,
        value: template._id,
      }));
      setOptions(formattedOptions);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [taskID]);

  const handleCheck = (templateId) => {
    setCheckedState((prevStates) =>
      prevStates?.map((item) =>
        item.templateId === templateId
          ? { ...item, required: !item.required }
          : item
      )
    );
  };

  const templateSelect = (selectedList) => {
    setSelectedOptions(selectedList);
    let checkedData = selectedList?.map((item) => ({
      templateId: item?.value,
      required: false,
    }));
    setCheckedState(checkedData);
  };

  const templateRemove = (selectedList, removedItem) => {
    let NewObjectData = selectedList?.filter(
      (item) => !(item.value === removedItem?.value)
    );
    setSelectedOptions(NewObjectData);
  };
  // ..............................

  // FOr Add Dropdown Dynamically

  const addDropdown = () => {
    setDropdowns([...dropdowns, { id: uuidv4(), value: "" }]);
  };

  const handleInputChange = (index, event) => {
    const newDropdowns = dropdowns.map((dropdown, i) => {
      if (i === index) {
        return { ...dropdown, value: event.target.value };
      }
      return dropdown;
    });
    setDropdowns(newDropdowns);
  };

  const removeDropdown = (index) => {
    setDropdowns(dropdowns.filter((_, i) => i !== index));
  };

  const handleStatusChange = (e) => {
    setAstatus(e.target.value);
  };

  // >>>>>>>>>>>>>>>>>>>>>>

  // >>>>>>>>>>>>For Status Change

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const getColorForStatus = (status) => {
    switch (status) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "black";
    }
  };
  // >>>>>>>>>>>>>>>

  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const currentPath = splitUrl[3];

  const updateTask = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Task Details Sections!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.taskName = headerTitle;
        userData.startDate = startDate;
        userData.endDate = endDate;
        userData.description = description;
        userData.status = status;
        userData.templates = checkedState;
        let results = await workflowServices.updateTask(
          taskData?._id?.$oid,
          userData
        );
        if (results?.status_code === 200) {
          setLoader(false);
          clearFormData();
          getMilestones();
        }
      }
    });
  };

  const handleSubmit = async (e) => {
    setLoadering(true);
    e.preventDefault();
    let userData = {};
    userData.studyID = studyId;
    userData.taskID = taskData?.taskID;
    userData.userID = selecteduserIds;
    let data = await workflowServices.addAssignedStudyAndTask(userData);
    setStatusCode(data);
    if (data?.status_code === 200) {
      clearFormData();
      setLoadering(false);
    } else {
      setLoadering(false);
    }
  };

  // const updateTaskComment = async () => {
  //   let userData = {};
  //   userData.comments = comments;
  //   let results = await workflowServices.updateTask(
  //     taskData?._id?.$oid,
  //     userData
  //   );
  //   results?.status_code === 200;
  // };

  const addTaskComment = async (e) => {
    e.preventDefault();
    let taskID = taskData?.taskID;
    setLoader(true);
    let userData = {};
    userData.comment = comment;
    userData.file = fileType;
    let data = await workflowServices.addTaskComment(userData, commentTaskId);
    setCommentStatusMessage(data);
    if (data?.status_code === 200) {
      setLoader(false);
      setStatusCode(true);
    }
  };

  const getAllTaskComment = async () => {
    let data = await workflowServices.getAllTaskComment(commentTaskId);
    setAllTaskComment(data?.data);
  };

  useEffect(() => {
    getAllTaskComment();
  }, [commentTaskId]);

  const getActiveTemplate = async () => {
    let data = await workflowServices.getActiveTemplate();
    if (data?.status_code === 200) {
      const formattedOptions = data?.data?.map((template) => ({
        label: template.name,
        value: template._id?.$oid,
      }));
      setOptions(formattedOptions);
    }
  };

  console.log("options", options);

  const handleLoginClick = (_id) => {
    setLoader(true);
    window.location.href = `/form/${_id}`;
    setLoader(false);
  };



  const clearFormData = () => {
    Hide();
    setHeaderTitle("");
    setStartDate("");
    setEndDate("");
    setStatus("");
    setDescription("");
    setComment("");
    setLoader("");
  };

  useEffect(() => {
    setHeaderTitle(taskData?.taskName || "");
    setStartDate(taskData?.startDate || "");
    setEndDate(taskData?.endDate || "");
    setStatus(taskData?.status || "");
    setDescription(taskData?.description || "");
  }, [taskData]);

  console.log("taskData", taskData);


  useEffect(() => {
    fetchOptiones();
  }, [teamsData]);

  return (
    <>
      <div className="milestonemodal_Font">
        <Offcanvas
          show={Show}
          onHide={Hide}
          placement="end"
          style={{ width: "660px" }}
        >
          <Offcanvas.Header className="offcanvas_header">
            <Offcanvas.Title>
              <FormControl
                className="offcanvas_workflow w-100"
                type="text"
                onChange={(e) => setHeaderTitle(e.target.value)}
                value={headerTitle}
                readOnly={roleType === "user"}
              />
            </Offcanvas.Title>
            <div className="hstack gap-3">
              <div
                onClick={() => clearFormData()}
                className="offcanvas_Close"
                title="Close"
              >
                X
              </div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <div className="px-3 pt-2">
              <Container fluid>
                <Row>
                  {roleType === "user" && (
                    <Col md={6} className="p-1 mb-2">
                      <Form.Group>
                        <Form.Label>
                          <b>Start Date :</b>
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          style={{ height: 39 }}
                          placeholder="Select Date"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={startDate}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  {roleType === "user" && (
                    <Col md={6} className="p-1 mb-2">
                      <Form.Group>
                        <Form.Label>
                          <b>End Date :</b>
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          style={{ height: 39 }}
                          placeholder="Select Date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  {roleType === "client" && currentPath !== "clientstudy" && (
                    <Col md={12} className="p-1 mb-2">
                      <Form.Group>
                        <Form.Label className="mb-1 fw-bold">
                          <b>Status :</b>
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          value={status}
                          className="rounded-1"
                          style={{
                            height: 39,
                            color: getColorForStatus(status),
                          }}
                          onChange={handleChange}
                        >
                          <option value="active" style={{ color: "green" }}>
                            Active
                          </option>
                          <option value="inactive" style={{ color: "red" }}>
                            Inactive
                          </option>
                          <option value="pending" style={{ color: "orange" }}>
                            Pending
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  )}
                  {currentPath === "clientstudy" && (
                    <Col md={12} className="p-1 mb-2">
                      <Form.Group>
                        <Form.Label className="mb-1 fw-bold">
                          <b> Task Status :</b>
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          value={status}
                          className="rounded-1"
                          style={{
                            height: 39,
                            color: getColorForStatus(status),
                          }}
                          onChange={handleChange}
                        >
                          <option value="start" className="text-primary">
                            Start
                          </option>
                          <option value="complete" className="text-success">
                            complete
                          </option>
                          <option value="pending" className="text-secondary">
                            Pending
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Container>

              {currentPath === "clientstudy" && roleType !== "user" && (
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold">
                    <b>Assign To:</b>
                  </Form.Label>
                  <InputGroup className="w-100">
                    <div style={{ flex: 1 }}>
                      <Multiselect
                        className="task-multiselect"
                        displayValue="label"
                        onKeyPressFn={() => { }}
                        onRemove={handleRemove}
                        onSelect={handleSelect}
                        options={optiones}
                        style={{ width: "100%" }}
                        selectedValues={selectedOptiones}
                      />
                    </div>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-save">Assign Task To User</Tooltip>
                      }
                    >
                      <Button
                        className="taskAssignButton"
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            style={{ width: 15, height: 15 }}
                            className="me-2"
                          />
                        ) : null}
                        Assign
                      </Button>
                    </OverlayTrigger>
                  </InputGroup>
                </Form.Group>
              )}
              {currentPath !== "clientstudy" && roleType !== "user" && (
                <>
                  <Form.Group className="mb-2  gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      <b>Templates :</b>
                    </Form.Label>
                    <Multiselect
                      displayValue="label"
                      onKeyPressFn={() => { }}
                      onRemove={templateRemove}
                      onSelect={templateSelect}
                      options={options}
                      selectedValues={selectedOptions}
                    />
                  </Form.Group>
                  {selectedOptions?.length > 0 && (
                    <Form.Group className="mt-2">
                      <Form.Label>
                        <b>Check Required Templates</b>
                      </Form.Label>
                      {selectedOptions?.map((item) => (
                        <Form.Check
                          key={item.value}
                          type="checkbox"
                          value={item.value}
                          label={item.label}
                          onChange={() => handleCheck(item.value)}
                        />
                      ))}
                    </Form.Group>
                  )}
                </>
              )}
              {roleType === "user" && (
                <>
                  <Form.Group className="mb-2 gap-2">
                    <Form.Label className="mb-1 fw-bold">
                      <b>Templates :</b>
                    </Form.Label>
                    {!(currentPath === "clientstudy" || roleType === "user") ? (
                      <Multiselect
                        displayValue="label"
                        onKeyPressFn={() => { }}
                        onRemove={templateRemove}
                        onSelect={templateSelect}
                        options={options}
                        selectedValues={selectedOptions}
                      />
                    ) : (
                      <>
                        <div>
                          {taskData?.templates?.map((item, index) => (
                            <NavLink key={index} to={`temp/${item?._id}`}
                              onClick={() => handleLoginClick(item._id)}
                            >
                              <Badge
                                bg="secondary"
                                className="custom_badge p-2 rounded me-2"
                                style={{ cursor: "pointer" }}
                              >
                                {item?.name}
                              </Badge>
                            </NavLink>
                          ))}
                        </div>
                        {selectedOptions?.length > 0 && (
                          <Form.Group className="mt-2">
                            <Form.Label>
                              <b>Check Required Templates</b>
                            </Form.Label>
                            {selectedOptions?.map((item) => (
                              <Form.Check
                                key={item.value}
                                type="checkbox"
                                value={item.value}
                                label={item.label}
                                onChange={() => handleCheck(item.value)}
                              />
                            ))}
                          </Form.Group>
                        )}
                      </>
                    )}
                  </Form.Group>
                </>
              )}

            </div>

            <div className="px-3 pt-1">
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Description:</b>
                </Form.Label>
                <ReactQuill
                  onChange={setDescription}
                  value={description}
                  readOnly={
                    roleType === "user" || currentPath === "clientstudy"
                  }
                />
              </Form.Group>
            </div>
            <FormGroup>
              <div className="d-flex mb-1 px-3">
                <Form.Label className="m-0">
                  <b>Add Sub Task:</b>
                </Form.Label>
                <Form.Label>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-add">Add SubTask</Tooltip>}
                  >
                    <i
                      className="fa-solid fa-circle-plus ms-1"
                      onClick={addDropdown}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
              </div>

              <div className="px-3 mb-1">
                {dropdowns?.map((dropdown, index) => (
                  <div className=" mb-2 " key={index + 1}>
                    <InputGroup>
                      <InputGroup.Text> {index + 1}</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Option ${index + 1}`}
                        style={{ width: "65%" }}
                        value={headerTitle}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                      <Form.Select onChange={handleStatusChange}>
                        <option value="Pending">Pending</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>
                      <InputGroup.Text
                        variant="light"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                        onClick={() => removeDropdown(index)}
                      >
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="tooltip-add">Delete</Tooltip>}
                        >
                          <i
                            className="fa-solid fa-circle-xmark p-1"
                            style={{ color: "red", cursor: "pointer" }}
                          ></i>
                        </OverlayTrigger>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                ))}
              </div>
            </FormGroup>

            <div className="p-0">
              <hr style={{ margin: "0px" }} />
              <div className="offcanvas-message mb-3">
                <div className="offcanvas-message-box hstack gap-2">
                  <div className="date-buttonchange d-flex">
                    <Button
                      className={`button-milestone-top border-end border-0 rounded-0 ${activeTab === "comments" ? "active" : ""
                        }`}
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                        backgroundColor:
                          activeTab === "comments" ? "#6c757d" : "",
                      }}
                      variant="outline-secondary"
                      onClick={() => handleTabClick("comments")}
                    >
                      Comments
                    </Button>
                    <Button
                      className={`button-milestone-top border-end border-0 rounded-0 ${activeTab === "activity" ? "active" : ""
                        }`}
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                        backgroundColor:
                          activeTab === "activity" ? "#6c757d" : "",
                      }}
                      variant="outline-secondary"
                      onClick={() => handleTabClick("activity")}
                    >
                      All Activity
                    </Button>
                  </div>
                </div>
                <hr style={{ margin: "0px" }} />
                {/* {activeTab === "comments" && commentsHere()}
                {activeTab === "activity" && activityHere()} */}
              </div>
              <div className="offcanbodytwo">
                <div>
                  <div className="comments-parentClass">
                    <div className="d-flex">
                      <div className="offcanvas-messageName-icon pt-3">
                        <span>S.G</span>
                      </div>
                      <div className="w-100 position-relative">
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "170px", marginRight: "13px" }}
                          className="offcanvas-description mt-2"
                          onChange={(e) => setComment(e.target.value)}
                          value={comment}
                        />
                        <div className="position-absolute bottom-0 start-0">
                          <div className="position-relative">
                            <Collapse in={openTextFormate}>
                              <div>
                                <div
                                  className="menu-section overflow-auto"
                                  style={{ height: 150, position: "absolute" }}
                                >
                                  <ul className="menu-list">
                                    <li>
                                      <i className="fa-solid fa-bold px-2"></i>
                                      Bold
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-italic px-2"></i>
                                      Italic
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-underline px-2"></i>
                                      underline
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-code px-2"></i>
                                      Code
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-list-ol"></i>
                                      Numbered list
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-list-ul px-2"></i>
                                      Bullet List
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Collapse>
                            <div className="customCummontOPtion">
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-bold">
                                    Attach a file an upload image
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="fa-solid fa-paperclip px-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleIconClick}
                                ></i>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-bold">
                                    Text Formatting
                                  </Tooltip>
                                }
                              >
                                <i
                                  onClick={() =>
                                    setOpenTextFormate(!openTextFormate)
                                  }
                                  className="fa-brands fa-autoprefixer px-2"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-bold">
                                    At-Mention
                                  </Tooltip>
                                }
                              >
                                <span>
                                  <i className="fa-solid fa-at px-2"></i>
                                </span>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="button_type text-end p-2 mt-3">
                {/* <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-save">Apply Changes</Tooltip>}
                >
                  {(roleType === "client" || roleType === "user") && (
                    <button
                      onClick={
                        currentPath === "clientstudy"
                          ? addTaskComment
                          : updateTask
                      }
                    >
                      {loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 15, height: 15 }}
                          className="me-2"
                        />
                      ) : currentPath === "clientstudy" ? (
                        "Comment"
                      ) : (
                        "Update"
                      )}
                    </button>
                  )}
                </OverlayTrigger> */}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-save">
                      {currentPath === "clientstudy"
                        ? "Add Comment"
                        : "Apply Changes"}
                    </Tooltip>
                  }
                >
                  {(roleType === "client" || roleType === "user") && (
                    <button
                      onClick={
                        currentPath === "clientstudy"
                          ? addTaskComment
                          : updateTask
                      }
                    >
                      {loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 15, height: 15 }}
                          className="me-2"
                        />
                      ) : currentPath === "clientstudy" ? (
                        "Comment"
                      ) : (
                        "Update"
                      )}
                    </button>
                  )}
                </OverlayTrigger>
              </div>
            </div>
          </Offcanvas.Body >
        </Offcanvas >
      </div >
    </>
  );
};

export default TaskDetailModal;
