import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import UseToggle from "../../../Hooks/UseToggle";
import SelectTempelateCustomization from "./SelectTempelateCustomization";

const SelectForm = ({
  deleteSection,
  section,
  sections,
  addUpdateFormById,
}) => {
  const { toggle, setToggle } = UseToggle();
  const [formData, setFormData] = useState(section);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSection = sections.find((item) => item.id === section.id);
      if (updatedSection) {
        setFormData(updatedSection);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [section.sections]);

  const handleToogle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div className="p-3 border shadow-sm rounded-3 mb-3">
        <div className="hstack justify-content-between mb-2">
          <Form.Label className="mb-1 d-flex gap-1">
            <b>
              <span
                style={{
                  fontWeight: formData?.labelStyle?.bold
                    ? formData.customize
                      ? "bold"
                      : "normal"
                    : "normal",
                  fontStyle: formData?.labelStyle?.italic
                    ? formData.customize
                      ? "italic"
                      : "normal"
                    : "normal",
                  color: formData.customize
                    ? formData?.labelStyle?.color
                    : "#000",
                }}
              >
                {formData?.label ? formData?.label : "Label"}
              </span>
              {formData?.required && (
                <span className="text-danger ms-1">*</span>
              )}
            </b>
            {formData?.help && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-header">{formData?.help}</Tooltip>
                }
              >
                <i className="fa-solid fa-circle-question text-dark"></i>
              </OverlayTrigger>
            )}
          </Form.Label>
          <div className="hstack justify-content-end gap-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header">Customize Select </Tooltip>}
            >
              <i
                className="fa-solid fa-pen-to-square text-success"
                style={{ cursor: "pointer" }}
                onClick={setToggle}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header"> Delete Select</Tooltip>}
            >
              <i
                className="fa-solid fa-trash text-danger"
                style={{ cursor: "pointer" }}
                onClick={deleteSection}
              ></i>
            </OverlayTrigger>
          </div>
        </div>
        <Form.Group>
          <Form.Select as="select" disabled>
            <option>Select</option>
            {(formData?.options ?? []).map((item, index) => (
              <option key={index} value={item.id}>
                {item.value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <SelectTempelateCustomization
        show={!toggle}
        onHide={handleToogle}
        Title="Select"
        id={formData?.id}
        type={formData?.type}
        addUpdateFormById={addUpdateFormById}
        sections={sections}
      />
    </>
  );
};

export default SelectForm;
