import React from 'react'
import { Table } from 'react-bootstrap'

const SponcerViewProperty = ({ propertiesData }) => {
    console.log("propertiesData", propertiesData);

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className="fw-bold">Attributes</th>
                        <th className="fw-bold">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <b>Company Name</b>
                        </td>
                        <td>
                            <span className="p-1">{propertiesData?.companyName}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Company ID</b>
                        </td>
                        <td>
                            <span className="p-1">{propertiesData?.companyID}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Office Phone:</b>
                        </td>
                        <td>
                            <span className="p-1">{propertiesData?.officePhoneNumber}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Status :</b>
                        </td>
                        <td>
                            <span className="p-1">{propertiesData?.status}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Website:</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.website}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Address :</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.Address}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Admin  :</b>
                        </td>
                        <td>
                            <span className="p-1">
                                GalaxBiotech
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b> Country:</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.Country}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>State :</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.State}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>City :</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.City}
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <b>Zip Code  :</b>
                        </td>
                        <td>
                            <span className="p-1">
                                {propertiesData?.zipCode}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default SponcerViewProperty