import React, { useEffect, useRef, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import UseToggle from "../../Hooks/UseToggle";
import { clientServices } from "../../APIServices/clientServices";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../Component/RightClick/RightClick";
import Swal from "sweetalert2";
import UpdateClientModal from "./UpdateClientModal";
import Moment from "react-moment";
import Paginations from "../../Hooks/Paginations";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../Hooks/NoDataFoundTable";

const SponsorList = ({
  tableRef,
  onMouseDown,
  selectedRow,
  setSelectedRow,
  getAllSponsor,
  loading,
  clientData,
  setPropertiesData,
}) => {
  const { toggle1, setToggle1 } = UseToggle();
  const [clientDetails, setClientDetails] = useState("");

  const updateClientstatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Client Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await clientServices.updateSponsorClient(id, data);
        if (results?.status_code === 200) {
          getAllSponsor();
        }
      }
    });
  };

  const deleteClient = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Client!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await clientServices.deleteSponsorClient(_id);
        if (data?.status_code === 200) {
          getAllSponsor();
        }
      }
    });
  };

  const updateClient = (item) => {
    setToggle1();
    setClientDetails(item);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      default:
        return "inherit";
    }
  };

  const formatStatus = (status) => {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  };

  const handleRowClick = (item) => {
    setSelectedRow(item._id.$oid);
    setPropertiesData(item);
  };

  const resizerRefs = useRef([]);

  useEffect(() => {
    const resizers = resizerRefs.current;
    let startX, startWidth;

    const initResize = (e, index) => {
      startX = e.clientX;
      startWidth = resizers[index].parentElement.offsetWidth;
      document.addEventListener("mousemove", doResize);
      document.addEventListener("mouseup", stopResize);
    };

    const doResize = (e) => {
      const width = startWidth + (e.clientX - startX);
      resizers.forEach((resizer, index) => {
        if (index === parseInt(resizer.dataset.index)) {
          resizer.parentElement.style.width = `${width}px`;
        }
      });
    };

    const stopResize = () => {
      document.removeEventListener("mousemove", doResize);
      document.removeEventListener("mouseup", stopResize);
    };

    resizers.forEach((resizer, index) => {
      resizer.addEventListener("mousedown", (e) => initResize(e, index));
    });
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = clientData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(clientData?.length / itemsPerPage);

  useEffect(() => {
    getAllSponsor();
  }, []);

  return (
    <>
      {loading ? (
        <SpinerDataLoader />
      ) : result?.length > 0 ? (
        <>
          <Table
            id="resizable-table"
            className="custom-table"
            ref={tableRef}
            striped
            border
            hover
          >
            <thead
              className="position-sticky bg-white px-2"
              style={{ top: -1 }}
            >
              <tr style={{ cursor: "pointer" }}>
                <th
                  style={{ width: 25 }}
                  onMouseDown={(e) => onMouseDown(e, 0)}
                >
                  Sr.
                </th>
                <th
                  style={{ width: 110 }}
                  onMouseDown={(e) => onMouseDown(e, 1)}
                >
                  Company Name
                </th>
                <th
                  style={{ width: 100 }}
                  onMouseDown={(e) => onMouseDown(e, 2)}
                >
                  Status
                </th>
                <th
                  style={{ width: 125 }}
                  onMouseDown={(e) => onMouseDown(e, 3)}
                >
                  Website
                </th>
                <th
                  style={{ width: 113 }}
                  onMouseDown={(e) => onMouseDown(e, 4)}
                >
                  Create Date | Time
                </th>
                <th
                  style={{ width: 95 }}
                  onMouseDown={(e) => onMouseDown(e, 5)}
                >
                  Update Date | Time
                </th>
              </tr>
            </thead>
            <tbody>
              {result?.map((item, index) => (
                <tr
                  key={item.id}
                  onClick={() => handleRowClick(item)}
                  className={selectedRow === item._id.$oid ? "selected" : ""}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    <ContextMenuTrigger
                      id={`clientData${index}`}
                      collect={() => ({
                        id: `clientData${index}` + 1,
                      })}
                    >
                      {startIndex + index + 1}
                    </ContextMenuTrigger>
                    <RightClick
                      ContextId={`clientData${index}`}
                      Menu1
                      MenuName1={
                        item.status === "active" ? "Inactive" : "Active"
                      }
                      icons1={
                        <i
                          className={`fa-solid fa-circle ${item.status === "active"
                            ? "text-danger"
                            : "text-success"
                            }`}
                        ></i>
                      }
                      className1={"text-white"}
                      handleClick1={() =>
                        updateClientstatus(
                          item?._id?.$oid,
                          item?.status === "active" ? "inactive" : "active"
                        )
                      }
                      Menu2
                      MenuName2={"Update"}
                      icons2={
                        <i className="fa-solid fa-pen-to-square text-white"></i>
                      }
                      className2={"text-white"}
                      handleClick2={() => updateClient(item)}
                      Menu3
                      MenuName3={"Delete"}
                      icons3={
                        <i className="fa-solid fa-trash-can text-danger"></i>
                      }
                      className3={"text-white "}
                      handleClick3={() => deleteClient(item?._id?.$oid)}
                    />
                  </td>
                  <td>
                    <ContextMenuTrigger
                      id={`clientData${index}`}
                      collect={() => ({
                        id: `clientData${index}` + 1,
                      })}
                    >
                      {item.companyName}
                    </ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger
                      id={`clientData${index}`}
                      collect={() => ({
                        id: `clientData${index}` + 1,
                      })}
                    >
                      <div style={{ color: getStatusColor(item.status) }}>
                        {formatStatus(item.status)}
                      </div>
                    </ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger
                      id={`clientData${index}`}
                      collect={() => ({
                        id: `clientData${index}` + 1,
                      })}
                    >
                      {item.website}
                    </ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger
                      id={`clientData${index}`}
                      collect={() => ({
                        id: `clientData${index}` + 1,
                      })}
                    >
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.created_at}
                      </Moment>
                    </ContextMenuTrigger>
                  </td>
                  <td>
                    <ContextMenuTrigger
                      id={`clientData${index}`}
                      collect={() => ({
                        id: `clientData${index}` + 1,
                      })}
                    >
                      <Moment format="DD MMM YYYY | HH:MM">
                        {item.updated_at}
                      </Moment>
                    </ContextMenuTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="position-sticky bottom-0 mx-1">
            <Paginations
              totalPage={totalPage}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={clientData?.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </>
      ) : (
        <NoDataFoundTable MSG={"Sponsor Not Found!"} />
      )}
      <UpdateClientModal
        Show={!toggle1}
        Hide={setToggle1}
        clientDetails={clientDetails}
        setClientDetails={setClientDetails}
        getAllSponsor={getAllSponsor}
        Size={"md"}
        Title="Update Client Modal"
      />
    </>
  );
};

export default SponsorList;
