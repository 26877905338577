import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import UseToggle from "../../../Hooks/UseToggle";
import HeadingTempelatesCustomization from "./HeadingTempelatesCustomization";

const Heading = ({ deleteSection, section, sections, addUpdateFormById }) => {
  const { toggle, setToggle } = UseToggle(false);
  const { customize, setCustomize } = useState(false);
  const [formData, setFormData] = useState(section);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSection = sections?.find((item) => item.id === section.id);
      if (updatedSection) {
        setFormData(updatedSection);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [section, sections]);

  return (
    <>
      <div className="p-3 border shadow-sm rounded-3 mb-3">
        <div className="hstack justify-content-between">
          <div
            className="w-100"
            style={{
              textAlign:
                formData?.labelStyle?.alignment === "center"
                  ? "center"
                  : formData?.labelStyle?.alignment === "left"
                  ? "left"
                  : formData?.labelStyle?.alignment === "right"
                  ? "right"
                  : "",
              fontWeight: formData?.labelStyle?.bold
                ? formData.customize
                  ? "bold"
                  : "normal"
                : "normal",
              fontStyle: formData?.labelStyle?.italic
                ? formData.customize
                  ? "italic"
                  : "normal"
                : "normal",
              color: formData.customize ? formData?.labelStyle?.color : "#000",
            }}
          >
            <h6
              onChange={() => setCustomize(!customize)}
              style={{
                fontWeight: formData?.labelStyle?.bold ? "bold" : "normal",
                fontStyle: formData?.labelStyle?.italic ? "italic" : "normal",
                color: formData?.labelStyle?.color,
              }}
            >
              {formData?.label ? formData?.label : "Label"}
            </h6>
          </div>

          <div className="hstack justify-content-end gap-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-heading">Customize Heading</Tooltip>
              }
            >
              <i
                className="fa-solid fa-pen-to-square text-success"
                style={{ cursor: "pointer" }}
                onClick={setToggle}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-heading">Delete Heading</Tooltip>}
            >
              <i
                className="fa-solid fa-trash text-danger"
                style={{ cursor: "pointer" }}
                onClick={() => deleteSection()}
              ></i>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <HeadingTempelatesCustomization
        show={!toggle}
        onHide={setToggle}
        Title="Task Name"
        id={formData?.id}
        type={formData?.type}
        sections={sections}
        addUpdateFormById={addUpdateFormById}
      />
    </>
  );
};

export default Heading;
