import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../../Component/RightClick/RightClick";
import Swal from "sweetalert2";
import { teamServices } from "../../APIServices/teamServices";
import UpdateTeamModal from "./UpdateTeamModal";
import UseToggle from "../../Hooks/UseToggle";
import Paginations from "../../Hooks/Paginations";
import Moment from "react-moment";
import { NoDataFoundTable } from "../../Hooks/NoDataFoundTable";
import TeamViewDetailsOffcanvas from "./TeamViewDetailsOffcanvas";

const TeamTableData = ({
  tableRef,
  onMouseDown,
  teamsData,
  selectedRow,
  setSelectedRow,
  getUsers,
  loading,
}) => {
  const { toggle, setToggle } = UseToggle();
  const [userDetails, setUserDetails] = useState("");
  const [userID, SetUserID] = useState("");
  const { toggle1, setToggle1 } = UseToggle();

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "orange";
      default:
        return "inherit";
    }
  };

  const changeStatus = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Team User Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await teamServices.changeStatus(_id, data);
        if (results?.status_code === 200) {
          getUsers();
        }
      }
    });
  };

  const deleteUser = async (_id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete Team User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {};
        data.status = status;
        let results = await teamServices.deleteUser(_id);
        if (results?.status_code === 200) {
          getUsers();
        }
      }
    });
  };

  const handleRowClick = (_id, status) => {
    changeStatus(_id, status);
  };

  const updateUser = (item) => {
    setToggle();
    setUserDetails(item);
  };

  const handleClickRow = (item) => {
    setSelectedRow(item._id.$oid);
    SetUserID(item?._id?.$oid);
    setToggle1();
  }

  console.log("userID", userID);

  const handleViwClick = (item) => {
    SetUserID(item?._id?.$oid);
    setToggle1();
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = teamsData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(teamsData?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "65vh" }}
        >
          <Spinner animation="border" />
        </div>
      ) : result?.length > 0 ? (
        <>
          <div
            className="team_table overflow-auto"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <Table
              id="resizable-table"
              className="custom-table"
              ref={tableRef}
              striped
              border
              hover
            >
              <thead
                className="position-sticky bg-white px-2"
                style={{ top: -1 }}
              >
                <tr>
                  <th
                    style={{ width: 50 }}
                    className="text-center"
                    onMouseDown={(e) => onMouseDown(e, 0)}
                  >
                    Sr.
                  </th>
                  <th
                    style={{ width: 222 }}
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    Full Name
                  </th>
                  <th
                    style={{ width: 219 }}
                    onMouseDown={(e) => onMouseDown(e, 2)}
                  >
                    Email
                  </th>
                  <th
                    style={{ width: 161 }}
                    onMouseDown={(e) => onMouseDown(e, 3)}
                  >
                    Gender
                  </th>
                  <th
                    style={{ width: 116 }}
                    onMouseDown={(e) => onMouseDown(e, 4)}
                  >
                    Phone
                  </th>
                  <th
                    style={{ width: 140 }}
                    onMouseDown={(e) => onMouseDown(e, 5)}
                  >
                    Status
                  </th>
                  <th
                    style={{ width: 191 }}
                    onMouseDown={(e) => onMouseDown(e, 6)}
                  >
                    Registered Date | Time
                  </th>
                  <th
                    style={{ width: 170 }}
                    onMouseDown={(e) => onMouseDown(e, 7)}
                  >
                    Modified Date | Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={item.id}
                    className={selectedRow === item._id.$oid ? "selected" : ""}
                    onClick={() => handleClickRow(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {startIndex + index + 1}
                      </ContextMenuTrigger>
                      <RightClick
                        ContextId={`clientData${index}`}
                        Menu1
                        MenuName1={
                          item.status === "active" ? "Inctive" : "Active"
                        }
                        icons1={
                          <i
                            class={`fa-solid fa-circle ${item.status === "active"
                              ? "text-danger"
                              : "text-success"
                              }`}
                          ></i>
                        }
                        className1={"text-white"}
                        handleClick1={() =>
                          handleRowClick(
                            item._id.$oid,
                            item.status === "active" ? "inactive" : "active"
                          )
                        }
                        Menu2
                        MenuName2={"Update"}
                        icons2={
                          <i class="fa-solid fa-pen-to-square text-white"></i>
                        }
                        className2={"text-white"}
                        handleClick2={() => updateUser(item)}
                        Menu3
                        MenuName3={"Delete"}
                        icons3={
                          <i class="fa-solid fa-trash-can text-danger"></i>
                        }
                        className3={"text-white "}
                        handleClick3={() => deleteUser(item?._id?.$oid)}
                        Menu4
                        MenuName4={"View"}
                        icons4={
                          <i class="fa-solid fa-eye text-white"></i>
                        }
                        className4={"text-white "}
                        handleClick4={() => handleViwClick(item)}
                      />
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.fullName}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.email}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.gender}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        {item.phone}
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <div style={{ color: getStatusColor(item.status) }}>
                          {item.status}
                        </div>
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <Moment format="DD MMM YYYY | HH:MM">
                          {item.created_at}
                        </Moment>
                      </ContextMenuTrigger>
                    </td>
                    <td>
                      <ContextMenuTrigger
                        id={`clientData${index}`}
                        collect={() => ({
                          id: `clientData${index}` + 1,
                        })}
                      >
                        <Moment format="DD MMM YYYY | HH:MM">
                          {item.updated_at}
                        </Moment>
                      </ContextMenuTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0 bg-white">
              <Paginations
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={teamsData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <NoDataFoundTable MSG={"Teams Data Is Not Found"} />
      )}
      <UpdateTeamModal
        Show={!toggle}
        Hide={setToggle}
        Size={"lg"}
        getUsers={getUsers}
        teamsData={teamsData}
        setUserDetails={setUserDetails}
        userDetails={userDetails}
        Title="Update Team"
        Submit={"Add User"}
      />
      <TeamViewDetailsOffcanvas
        show={!toggle1}
        onHide={setToggle1}
        userID={userID}


      />
    </>
  );
};
export default TeamTableData;
