import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Offcanvas,
  InputGroup,
  Col,
  Spinner,
  Container,
  Row,
} from "react-bootstrap";
import { quotationServices } from "../../../APIServices/quotationServices";
import { workflowServices } from "../../../APIServices/workflowServices";

const PhaseOffcanvas = ({
  show,
  onHide,
  size,
  getAllPhaseService,
  workFlowId,
  activePhaseData,
  getPhaseIdByWorkflowId,
}) => {
  const [phaseID, setPhaseID] = useState("");
  const [activeService, setActiveService] = useState("");
  const [effortsInEfficacyEndPoint, setEffortsInEfficacyEndPoint] =
    useState("");
  const [efficacyCost, setEfficacyCost] = useState("");
  const [effortsInsafetyEndPoint, setEffortsInsafetyEndPoint] = useState("");
  const [safetyCost, setSafetyCost] = useState("");
  const [reviewEffortInEfficacy, setReviewEffortInEfficacy] = useState("");
  const [reviewEfficacyCost, setReviewEfficacyCost] = useState("");
  const [reviewEffortInSafety, setReviewEffortInSafety] = useState("");
  const [reviewSafetyCost, setReviewSafetyCost] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState("");

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let id = phaseID;
    let userData = {};
    userData.workflowID = workFlowId;
    userData.effortsInEfficacyEndPoint = parseInt(
      effortsInEfficacyEndPoint,
      10
    );
    userData.efficacyCost = parseInt(efficacyCost, 10);
    userData.effortsInsafetyEndPoint = parseInt(effortsInsafetyEndPoint, 10);
    userData.safetyCost = parseInt(safetyCost, 10);
    userData.reviewEffortInEfficacy = parseInt(reviewEffortInEfficacy, 10);
    userData.reviewEfficacyCost = parseInt(reviewEfficacyCost, 10);
    userData.reviewEffortInSafety = parseInt(reviewEffortInSafety, 10);
    userData.reviewSafetyCost = parseInt(reviewSafetyCost, 10);
    let data = await quotationServices.addPhaseService(userData, id);
    setStatusMessage(data);
    if (data?.status_code === 200) {
      setLoader(false);
      clearFormData();
      getAllPhaseService();
      getPhaseIdByWorkflowId();
    } else {
      setLoader(false);
    }
  };

  const clearFormData = () => {
    onHide();
    setPhaseID("");
    setActiveService("");
    setEffortsInEfficacyEndPoint("");
    setEfficacyCost("");
    setEffortsInsafetyEndPoint("");
    setSafetyCost("");
    setReviewEffortInEfficacy("");
    setReviewEfficacyCost("");
    setReviewEffortInSafety("");
    setReviewSafetyCost("");
  };

  useEffect(() => {
    getPhaseIdByWorkflowId();
  }, []);

  return (
    <Offcanvas
      show={show}
      size={size}
      onHide={onHide}
      placement="end"
      style={{ width: "540px" }}
    >
      <Offcanvas.Header className="py-2">
        <div className="hstack justify-content-between w-100 gap-3">
          <Offcanvas.Title>
            <b>Statistical Input to Protocol</b>
          </Offcanvas.Title>
          <div
            onClick={() => clearFormData()}
            className="offcanvas_Close"
            title="Close"
            style={{ cursor: "pointer" }}
          >
            X
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column p-2">
        <div className="flex-grow-1 overflow-auto">
          <div className="px-1 pt-1">
            <Container fluid>
              <Row>
                <Col md={12} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>
                        Phase :<span style={{ color: "red" }}>*</span>
                      </b>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setPhaseID(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="">Select</option>
                      {activePhaseData?.map((item, index) => (
                        <option key={index} value={item?.phaseID}>
                          {item.phaseName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>
                        Effort In Efficacy Endpoint:
                        <span style={{ color: "red" }}>*</span>
                      </b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        className="checkbox-control"
                        placeholder="Enter Effort In Efficacy Endpoint"
                        onChange={(e) =>
                          setEffortsInEfficacyEndPoint(e.target.value)
                        }
                        value={effortsInEfficacyEndPoint}
                      />
                      <Button
                        variant="light"
                        className="px-2"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        Hours
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>Cost:</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        className="checkbox-control"
                        placeholder="Ente Cost"
                        onChange={(e) => setEfficacyCost(e.target.value)}
                        value={efficacyCost}
                      />
                      <Button
                        variant="light"
                        className="px-2"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        $/Hours
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>
                        Effort In Safety Endpoint:
                        <span style={{ color: "red" }}>*</span>
                      </b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        className="checkbox-control"
                        placeholder="Enter Effort In Safety Endpoint"
                        onChange={(e) =>
                          setEffortsInsafetyEndPoint(e.target.value)
                        }
                        value={effortsInsafetyEndPoint}
                      />
                      <Button
                        variant="light"
                        className="px-2"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        Hours
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>Cost:</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        className="checkbox-control"
                        placeholder="Enter Cost "
                        onChange={(e) => setSafetyCost(e.target.value)}
                        value={safetyCost}
                      />
                      <Button
                        variant="light"
                        className="px-2"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        $/Hours
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>
                        Review Effort In Efficacy Endpoint
                        <span style={{ color: "red" }}>*</span>
                      </b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        className="checkbox-control"
                        placeholder="Enter Review Effort In Efficacy Endpoint"
                        onChange={(e) =>
                          setReviewEffortInEfficacy(e.target.value)
                        }
                        value={reviewEffortInEfficacy}
                      />
                      <Button
                        variant="light"
                        className="px-2"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        Hours
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>Cost :</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        className="checkbox-control"
                        placeholder="Enter Cost"
                        onChange={(e) => setReviewEfficacyCost(e.target.value)}
                        value={reviewEfficacyCost}
                      />
                      <Button
                        variant="light"
                        className="px-2"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        $/Hours
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>
                        Review Effort In Safety Endpoint
                        <span style={{ color: "red" }}>*</span>
                      </b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        className="checkbox-control"
                        placeholder="Enter Review Effort In Safety Endpoint"
                        onChange={(e) =>
                          setReviewEffortInSafety(e.target.value)
                        }
                        value={reviewEffortInSafety}
                      />
                      <Button
                        variant="light"
                        className="px-2"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        Hours
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-1 mb-1">
                  <Form.Group>
                    <Form.Label>
                      <b>Cost :</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        className="checkbox-control"
                        placeholder="Enter Cost"
                        onChange={(e) => setReviewSafetyCost(e.target.value)}
                        value={reviewSafetyCost}
                      />
                      <Button
                        variant="light"
                        className="px-2"
                        style={{
                          fontSize: "13px",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        $/Hours
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="text-end py-3">
            <Form.Group as={Col} md="12">
              <div className="button_type">
                <button type="submit" onClick={handleSubmit}>
                  {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : null}
                  Submit
                </button>
              </div>
            </Form.Group>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PhaseOffcanvas;
