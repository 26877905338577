import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import AnalyticsDonutChart from "./AnalyticsDonutChart";
import DummyTableCard from "./RecentLoginTableCard";
import { loginActivitiesServices } from "../../APIServices/loginActivitiesServices";
import RecentActivityTable from "./RecentActivityTable";
import RecentLoginTableCard from "./RecentLoginTableCard";
import { clientServices } from "../../APIServices/clientServices";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";

const Analytics = () => {
  const [logData, setLogData] = useState([]);
  const [totalLogins, setTotalLogins] = useState(0);
  const [failedLoginData, setFailedLoginData] = useState([]);
  const [totalFailedLoginData, settotalFailedLoginData] = useState([]);
  const [totalLogouts, setTotalLogouts] = useState(0);
  const [greeting, setGreeting] = useState("");
  const [userData, setUserData] = useState([]);

  const loginActivities = async () => {
    let data = await loginActivitiesServices.loginActivities();
    setLogData(data.data);
    setTotalLogins(data?.data?.length);
    const logoutCount = data?.data?.filter(
      (log) => log.type === "logout"
    )?.length;
    setTotalLogouts(logoutCount);
  };

  const getUserLogs = async () => {
    let data = await clientServices.getUserLogs();
    setUserData(data?.data);
  };

  const getFailLogin = async () => {
    let data = await loginActivitiesServices.getFailLogin();
    setFailedLoginData(data?.data);
    settotalFailedLoginData(data?.data?.length);
  };

  useEffect(() => {
    loginActivities();
    getFailLogin();
    getUserLogs();
  }, []);

  useEffect(() => {
    const currentHour = new Date().getHours();
    let greetingMessage;

    if (currentHour < 12) {
      greetingMessage = "Good Morning";
    } else if (currentHour < 18) {
      greetingMessage = "Good Afternoon";
    } else {
      greetingMessage = "Good Evening";
    }

    setGreeting(greetingMessage);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12} className="p-0">
            <div
              className="text-center border-bottom mb-1"
              style={{ padding: "20.8px 0px" }}
            >
              <h3 className="fw-bold">{`${greeting}, Siddhant Gautam`}</h3>
              <p>Welcome to Your Dashboard</p>
            </div>
          </Col>
        </Row>
        <div style={{ height: "calc(100vh - 215px)", overflowX: "hidden" }}>
          <Row>
            <Col md={3}>
              <div className="mb-3">
                <Card className="analytics_card shadow-sm border-0 h-100 p-2">
                  <Card.Body>
                    <div className="hstack">
                      <div className="analytics_icon rounded-circle">
                        <i className="fa-solid fa-file-contract"></i>
                      </div>
                      <div className="widget-data">
                        <Card.Title className="text-muted mb-1">
                          <b>{totalLogins}</b>
                        </Card.Title>
                        <Card.Text>
                          <p>Total Login Logs.</p>
                        </Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="mb-3">
                <Card className="analytics_card shadow-sm border-0 h-100  p-2">
                  <Card.Body>
                    <div className="hstack">
                      <div className="analytics_icon rounded-circle">
                        <i className="fa-solid fa-file-contract"></i>
                      </div>
                      <div className="widget-data">
                        <Card.Title className="text-muted mb-1">
                          <b>{totalFailedLoginData}</b>
                        </Card.Title>
                        <Card.Text>
                          <p>Total Failed Login</p>
                        </Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="mb-3">
                <Card className="analytics_card shadow-sm border-0 h-50  p-2">
                  <Card.Body>
                    <div className="hstack">
                      <div className="analytics_icon rounded-circle">
                        <i className="fa-solid fa-file-contract"></i>
                      </div>
                      <div className="widget-data">
                        <Card.Title className="text-muted mb-1">
                          <b>{totalLogouts}</b>
                        </Card.Title>
                        <Card.Text>
                          <p>Total Logout Logs</p>
                        </Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <Card
                className="chart-background shadow-sm border-0 "
                style={{ height: 334 }}
              >
                <Card.Body className="pb-0">
                  <AnalyticsDonutChart />
                </Card.Body>
              </Card>
            </Col>
            <Col md={5} className="mb-3">
              <Card
                className="chart-background shadow-sm border-0 "
                style={{ height: 334 }}
              >
                <Card.Body>
                  <RecentLoginTableCard />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} className="mb-3">
              <Card className="chart-background shadow-sm border-0 h-100">
                <Card.Body>
                  <h6 className="mb-2">
                    <b>Recent Activity</b>
                  </h6>
                  <div>
                    <Table
                      className="table-bordered table-hover mb-3 bg-light"
                      striped
                      bordered
                      hover
                    >
                      <thead className="cardTable">
                        <tr>
                          <th>Sr</th>
                          <th>Name</th>
                          <th>Activity</th>
                          <th>Date And Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData?.slice(0, 10).map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.updateBy}</td>
                            <td>{item.activity}</td>
                            <td>
                              <Moment format="DD MMM YYYY | HH:MM">
                                {item.timestamp}
                              </Moment>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className="button_type text-end">
                    <NavLink to={"/recent-activity"}>
                      <button>View All</button>
                    </NavLink>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Analytics;
