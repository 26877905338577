import React, { useEffect, useState } from 'react'
import UseToggle from '../../Hooks/UseToggle'
import AddInvoiceDataModal from './AddInvoiceDataModal';
import { invoiceServices } from '../../APIServices/invoiceServices';
import Moment from 'react-moment';
import { ContextMenuTrigger } from 'react-contextmenu';
import RightClick from '../../Component/RightClick/RightClick';
import Paginations from '../../Hooks/Paginations';
import { NoDataFoundTable } from '../../Hooks/NoDataFoundTable';
import { Spinner, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import UpdateInvoiceData from './UpdateInvoiceData';
import Swal from 'sweetalert2';

const TaxInvoiceData = () => {
    const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
    const [invoiceSpouncerData, setInvoiceSpouncerData] = React.useState([]);
    const [loader, setLoader] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [activeSponcerId, setActiveSponcerId] = useState("");
    const [updateInvoiceDetails, setUpdateInvoiceDetails] = useState("");
    const [updateInvoiceId, setUpdateInvoiceId] = useState("");
    const [activeSpouncer, setActiveSpouncer] = useState([]);

    const getInvoiceBySponsor = async () => {
        let data = await invoiceServices.getInvoiceBySponsor();
        setInvoiceSpouncerData(data?.data);
        setLoader(false);
    };

    const getSponsorByStatus = async () => {
        let data = await invoiceServices.getSponsorByStatus();     //For Invoice Getting Active Sponcer data
        setActiveSpouncer(data?.data);
    };



    useEffect(() => {
        getInvoiceBySponsor();
        getSponsorByStatus();
    }, []);

    const handleLoginClick = (_id) => {
        setLoader(true);
        window.location.href = `/invoice/${_id}`;
        setLoader(false);
    };

    const updateInvoice = (item) => {
        setToggle1();
        setUpdateInvoiceDetails(item);
        setUpdateInvoiceId(item?._id);
    };

    const deleteInvoice = async (_id, status) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to Delete Invoice!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#103C5E",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, update it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = {};
                data.status = status;
                let results = await invoiceServices.deleteInvoice(_id);
                if (results?.status_code === 200) {
                    getInvoiceBySponsor();
                }
            }
        });
    };

    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = invoiceSpouncerData?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(invoiceSpouncerData?.length / itemsPerPage);

    return (
        <div>
            <>
                <div className="hstack  inputWorkFlow gap-2 px-1 border-bottom" style={{ padding: 6 }}>
                    <button
                        onClick={setToggle}
                        title="Add Quotations"
                        className="teams_button border rounded-1 fs-12"
                    >
                        <i className="fa-solid fa-plus text-muted mt-1"></i>
                    </button>
                </div>
                {loader ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "65vh" }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : result?.length > 0 ? (
                    <>
                        <div
                            className="overflow-auto m-1"
                            style={{ height: "calc(100vh - 130px" }}
                        >
                            <Table
                                id="resizable-table"
                                bordered
                                className="custom-table"
                                striped
                                border
                                hover
                            >
                                <thead className="position-sticky" style={{ top: -1 }}>
                                    <tr style={{ cursor: "pointer" }}>
                                        <th style={{ border: "1px solid #896e6e5e", width: 47 }}>
                                            Sr.
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e", width: 130 }}>
                                            Company Name
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e" }}>
                                            Sponsor Name
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e" }}>Currency</th>
                                        <th style={{ border: "1px solid #896e6e5e" }}>Amount Due</th>
                                        <th style={{ border: "1px solid #896e6e5e" }}>Invoice No</th>
                                        <th style={{ border: "1px solid #896e6e5e" }}>Due Date</th>
                                        <th style={{ border: "1px solid #896e6e5e" }}>Tax Invoice</th>
                                        <th style={{ width: 80 }} className="text-center">
                                            View
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e" }}>
                                            Create Date | Time
                                        </th>
                                        <th style={{ border: "1px solid #896e6e5e" }}>
                                            Update Date | Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {result?.map((item, index) => (
                                        <tr
                                            style={{ cursor: "pointer" }}
                                            key={item.id}
                                            className={
                                                selectedRow === item?._id ? "selected" : ""
                                            }
                                            onClick={() => setSelectedRow(item._id)}
                                        >
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {startIndex + index + 1}
                                                </ContextMenuTrigger>
                                                <RightClick
                                                    ContextId={`clientData${index}`}
                                                    Menu2
                                                    MenuName2={"Update"}
                                                    icons2={
                                                        <i class="fa-solid fa-pen-to-square text-white"></i>
                                                    }
                                                    className2={"text-white"}
                                                    handleClick2={() => updateInvoice(item)}
                                                    Menu3
                                                    MenuName3={"Delete"}
                                                    icons3={
                                                        <i class="fa-solid fa-trash-can text-danger"></i>
                                                    }
                                                    className3={"text-white "}
                                                    handleClick3={() => deleteInvoice(item?._id)}
                                                />
                                            </td>
                                            <td>
                                                {" "}
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.companyName}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.sponsorName}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.currency}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.amountDue}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.invoiceNo}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.dueDate}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    {item.taxInvoice}
                                                </ContextMenuTrigger>
                                            </td>
                                            <td className="text-center">
                                                <NavLink
                                                    to={`clientstudy/${item?._id}`}
                                                    onClick={() => handleLoginClick(item._id)}
                                                >
                                                    <button
                                                        className="text-white border-success bg-success border-0"
                                                        style={{
                                                            fontSize: 10,
                                                            borderRadius: 3,
                                                            padding: "2px 8px",
                                                        }}
                                                    >
                                                        View
                                                    </button>
                                                </NavLink>
                                            </td>

                                            <td>
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    <Moment format="DD MMM YYYY | HH:MM">
                                                        {item.created_at}
                                                    </Moment>
                                                </ContextMenuTrigger>
                                            </td>
                                            <td>
                                                {" "}
                                                <ContextMenuTrigger
                                                    id={`clientData${index}`}
                                                    collect={() => ({
                                                        id: `clientData${index}` + 1,
                                                    })}
                                                >
                                                    <Moment format="DD MMM YYYY | HH:MM">
                                                        {item.updated_at}
                                                    </Moment>
                                                </ContextMenuTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className="position-sticky bottom-0 bg-white">
                                <Paginations
                                    totalPage={totalPage}
                                    pageNumber={pageNumber}
                                    itemsPerPage={itemsPerPage}
                                    totalItems={invoiceSpouncerData?.length}
                                    setPageNumber={setPageNumber}
                                    setItemsPerPage={setItemsPerPage}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "80vh",
                            justifyContent: "center",
                        }}
                    >
                        <NoDataFoundTable MSG={"Invoice Data is Not Found"} />
                    </div>
                )}
            </>
            <AddInvoiceDataModal
                Show={!toggle}
                Hide={setToggle}
                Size={"lg"}
                Title="Add User"
                Submit={"Add User"}
                activeSpouncer={activeSpouncer}
                getInvoiceBySponsor={getInvoiceBySponsor}
                setActiveSponcerId={setActiveSponcerId}
                activeSponcerId={activeSponcerId}

            />
            <UpdateInvoiceData
                Show={!toggle1}
                Hide={setToggle1}
                Size={"lg"}
                updateInvoiceDetails={updateInvoiceDetails}
                getInvoiceBySponsor={getInvoiceBySponsor}
                updateInvoiceId={updateInvoiceId}
                activeSpouncer={activeSpouncer}
                setActiveSponcerId={setActiveSponcerId}
                setActiveSpouncer={setActiveSpouncer}
                Title="Add User"
                Submit={"Add User"}

            />
        </div>
    )
}

export default TaxInvoiceData