import React, { useEffect, useRef, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import SignaturePad from "signature_pad";
import SignatureBoxTempelateCustomization from "./SignatureBoxTempelateCustomization";
import UseToggle from "../../../Hooks/UseToggle";

const SignatureBox = ({
  deleteSection,
  section,
  sections,
  addUpdateFormById,
}) => {
  const canvasRef = useRef(null);
  const [signaturePad, setSignaturePad] = useState(null);
  const { toggle, setToggle } = UseToggle();
  const [formData, setFormData] = useState(section);

  useEffect(() => {
    const canvas = canvasRef.current;
    const signaturePad = new SignaturePad(canvas, {
      backgroundColor: "rgba(255, 255, 255, 0)",
      penColor: "black",
      velocityFilterWeight: 0.7,
      minWidth: 0.5,
      maxWidth: 2.5,
      throttle: 16,
      minPointDistance: 3,
      onEnd: () => {},
      onBegin: () => {},
    });

    setSignaturePad(signaturePad);

    return () => {
      signaturePad.off();
    };
  }, []);

  const handleClear = () => {
    signaturePad.clear();
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedSection = sections.find((item) => item.id === section.id);
      if (updatedSection) {
        setFormData(updatedSection);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    handleStorageChange();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [section.sections]);

  return (
    <>
      <div className="p-3 border shadow-sm rounded-3 mb-3">
        <div className="hstack justify-content-between">
          <Form.Label className="mb-1 d-flex gap-1">
            <b>
              <span
                style={{
                  fontWeight: formData?.labelStyle?.bold
                    ? formData.customize
                      ? "bold"
                      : "normal"
                    : "normal",
                  fontStyle: formData?.labelStyle?.italic
                    ? formData.customize
                      ? "italic"
                      : "normal"
                    : "normal",
                  color: formData.customize
                    ? formData?.labelStyle?.color
                    : "#000",
                }}
              >
                {formData?.label ? formData?.label : "Label"}
              </span>
              {formData?.required && (
                <span className="text-danger ms-1">*</span>
              )}
            </b>
            {formData?.help && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-header">{formData?.help}</Tooltip>
                }
              >
                <i className="fa-solid fa-circle-question text-dark"></i>
              </OverlayTrigger>
            )}
          </Form.Label>
          <div className="hstack justify-content-end gap-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-header">Customize Signature</Tooltip>
              }
            >
              <i
                className="fa-solid fa-pen-to-square text-success"
                style={{ cursor: "pointer" }}
                onClick={setToggle}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-header"> Delete Signature</Tooltip>}
            >
              <i
                className="fa-solid fa-trash text-danger"
                style={{ cursor: "pointer" }}
                onClick={deleteSection}
              ></i>
            </OverlayTrigger>
          </div>
        </div>
        <formData className="signature-component mt-3">
          <canvas ref={canvasRef} className="signature-box"></canvas>
        </formData>
        <div className="text-end mt-2">
          <Button variant="outline-dark" className="me-3">
            Submit
          </Button>

          <Button variant="outline-dark" onClick={handleClear}>
            Clear
          </Button>
        </div>
      </div>
      <SignatureBoxTempelateCustomization
        show={!toggle}
        onHide={setToggle}
        Title="Signature"
        id={formData?.id}
        type={formData?.type}
        addUpdateFormById={addUpdateFormById}
        sections={sections}
        section={section}
        setFormData={setFormData}
      />
    </>
  );
};

export default SignatureBox;
