import React, { useEffect, useRef, useState } from "react";
import profileImage from "../Images/manImage.jpg";
import { NavLink } from "react-router-dom";
import { Form } from "react-bootstrap";
import TwoFAmodal from "../Pages/Settings/Security/TwoFAmodal";
import UseToggle from "../Hooks/UseToggle";
import ChangePassword from "../Pages/Settings/Security/ChangePassword";
import { authServices } from "../APIServices/authServices";

const Header = ({ userCurrrentData, roleType }) => {
  const ProfileRef = useRef(null);
  const NotificationRef = useRef(null);
  const [profileActive, setProfileActive] = useState(false);
  const [notificationActive, setNotificationActive] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [profileImage, setProfileImage] = useState("");

  const toggleProfile = () => {
    setProfileActive(!profileActive);
  };
  const toggleNotification = () => {
    setNotificationActive(!notificationActive);
  };

  const path = localStorage.getItem("path", "clientstudy");
  const loginType = localStorage.getItem("loginType");

  const logout = async () => {
    await authServices.logout();
  };

  // const logout = () => {
  //   localStorage.removeItem("token") || localStorage.removeItem("userData");
  //   localStorage.removeItem("roletype") || localStorage.removeItem("roleData");
  //   window.location.href = "/";
  // };

  const handleLogout2 = () => {
    window.location.href = "/analytics";
    localStorage.removeItem("path", "clientstudy");
  };

  const handleLogout = () => {
    localStorage.removeItem("loginType");
    window.location.href = "/analytics";
  };


  useEffect(() => {
    const handleClickProfileMenuOutside = (event) => {
      if (ProfileRef.current && !ProfileRef.current.contains(event.target)) {
        setProfileActive(false);
      }
    };
    document.addEventListener("click", handleClickProfileMenuOutside, true);
    return () => {
      document.removeEventListener(
        "click",
        handleClickProfileMenuOutside,
        true
      );
    };
  }, []);

  useEffect(() => {
    setFirstName(
      userCurrrentData?.firstName ? userCurrrentData?.firstName : ""
    );
    setMiddleName(
      userCurrrentData?.middleName ? userCurrrentData?.middleName : ""
    );
    setLastName(userCurrrentData?.lastName ? userCurrrentData?.lastName : "");
    // setProfileImage(
    //   userCurrrentData?.profileImage
    //     ? userCurrrentData?.profileImage
    //     : ProfilePic
    // );
  }, [userCurrrentData]);

  useEffect(() => {
    const handleClickNotification = (event) => {
      if (
        NotificationRef.current &&
        !NotificationRef.current.contains(event.target)
      ) {
        setNotificationActive(false);
      }
    };
    document.addEventListener("click", handleClickNotification, true);
    return () => {
      document.removeEventListener("click", handleClickNotification, true);
    };
  }, []);
  const { toggle3, setToggle3, toggle4, setToggle4 } = UseToggle();

  return (
    <>
      <div className="header_Section hstack justify-content-between">
        <div className="text-white fw-bold px-2"> Galax Pm+</div>
        <div className="bell hstack gap-3">
          <div
            ref={NotificationRef}
            className={`profile position-relative ${notificationActive ? "active" : ""
              }`}
          >
            <div
              className="icon_wrap hstack gap-2"
              onClick={toggleNotification}
            >
              <div
                className={`title_name hstack gap-1 px-2 p-1 ${notificationActive ? "profileNameActive" : ""
                  }`}
                style={{ cursor: "pointer" }}
              >
                <div className="notification">
                  <span>
                    <i className="fa-solid fa-bell"></i>
                  </span>
                  <span className="badge"> </span>
                </div>
              </div>
            </div>
            <div
              className="profile_dd shadow"
              style={{ right: 0, width: "300px" }}
            >
              <div className="profile_ul rounded">
                <div
                  className="notification_Bar"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div className="notificationbar mb-2 d-flex gap-2">
                    <img src={profileImage} alt="image" />
                    <div>
                      <Form.Label style={{ fontSize: 13 }}>
                        <b>notification 1</b>
                      </Form.Label>
                      <br />
                      <Form.Text style={{ fontSize: 12, lineHeight: 1.1 }}>
                        Accepty friend request Accepty friend request Accepty
                        friend request
                      </Form.Text>
                    </div>
                  </div>
                  <div style={{ border: "1px dashed #f1ebeb" }}></div>
                  <div className="notificationbar mb-1 d-flex gap-2">
                    <img src={profileImage} alt="image" />
                    <div>
                      <Form.Label style={{ fontSize: 13 }}>
                        <b>notification 1</b>
                      </Form.Label>
                      <br />
                      <Form.Text style={{ fontSize: 12, lineHeight: 1.1 }}>
                        Accepty friend request Accepty friend request Accepty
                        friend request
                      </Form.Text>
                    </div>
                  </div>
                  <div style={{ border: "1px dashed #f1ebeb" }}></div>
                  <div className="notificationbar mb-1 d-flex gap-2">
                    <img src={profileImage} alt="image" />
                    <div>
                      <Form.Label style={{ fontSize: 13 }}>
                        <b>notification 2</b>
                      </Form.Label>
                      <br />
                      <Form.Text style={{ fontSize: 12, lineHeight: 1.1 }}>
                        Accepty friend request Accepty friend request Accepty
                        friend request
                      </Form.Text>
                    </div>
                  </div>
                  <div style={{ border: "1px dashed #f1ebeb" }}></div>
                  <div className="notificationbar mb-1 d-flex gap-2">
                    <img src={profileImage} alt="image" />
                    <div>
                      <Form.Label style={{ fontSize: 13 }}>
                        <b>notification 3</b>
                      </Form.Label>
                      <br />
                      <Form.Text style={{ fontSize: 12, lineHeight: 1.1 }}>
                        Accepty friend request Accepty friend request Accepty
                        friend request
                      </Form.Text>
                    </div>
                  </div>
                  <div style={{ border: "1px dashed #f1ebeb" }}></div>
                  <div className="notificationbar mb-1 d-flex gap-2">
                    <img src={profileImage} alt="image" />
                    <div>
                      <Form.Label style={{ fontSize: 13 }}>
                        <b>notification 4</b>
                      </Form.Label>
                      <br />
                      <Form.Text style={{ fontSize: 12, lineHeight: 1.1 }}>
                        Accepty friend request Accepty friend request Accepty
                        friend request
                      </Form.Text>
                    </div>
                  </div>
                  <div style={{ border: "1px dashed #f1ebeb" }}></div>
                  <div className="notification_clearButton">
                    <span>Clear All</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={ProfileRef}
            className={`profile position-relative ${profileActive ? "active" : ""
              }`}
          >
            <div className="icon_wrap hstack gap-2" onClick={toggleProfile}>
              <img src={profileImage} alt="Profile" title="User Profile" />

              <div
                className={`title_name hstack gap-1 px-2 p-1 ${profileActive ? "profileNameActive" : ""
                  }`}
                style={{ cursor: "pointer" }}
              >
                <h6 className="text-capitalize">
                  {roleType === "client" ? (
                    <b>{userCurrrentData?.companyName}</b>
                  ) : roleType === "user" ? (
                    <b>
                      {userCurrrentData?.firstName} {userCurrrentData?.lastName}
                    </b>
                  ) : null}
                </h6>
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>

            <div className="profile_dd shadow" style={{ right: 0 }}>
              <ul className="profile_ul ">
                <li>
                  <NavLink to="/profile">
                    <i className="fa-solid fa-user me-2"></i> Account
                  </NavLink>
                  <div style={{ border: "1px dashed #f1ebeb" }}></div>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      toggleProfile();
                      setToggle3();
                    }}
                  >
                    <i className="fa-solid fa-shield me-2"></i>
                    2FA
                  </NavLink>
                  <div style={{ border: "1px dashed #f1ebeb" }}></div>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      toggleProfile();
                      setToggle4();
                    }}
                  >
                    <i className="fa-solid fa-lock me-2"></i>
                    Change Password
                  </NavLink>
                  <div style={{ border: "1px dashed #f1ebeb" }}></div>
                </li>
                {path === "clientstudy" && (
                  <li>
                    <div className="text-danger p-2" onClick={handleLogout2} style={{ fontSize: 13, cursor: "pointer" }}>
                      <i className="fas fa-sign-out-alt me-2 text-danger"></i>
                      Study LogOut
                    </div>
                    <div style={{ border: "1px dashed #f1ebeb" }}></div>
                  </li>
                )}
                {loginType === "study" && (
                  <li>
                    <div className="text-danger p-2" onClick={handleLogout} style={{ fontSize: 13, cursor: "pointer" }}>
                      <i className="fas fa-sign-out-alt me-2 text-danger"></i>
                      Study User LogOut
                    </div>
                    <div style={{ border: "1px dashed #f1ebeb" }}></div>
                  </li>
                )}
                <li>
                  <NavLink onClick={logout} className="text-danger">
                    <i className="fas fa-sign-out-alt me-2 text-danger"></i>
                    Logout
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <TwoFAmodal
        Show={!toggle3}
        Hide={setToggle3}
        Size={"md"}
        Title="Two Factor Auth"
        Submit={"Go to Authentication"}
      />
      <ChangePassword
        Show={!toggle4}
        Hide={setToggle4}
        Size={"md"}
        Title="Change Password"
        Submit={"Go to Change Password"}
      />
    </>
  );
};

export default Header;
