import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
  headersWithoutAuthWithBody,
  headersWithoutAuthWithoutBody,
} from "../APIHelpers/Responses";

export const authServices = {
  login,
  logout,
  resetPassword,
  changePassword,
  otpVerification,

  twoFAEnableDisable,
  updateProfileImage,
  updateProfile,
  generateQRCode,
  getQRData,
  showQRCode,
  enableTwoFactorAuth,
  disableTwoFactorAuth,
  twoFactorAuthType,
  getTwoFactorAuthType,
  emailOTPFor2FA,
  enableTwoFactorAuthByEmail,
  disableTwoFactorAuthByEmail,
  verifyTwoFAForEmailLogin,
  verifyTwoFAForAppLogin,
  disableTwoFactorAuthByAdmin,
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Mera
  getUsers,
  addUsers,
  addInvitei,
  getUserById,
  updateUser,
  deleteUser,
  sendMail,
  requestForResetPassword,
  uploadImage,
  changeStatus,
  getUsersByStatus,
  addClient,
  addSponsorClient,
  currentUserData,
  generateOTPforTwoFAAuth,
  verificationForEnableTwoFAByEmail,
  twoFAverificationByEmail,
  verificationForTwoFAverificationByEmail,
  verificationForDisableTwoFAByEmail,
  generateQRforTwoFAAuthByApp,
  enableTwoFAbyApp,
  disableTwoFAbyApp,
};

// User login
async function login(userData) {
  const response = await fetch(
    `${config.API_URL}login`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let roleData = data?.roleType;
  if (data.status_code === 200) {
    localStorage.setItem("token", JSON.stringify(data.token));
    if (roleData === "user" || roleData === "client") {
      window.location.href = "/analytics";
    } else {
      let Message = {
        status_code: 400,
        message: "You have not registered on this panel.",
      };
      return Message;
    }
    return data;
  } else if (data.status_code === 401) {
    return data;
  } else {
    return data;
  }
}

// User Logout
async function logout(userData) {
  localStorage.clear();
  const response = await fetch(
    `${config.API_URL}logout`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  console.log("data", data);

  await handleResponse(data);
  if (data?.status_code === 200) {
    window.location.href = "/";
  }
}

// OTP Verification
async function otpVerification(userData) {
  const response = await fetch(
    `${config.API_URL}otpVerification`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  if (data?.status_code === 200) {
    localStorage.setItem("isTwoFA", JSON.stringify(false));
    window.location.href = "/admin/dashboard";
  }
  return data;
}

// Change Password
async function changePassword(userData) {
  const response = await fetch(
    `${config.API_URL}changePassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Profile Image
async function updateProfileImage(formData, id) {
  const response = await axios.post(
    `${config.API_URL}updateProfileImage/${id}`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// Reset Password
async function resetPassword(userData) {
  const response = await fetch(
    `${config.API_URL}resetPassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// // Forgot Password
// async function forgotPassword(userData) {
//   const response = await fetch(
//     `${config.API_URL}forgotPassword`,
//     headersWithAuth("PUT", userData, headers)
//   );
//   const data = await response.json();
//   return data;
// }

// Two Factor Authentication
async function twoFAEnableDisable(userData) {
  const response = await fetch(
    `${config.API_URL}twoFAEnableDisable`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Company Profile Data
async function updateProfile(userData) {
  const response = await fetch(
    `${config.API_URL}updateProfile`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Generate QR code
async function generateQRCode() {
  const response = await fetch(
    `${config.API_URL}generateQRCode`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get QR Data
async function getQRData() {
  const response = await fetch(
    `${config.API_URL}getQRData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Show QR code
async function showQRCode() {
  const response = await fetch(
    `${config.API_URL}showQRCode`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Enable Two Factor Auth
async function enableTwoFactorAuth(userData) {
  const response = await fetch(
    `${config.API_URL}enableTwoFactorAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Disable Two Factor Auth
async function disableTwoFactorAuth(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Auth Type
async function getTwoFactorAuthType() {
  const response = await fetch(
    `${config.API_URL}getTwoFactorAuthType`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Auth Type
async function twoFactorAuthType(userData) {
  const response = await fetch(
    `${config.API_URL}twoFactorAuthType`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Send OTP for Email two factor auth
async function emailOTPFor2FA(userData) {
  const response = await fetch(
    `${config.API_URL}emailOTPFor2FA`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Disable Email Two Factor Auth
async function disableTwoFactorAuthByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuthByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Enable Email Two Factor Auth
async function enableTwoFactorAuthByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}enableTwoFactorAuthByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Verify Two FA for Email Login
async function verifyTwoFAForEmailLogin(userData) {
  const response = await fetch(
    `${config.API_URL}verifyTwoFAForEmailLogin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Verify Two FA for App Login
async function verifyTwoFAForAppLogin(userData) {
  const response = await fetch(
    `${config.API_URL}verifyTwoFAForAppLogin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Disable Two Factor Auth by Admin
async function disableTwoFactorAuthByAdmin(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuthByAdmin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// getUsers
async function getUsers() {
  const response = await fetch(
    `${config.API_URL}getUsers`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//addUser
async function addUsers(userData) {
  const response = await fetch(
    `${config.API_URL}addUsers`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//addInvitation
async function addInvitei(userData) {
  const response = await fetch(
    `${config.API_URL}addInvitei`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//getUserById
async function getUserById(userData) {
  const response = await fetch(
    `${config.API_URL}getUserById${userData.id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
// updateUser
async function updateUser(userData) {
  const response = await fetch(
    `${config.API_URL}updateUser`,
    headersWithAuthWithoutBody("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
//deleteUser
async function deleteUser(userData, id) {
  const response = await fetch(
    `${config.API_URL}deleteUser/${id}`,
    headersWithAuthWithoutBody("DELETE", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
// sendMail
async function sendMail(userData) {
  const response = await fetch(
    `${config.API_URL}sendMail`,
    headersWithAuthWithoutBody("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
//requestForResetPassword
async function requestForResetPassword(userData) {
  const response = await fetch(
    `${config.API_URL}requestForResetPassword`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// uploadImage
async function uploadImage(userData) {
  const response = await fetch(
    `${config.API_URL}uploadImage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// changeStatus
async function changeStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeStatus/${userData.id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
// getUsersByStatus
async function getUsersByStatus() {
  const response = await fetch(
    `${config.API_URL}getUsersByStatus`,
    headersWithAuth("GET", headers)
  );
  const data = await response.json();
  return data;
}
//addClient
async function addClient(clientData) {
  const response = await fetch(
    `${config.API_URL}addClient`,
    headersWithAuth("POST", clientData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// addSponsorClient client
async function addSponsorClient(clientData) {
  const response = await fetch(
    `${config.API_URL}addSponsorClient`,
    headersWithAuth("POST", clientData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

//currentUserData
async function currentUserData() {
  const response = await fetch(
    `${config.API_URL}currentUserData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
// generateOTPforTwoFAAuth
async function generateOTPforTwoFAAuth(userData) {
  const response = await fetch(
    `${config.API_URL}generateOTPforTwoFAAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
//verificationForEnableTwoFAByEmail
async function verificationForEnableTwoFAByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}verificationForEnableTwoFAByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
// twoFAverificationByEmail
async function twoFAverificationByEmail(userData) {
  const response = await fetch(
    `${config.API_URL} twoFAverificationByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
// verificationForTwoFAverificationByEmail
async function verificationForTwoFAverificationByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}  verificationForTwoFAverificationByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
// verificationForDisableTwoFAByEmail
async function verificationForDisableTwoFAByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}  verificationForDisableTwoFAByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
//generateQRforTwoFAAuthByApp
async function generateQRforTwoFAAuthByApp(userData) {
  const response = await fetch(
    `${config.API_URL}  generateQRforTwoFAAuthByApp`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
//enableTwoFAbyApp
async function enableTwoFAbyApp(userData) {
  const response = await fetch(
    `${config.API_URL}  enableTwoFAbyApp`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
//disableTwoFAbyApp
async function disableTwoFAbyApp(userData) {
  const response = await fetch(
    `${config.API_URL}  disableTwoFAbyApp`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}
